// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';

import { Row, Col, Button } from 'react-bootstrap';

import { withFeatureflow } from 'react-featureflow-client';

import ControlWrapper from './controls/ControlWrapper';
import FormErrors from './controls/FormErrors';
import ActionButton from './controls/ActionButton';
import { Control, actions } from 'react-redux-form';

import * as authoritiesApi from '../api/authorities';

import Select from 'react-select';

function mapStateToProps(state){
  return {
    user: state.form.user,
    isCurrentUser: state.form.user.id === state.principalUserOutline.id,
    authorities: state.authorities

  }
}

const loadAuthorities = async () => {
    const authorities = await authoritiesApi.get();
    return {
        options: authorities.map(a => ({
            value: a.name,
            label: a.description || a.name
        })),
        complete: true
    }
}



export default withFeatureflow()(connect(mapStateToProps)(function(props: any):React.Element<any>{
  return (
    <Form model="form.user" onSubmit={props.onSubmit}>
      <ControlWrapper model=".email"
                     type="text"
                     disabled={true}
                     placeholder="Email"
                     label="Email"/>
      <Row>
        <Col xs={6}>
          <ControlWrapper model=".firstName"
                         type="text"
                         placeholder="First name"
                         label="First name"
                         errorMessages={{
                       isRequired: 'First name is required'
                     }}/>
        </Col>
        <Col xs={6}>
          <ControlWrapper model=".lastName"
                         type="text"
                         placeholder="Last name"
                         label="Last name"
                         errorMessages={{
                       isRequired: 'Last name is required'
                     }}/>
        </Col>
      </Row>


      <div style={{marginTop: 10, paddingTop: 10, borderTop: '1px solid #eee'}}>

       {/*   {
              props.authorities.map((auth) => {
                  <div>{auth}</div>
              })
          }*/}

        {!props.isCurrentUser &&
        <ControlWrapper model=".role"
                       component={Select.Async}
                       placeholder="Role"
                       label="Role"
                                           /*options={props.options}*/
                       errorMessages={{
                         isRequired: 'Role is required'
                       }}
                        simpleValue={true}
                        loadOptions={loadAuthorities}
                        updateOn={['change']}
                        searchable={false}
                        clearable={false}
        />
        }
      </div>
      <FormErrors form="environment"/>
    </Form>
  )
}))

export const UserFormButtons = connect(mapStateToProps)((props: any): React.Element<any> => {
  return (
    <div>
      {
        !props.isCurrentUser &&
        <Button bsStyle="danger"
                disabled={props.user.id === props.currentUserId}
                onClick={props.onDelete.bind(this)}>
          Delete User
        </Button>
      }
      <Control component={ActionButton}
               model="form.user"
               type="submit"
               mapProps={{
                   disabled: props=>props.fieldValue.pending || !props.fieldValue.valid,
                   spinner: props=>props.fieldValue.pending
                 }}
               bsStyle="success"
               onClick={()=>props.dispatch(actions.submit('form.user'))}
      >
        Update User
      </Control>
    </div>
  );
})