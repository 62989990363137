// @flow
import * as reduxUtils from '../utils/redux';

import * as experimentApi from '../api/experiment';

type ExperimentsState = {[key: string]: any};
const PREFIX: string = "FF/Experiments/";
const RESET: string = PREFIX + "RESET";
const SET_EXPERIMENTS: string = PREFIX + "SET_EXPERIMENTS";
const REMOVE_EXPERIMENT: string = PREFIX + "REMOVE_EXPERIMENT";

const initialState: ExperimentsState = {};

export default function (state: ExperimentsState=initialState, action: Action): ExperimentsState{
  switch(action.type){
    case(SET_EXPERIMENTS):
      return {
        ...state,
        ...reduxUtils.reduceArrayToMap(action.payload.experiments)
      };
    case (REMOVE_EXPERIMENT):
      return {
        ...state,
        ...reduxUtils.archive(action.payload.experimentId, state)
      };
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setExperiments(experiments: any[]): Action{
  return {
    type: SET_EXPERIMENTS,
    payload:{
      experiments
    }
  }
}

export function removeExperiment(experimentId: string): Action{
  return {
    type: REMOVE_EXPERIMENT,
    payload:{
      experimentId
    }
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}

//Thunks
export function doSetExperimentEnabled( experiment: any, enabled: boolean): Thunk{
  return (dispatch)=>{
    const updated = {
      ...experiment,
      enabled
    };
    dispatch(setExperiments([updated])); //presume all is well
    return experimentApi.update(updated)
      .then(response=>{
        dispatch(setExperiments([response]));
        return Promise.resolve(response);
      })
  }
}

export function doUpdateExperiment(experiment: any): Thunk{
  return (dispatch)=>{
    return experimentApi.update(experiment)
    .then(response=>{
      dispatch(setExperiments([response]));
      return Promise.resolve(response);
    })
  }
}


export function doRemoveExperiment(experimentId: any): Thunk{
  return (dispatch)=>{
    return experimentApi.remove(experimentId)
      .then(response=>{
        dispatch(removeExperiment(experimentId));
        return Promise.resolve(response);
      })
      .catch(err=>{
        return Promise.reject(err);
      })
  }
}