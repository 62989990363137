// @flow
import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';

import Alert from 'react-s-alert';

export default function(props: any): React.Element<any>{
  const classNames = props.classNames
    .replace('s-alert-box ','')
    .replace('s-alert-info ','')
    .replace('s-alert-warning ','')
    .replace('s-alert-success ','')
    .replace('s-alert-error ','');

  let classNameMatch = /(info|warning|success|error)/.exec(props.classNames)[1];

  if (classNameMatch === 'error'){
    classNameMatch = 'danger';
  }
  return (
    <BootstrapAlert bsStyle={classNameMatch || 'info'}
                    className={classNames}
                    onDismiss={Alert.close.bind(this, props.id)}
                    id={props.id}
                    style={{
                      zIndex: 9999,
                      position: 'fixed',
                      ...props.styles
                    }}>
      <p>{props.message}</p>
    </BootstrapAlert>
  )
}

