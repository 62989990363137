// @flow
import React, {useState} from 'react';
import {Button, Col, FormControl, FormGroup, Panel, Row} from "react-bootstrap";
import type {Price, Product} from "../../../api/subscription";


type Props = {
    product: Product,
    currentPriceId: string,
    currentProductId: string,
    currentPriceId: string,
    subscribeToPrice: (product: Product, price: Price) => void,
};


function FreeProduct({product, currentPriceId, subscribeToPrice}: Props) {
    const price = product.prices.month;
    const currentPlan = product.prices.month.id === currentPriceId;

    const doSubscribeToPrice = (price) => () => {
        subscribeToPrice(product, price);
    }

    return (
        <Col lg={3} md={3} sm={6} xs={12}>
            <Panel>
                <Panel.Heading style={{fontSize: 18, textAlign: 'center'}}>Free</Panel.Heading>
                <Panel.Body style={{textAlign: 'center'}}>
                    <Row>
                        <Col xs={12}>
                            <h3 style={{marginTop: 0}}>Free</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <b>Features</b>
                            <ul style={{textAlign: 'left'}}>
                                <li>Single Seat</li>
                                <li>1 project</li>
                                <li>2 Environments</li>
                                <li>Comprehensive feature targeting rules and progressive rollout</li>
                                <li>Feature flag dashboard, basic analytics and real time status</li>
                                <li>Access to all SDKs across Server, Web and Mobile</li>
                                <li>Slack and Jira Integration</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Button onClick={doSubscribeToPrice(product, price)}
                                    disabled={currentPlan}
                                    bsStyle="primary" bsSize="sm">
                                {currentPlan ? 'Current Plan' : `Downgrade to Free`}
                            </Button>
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        </Col>
    )
}

export default FreeProduct;
