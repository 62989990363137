// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { createSelector } from '@reduxjs/toolkit'

//
import Select from 'react-select';

import { convertStringToKey } from '../utils/formParsers';

import ControlWrapper from './controls/ControlWrapper';
import FormErrors from './controls/FormErrors';
import ActionButton from './controls/ActionButton';
import { Control, actions } from 'react-redux-form';

import { withFeatureflow } from 'react-featureflow-client'


const selectFeatures = state => state.features;

const getExistingKeys = createSelector(
  selectFeatures,
  features => Object.keys(features).map(key=>features[key].key)
);

const selectFeatureForm = state => state.form.forms.feature;
const getKeyPristine = createSelector(
  selectFeatureForm,
  form=> form.key.pristine
);

const getFeatureForm = createSelector(
  state => state.form.feature,
  form=>form
)

function mapStateToProps(state){
  return {
    existingKeys: getExistingKeys(state),
    keyPristine: getKeyPristine(state),
    featureForm: getFeatureForm(state),
  }
}
export default withFeatureflow()(connect(mapStateToProps)(function(props: any):React.Element<any>{
  return (
    <Form model="form.target" onSubmit={props.onSubmit}>
      <ControlWrapper model=".name"
                     type="text"
                     label="Name"
                     placeholder="Name"
                     errorMessages={{
                       isRequired: 'Name is required'
                     }}
      />
      <ControlWrapper model=".key"
                      type="text"
                      label="Key"
                      placeholder="Key"
                      errorMessages={{
                       isRequired: 'Key is required'
                     }}
                      parser={ convertStringToKey }
                      changeAction={ keyInputMask }
      />
      <ControlWrapper model=".type"
                      component={Select}
                      label="Type"
                      placeholder="Type"
                      errorMessages={{
                        isRequired: 'Type is required'
                      }}
                      ignore={["blur"]}
                      clearable={false}
                      searchable={false}
                      simpleValue={true}
                      options={[
                        {
                          label: 'String',
                          value: 'string'
                        },
                        {
                          label: 'Number',
                          value: 'number'
                        },
                        {
                          label: 'Date',
                          value: 'date'
                        }
                      ]}
          />
      <FormErrors form="target"/>
    </Form>
  )
}))

export const CreateTargetFormButtons = connect()((props: any): React.Element<any> => {
  return (
      <Control component={ActionButton}
               model="form.target"
               type="submit"
               bsStyle="success"
               mapProps={{
                 spinner: props=>props.fieldValue.pending,
                 disabled: props=>props.fieldValue.pending || !props.fieldValue.valid
               }}
               onClick={()=>props.dispatch(actions.submit('form.target'))}
      >
        Create Target
      </Control>
  );
});

function keyInputMask(model, value): Thunk {
  return (dispatch) => {
    let key = convertStringToKey(value);
    dispatch(actions.change(model,value));
    if (key.length === 0){
      // Let the name dictate again!
      dispatch(actions.setPristine(model))
    }
  }
}