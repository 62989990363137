// @flow
import React, {useState} from 'react';
import {Button, Col, FormControl, FormGroup, Panel, Row} from "react-bootstrap";

function EnterprisePricingPlan() {
    return (
        <Col lg={3} md={3} sm={3} xs={12}>
            <Panel>
                <Panel.Heading style={{fontSize: 18, textAlign: 'center'}}>Enterprise</Panel.Heading>
                <Panel.Body style={{textAlign: 'center'}}>
                    <h3 style={{marginTop: 0}}>sales@featureflow.io</h3>
                    <p style={{fontSize: 18}}>

                    </p>
                    <b>Features</b>
                    <ul style={{textAlign: 'left'}}>
                        <li>25+ seats</li>
                        <li>Millions of frontend MAU</li>
                        <li>Unlimited projects</li>
                        <li>Unlimited environments</li>
                        <li>Unlimited features</li>
                        <li>Audit logs</li>
                        <li>A/B Testing</li>
                        <li>On Premise deploys</li>
                        <li>Dedicated VPC deploys</li>
                    </ul>

                    <Button onClick={() => {
                        window.Intercom('show');
                    }}
                            bsStyle="primary" bsSize="sm">
                        Contact us
                    </Button>
                </Panel.Body>
            </Panel>
        </Col>
    )
}

export default EnterprisePricingPlan;
