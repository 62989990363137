import React, {useEffect, useState} from 'react';

import {
    Row,
    Col,
    Panel,
    Table, ButtonToolbar, Button, ButtonGroup
} from 'react-bootstrap';
import {connect} from 'react-redux';
import { useFeatureflow } from 'react-featureflow-client';
import { withRouter } from 'react-router';
import * as featureApi from '../api/feature';
import ProjectPanel from "../components/project/ProjectPanel";
import FeatureSummaryRow from "../components/features/FeatureSummaryRow";
import {LinkContainer} from "react-router-bootstrap";

import { useGetProjectsQuery } from '../api/featureflowApi';
import { LoadingOverlay } from '../components/LoadingOverlay';

function mapStateToProps(state, props) {
    return {}
}

export default withRouter(connect(mapStateToProps)(function (props: any): React.Element<any> {
        const BOOKMARKED: string = 'bookmarked';
        const RECENT: string = 'recent';
        const MAINTAINING = 'maintaining';
        let query = props.location.query.query || 'recent';

        const [features, setFeatures] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        //const [projects, setProjects] = useState([]);
        const [currentQuery, setCurrentQuery] = useState(query);

        const { data: projects, error, isLoading: isProjectsLoading } = useGetProjectsQuery();
        const featureflow = useFeatureflow();
        let loadFiltered = async (query: string) => {
            if (isLoading) {
                return;
            }
            setIsLoading(true);
            if (query === RECENT) {
                await setFeatures(await featureApi.getRecent());
            }
            if (query === BOOKMARKED) {
                await setFeatures(await featureApi.getBookmarked());
            }
            if (query === MAINTAINING) {
                await setFeatures(await featureApi.getMaintaining())
            }
            setIsLoading(false);
        }
        useEffect(() => {
            setCurrentQuery(query);
            loadFiltered(query);
        }, [query]);

        return (
            <>
                <LoadingOverlay visible={isLoading} />
                <Row>
                    <Col xs={12} md={8} style={{paddingBottom: 10}}>
                        <h2 style={{paddingBottom: 0}}>
                            Home
                        </h2>
                    </Col>
                </Row>
                {featureflow.evaluate('home-projects').isOn() &&
                <>

                    <Row>
                        <Col xs={12} md={8} style={{paddingBottom: 10}}>
                            <h3 style={{paddingBottom: 0}}>
                                Projects
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        {projects.map(project =>
                            <Col xs={6} md={3} style={{paddingBottom: 20}}>

                                <Panel>
                                    <Panel.Heading>
                                        <Panel.Title componentClass="h3">{project.name}</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <ProjectPanel project={project}></ProjectPanel>
                                    </Panel.Body>
                                </Panel>
                            </Col>
                        )}
                    </Row>
                </>
                }
                <Row>
                    <Col xs={12} style={{paddingBottom: 20}}>
                        {featureflow.evaluate('home-projects').isOn() &&
                        <h3>Features</h3>
                        }
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bs-component">
                                    <ButtonToolbar>
                                        <ButtonGroup bsSize="small">
                                            <LinkContainer to='/home?query=recent'>
                                                <Button
                                                    active={true}
                                                    active={currentQuery === RECENT}
                                                    bsStyle={currentQuery === RECENT ? 'primary active' : 'primary'} //set here as active fails on initial render
                                                    disabled={isLoading}>Recent</Button>
                                            </LinkContainer>
                                            <LinkContainer to={{pathname: `/home`, search: "?query=bookmarked"}}>
                                                <Button bsStyle="primary"
                                                        disabled={isLoading}
                                                        active={currentQuery === BOOKMARKED}>Bookmarked</Button>
                                            </LinkContainer>
                                            <LinkContainer to={{pathname: `/home`, search: "?query=maintaining"}}>
                                                <Button bsStyle="primary"
                                                        disabled={isLoading}
                                                        active={currentQuery === MAINTAINING}>Maintaining</Button>
                                            </LinkContainer>
                                        </ButtonGroup>
                                    </ButtonToolbar>

                                    {/*<ul className="nav nav-pills">
                                        <li className="active"><a href="#" data-ol-has-click-handler="">Recent</a></li>
                                        <li><a href="#" onClick={handleSelect} data-ol-has-click-handler="">Starred</a></li>
                                        <li ><a href="#" data-ol-has-click-handler="">Maintaining</a></li>
                                    </ul>*/}
                                    <Table className="table table-striped table-hover">
                                        <thead>
                                        <tr>
                                            <th style={{border: 0}}>Key</th>
                                            <th style={{border: 0}}>Name</th>
                                            <th style={{border: 0}}>Description</th>
                                            <th style={{border: 0}}>Project</th>
                                            <th style={{border: 0}}>Maintainer</th>
                                            {/*                                            <th style={{border: 0}}>Tags</th>*/}
                                            {/*<th style={{border: 0}}>Status</th>*/}
                                            {/*<th style={{border: 0}}>Staging</th>
                                            <FeatureControlsth style={{border: 0}}>Production</th>*/}
                                            <th style={{border: 0}}></th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {(!features || features.length === 0) &&
                                        <tr>
                                            <td colSpan={'6'}>
                                                <div style={{margin: 50, textAlign: 'center', fontSize: 20}}>
                                                    No results
                                                </div>
                                            </td>
                                        </tr>
                                        }
                                        {features.map(feature => <FeatureSummaryRow key={feature.id} feature={feature}/>)}
                                        {/* <tr>
                                            <td>{feature.name} </td>
                                            <td className="text-right">
                                                <ButtonToolbar className="pull-right">
                                                    <Button bsStyle="primary"
                                                            bsSize="xsmall">
                                                        Edit <FaTrash/>
                                                    </Button>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>*/}
                                        {/*<tr>
                                            <td>Feature 1</td>
                                            <td>Rolling Out</td>
                                            <td>
                                                <FeatureStatusIndicator featureKey={"123"}
                                                                        available={true}
                                                                        trafficStatus={"alive"}
                                                                        targetingStatus={"targeted"}
                                                                        staleDays={10}
                                                                        environment={"PRod`"}
                                                                        style={{marginLeft: 10}}
                                                />
                                            </td>
                                            <td>
                                                <FeatureStatusIndicator featureKey={"123"}
                                                                        available={true}
                                                                        trafficStatus={"alive"}
                                                                        targetingStatus={"targeted"}
                                                                        staleDays={10}
                                                                        environment={"PRod`"}
                                                                        style={{marginLeft: 10}}
                                                />
                                            </td>
                                            <td className="text-right">
                                                <ButtonToolbar className="pull-right">
                                                    <Button bsStyle="danger"
                                                            bsSize="xsmall">
                                                        Edit <FaTrash/>
                                                    </Button>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>*/}
                                        {/*<tr>
                                            <td>James Pickup</td>
                                            <td>User</td>
                                            <td>002179a-371893-626h6n-9f6...</td>
                                            <td>2 Days ago</td>
                                            <td className="text-right">
                                                <ButtonToolbar className="pull-right">
                                                    <Button bsStyle="danger"
                                                            bsSize="xsmall">
                                                        Delete User <FaTrash/>
                                                    </Button>
                                                </ButtonToolbar>
                                            </td>
                                        </tr>*/}

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </>)
    }
));