// @flow
import React from 'react';

import * as modalController from '../redux/modalController';
import FindOrgForm, { FindOrgFormButtons } from '../forms/FindOrgForm';

import { actions } from 'react-redux-form';

import * as accountApi from '../api/account';




import { dispatch } from '../redux';

export function findOrgModal(): Promise<any>{
  return new Promise((resolve, reject)=>{
    //Must reset the form before we show it.
    dispatch(actions.reset('form.findOrg'));
    dispatch(actions.setInitial('form.findOrg'));

    dispatch(modalController.show({
      title: 'Find your organisations!',
      body: <FindOrgForm onSubmit={(values)=>{
                                    return accountApi.findOrgsByEmail(values.email)
                                      .then(success=>{
                                        dispatch(actions.setSubmitted('form.findOrg'));
                                      })
                                      .catch(err=>{
                                        let errorMessage = 'An error occurred sending the form.';
                                        if (err){
                                          errorMessage = err.message;
                                        }
                                        dispatch(actions.setSubmitFailed('form.findOrg'));
                                        dispatch(actions.setErrors('form.findOrg', {serverError: errorMessage}));
                                        return reject(err);
                                      });
                                    }}/>,
      footer: <FindOrgFormButtons onClose={()=>{dispatch(modalController.close())}}/>,
      closeButton: true,
      static: true,
    }));
  })

}