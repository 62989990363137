// @flow
import api from './';


type IntegrationData = {
    id: ?string,
    name: string,
    description: string,
    type: string,
    enabled: boolean,
    oneTimeToken: string,
    configuration: any
}
type Token = {
    token: string
}


export function createIntegration(data: IntegrationData){
    return api( 'post', `/api/v1/integrations`, {
        data
    })
}
export function connectJira(data: Token){
    return api( 'post', `/api/v1/integrations/jira/connect`, {
        data
    })
}
export function updateIntegration(data: IntegrationData){
    return api(data.id ? 'put' : 'post', `/api/v1/integrations`, {
        data
    })
}

export function removeIntegration(id: string){
  return api('delete', `/api/v1/integrations/${id}`)
}

export function getIntegration(id: string){
    return api('get', `/api/v1/integrations/${id}`);
}

export function getIntegrations(){
    return api('get', `/api/v1/integrations`);
}






