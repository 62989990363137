// @flow

type Condition = {
  target: string,
  operator: string,
  values: string[],
  rule: string
}

type Rule = {
  conditions: Condition[],
  split?: string,
  splits: {
    [key: string]: number
  },
  defaultRule: boolean
}

type RulesForm = {
  rules: Rule[],
  offVariantKey: string
}

export const defaultCondition = {
  target: '',
  operator: '',
  values: []
};

export const defaultRule = {
  conditions: [defaultCondition],
  split: undefined,
  splits: {},
  defaultRule: false
}

const initialState: RulesForm = {
  rules: [],
  offVariantKey: ''
};
export default initialState;