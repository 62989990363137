// @flow
import React from 'react';

import * as modalController from '../redux/modalController';
import {confirmModal} from './ConfirmModal';
import * as roleApi from "../api/role";
import UserForm, {UserFormButtons} from '../forms/UserForm';

import {actions} from 'react-redux-form';


import {dispatch} from '../redux';


type
Role = {
    id: string,
    name: string
}

export function updateRoleModal(role: Role, deleteRole: (Role)=>any): Promise<any> {
    return new Promise((resolve, reject) => {
        const {id, name} = role;
        //Must reset the form before we show it.

        dispatch(actions.load('form.role', {id, name}));

        dispatch(modalController.show({
            title: `Update Role`,
            body: <div>
                <UserForm onSubmit={(_values) => {
                    let values = {..._values};

                    values.authorities = [values.role];
                    values.authority = values.role;

                    delete values.role;

                    roleApi.update(values)
                        .then(response => {
                            dispatch(modalController.close());
                            dispatch(actions.setPending('form.user', false));
                            return response;
                        }).then(resolve)
                        .catch(err => {
                            dispatch(actions.setErrors('form.user', {err}));
                            dispatch(actions.setPending('form.user', false));
                            return reject(err);
                        });
                }}/>
            </div>,
            footer: <UserFormButtons onDelete={() => {
                if (role && role.id) {
                    confirmModal('Delete User', `Are you sure you want to delete '${role.email}'?`).then(value => {
                        if (value && role) {
                            deleteRole(role);
                            dispatch(modalController.close());
                        }
                    })
                }
            }}/>,
            closeButton: true,
            static: true,
        }));
    })

}