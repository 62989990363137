// @flow
import api from './';

export type TokenType = {
  id: string,
  name: string,
  role: string

}

export function get(){
  return api('get', `/api/internal/v1/tokens`)
}

export function create(data: TokenType){
  return api('post', `/api/internal/v1/tokens`, {
    data
  })
}

export function remove(id: string){
  return api('delete', `/api/internal/v1/tokens/${id}`)
}