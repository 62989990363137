// @flow
import React from 'react';

import {connect} from 'react-redux';

import {getStripeCheckout} from '../../../config/stripeCheckout'

import BillingView from './BillingView'

import {confirmModal} from '../../../modals/ConfirmModal';
import {modal} from '../../../modals/BasicModal';

import CCIcon from '../../../components/CCIcon';

import {FaCheckCircle, FaExclamationTriangle} from 'react-icons/lib/fa';

import * as subscriptionApi from '../../../api/billing/subscription';

import { withFeatureflow } from  'react-featureflow-client';
import {dispatch} from "../../../redux";
import * as subscriptionOutline from "../../../redux/subscriptionOutline";
import type {CardInfoType, Coupon, InvoiceLineItem, Price, BillingUsage} from "../../../api/billing/types";



const priceSort = function (a, b) {
    let keyA = a.meta.order,
        keyB = b.meta.order;
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
};

class Billing extends React.Component {
    state: {
        updating: boolean,
        prices?: Price[],
        info?: CardInfoType,
        usage?: BillingUsage,
        invoices?: InvoiceLineItem[],
        couponCode?: string,
        coupon?: Coupon
    } = {
        prices: undefined,
        info: undefined,
        updating: false,
        usage: undefined,
        history: undefined
    };

    componentDidMount() {
        Promise.all([
            subscriptionApi.getPrices(),
            subscriptionApi.getBillingInfo(),
            subscriptionApi.getBillingUsage(),
            subscriptionApi.getBillingInvoices(),
        ]).then(([prices, info, usage, history]) => {
            setTimeout(() => {
                this.setState({
                    prices: prices.sort(priceSort),
                    info,
                    usage,
                    invoices: history.items.filter(invoice => invoice.paid)
                })
            }, 1);
        }).catch(console.warn);
    }

    updateCardDetails(): void {
        let config = {
            name: 'Update Credit Card',
            email: this.props.email,
            panelLabel: 'Update',
            token: (token) => {
                this.setState({
                    info: undefined
                });
                subscriptionApi.addCardToken(token.id)
                    .then((info) => {
                        this.setState({
                            info
                        })
                    })
            }
        };
        getStripeCheckout(this.props.organisation.stripeKey).open(config);
    }

    cancelSubscription() {
        confirmModal(`Cancel subscription`,
            <div>
                Are you sure you wish to cancel your subscription and move back to the free tier?
            </div>
        ).then(result => {
            if (result) {
                this.setState({updating: true});
                return subscriptionApi.cancelSubscription()
            }
            else {
                return Promise.reject();
            }
        }).then(result => {
            dispatch(subscriptionOutline.setSubscription(result));
            modal('Subscription Cancelled',
                <div style={{textAlign: 'center', height: 250}}>
                    <div style={{fontSize: 100, color: '#60c3a7'}}>
                        <FaCheckCircle/>
                    </div>
                    <b>Your subscription cancellation was successful.</b>
                </div>
            ).catch(console.warn);
            this.setState({updating: false});
        }).catch(err => {
            modal('Error Cancelling Subscription',
                <div style={{textAlign: 'center', height: 250}}>
                    <b>There was an error cancelling your subscription.</b>
                    <br/> <br/>
                    Please contact <a href="mailto:billing@featureflow.io">billing@featureflow.io</a> if the issue
                    persists.
                </div>
            ).catch(console.warn);
            this.setState({updating: false});
        });
    }

    getCoupon(couponCode: string) {
        subscriptionApi.getCoupon(couponCode)
            .then(coupon => {
                this.setState({
                    coupon: coupon
                })
            }).catch(error => {
        });
    }


    subscribeToPrice(price: Price, seats?: number) {
        if (!this.state.info) {
            return;
        }

        const hasCardDetails = !!this.state.info.lastFour;

        let subscriptionPromise;

        if (hasCardDetails) {
            const cardDetails = this.state.info;
            subscriptionPromise = confirmModal(`Subscribe to ${price.description}?`,
                <div>
                    Do you want to change your subscription to {price.productName}? <br/>
                    { seats &&
                        <>Your card will be charged ${(price.amount / 100 * seats).toFixed(2)}/{price.interval} ({price.currency.toUpperCase()}) for {seats} seats. <br/>
                        Changes take effect immediately and your account is pro-rated to reflect the change.
                        </>
                    }
                    { !seats &&
                        <>Your card will be charged ${(price.amount / 100).toFixed(2)}/{price.interval}({price.currency.toUpperCase()}). <br/></>
                    }
                    <br/>
                    Billing to: <b><CCIcon brand={cardDetails.brand}/> {cardDetails.brand} ending
                    in {cardDetails.lastFour}</b>
                    <br/>
                    {
                        this.props.featureflow.evaluate('coupons').isOn() &&
                        <span>
                            If you have a coupon code, enter it here:
                              <input value={this.state.couponCode}
                                     onBlur={(event)=>{
                                         this.getCoupon(event.target.value, this.state.couponCode)
                                     }}/>
                        </span>
                    }
                </div>
            );
        }
        else {
            subscriptionPromise = new Promise((resolve, reject) => {
                let token = false;
                getStripeCheckout(this.props.organisation.stripeKey).open({
                    email: this.props.email,
                    name: price.productName,
                    description: price.description,
                    panelLabel: `Subscribe $${(price.amount / 100).toFixed(2)}/${price.interval} (${price.currency.toUpperCase()})`,
                    currency: price.currency.toUpperCase(),
                    token: (_token) => {
                        token = _token;
                    },
                    closed: () => {
                        if (token) {
                            this.setState({updating: true});
                            subscriptionApi.addCardToken(token.id)
                                .then((info) => {
                                    this.setState({info});
                                    return Promise.resolve(true);
                                })
                                .then(resolve)
                                .catch(reject);
                        }
                        else {
                            reject();
                        }
                    }
                });
            })
        }

        subscriptionPromise
            .then(result => {
                if (result) {
                    this.setState({updating: true});
                    return subscriptionApi.subscribe(price.id, seats)
                }
            })
            .then(result => {
                this.setState({updating: false});
                if (result) {
                    dispatch(subscriptionOutline.setSubscription(result));
                    confirmModal('Success',
                        <div style={{textAlign: 'center', height: 250}}>
                            <div style={{fontSize: 100, color: '#60c3a7'}}>
                                <FaCheckCircle/>
                            </div>
                            <b>Your subscription to {price.productName} was successful.</b>
                        </div>,
                     [
                        {
                            text: 'Ok',
                            value: true,
                            style: 'primary'
                        }
                    ]
                    ).catch(console.warn);
                }
            }).catch(e => {
            setTimeout(() => {

                this.setState({updating: false});
                modal('Warning',
                    <div style={{textAlign: 'center', height: 250}}>
                        <div style={{fontSize: 100, color: '#60c3a7'}}>
                            <FaExclamationTriangle/>
                        </div>
                        <b>Sorry, we were unable to subscribe you to the {price.productName} plan. Please contact us using the chat below and we will be happy to set you up.</b>
                    </div>
                ).catch(console.warn);
            }, 500)
        })
            ;
    }
//Tee ": React.Element" is flow describing the return type i.e. function foo(x: ?number): string {...return "mystring"}
    render(): React.Element<any> {
        return <BillingView info={this.state.info}
                            prices={this.state.prices}
                            subscription={this.props.subscription}
                            usage={this.state.usage}
                            invoices={this.state.invoices}
                            updateCardDetails={this.updateCardDetails.bind(this)}
                            subscribeToPrice={this.subscribeToPrice.bind(this)}
                            cancelSubscription={this.cancelSubscription.bind(this)}
                            updating={this.state.updating}
        />
    }
}


function mapStateToProps(state) {
    return {
        email: state.principalUserOutline.email,
        subscription: state.subscriptionOutline,
        organisation: state.organisation
    }
}
export default withFeatureflow({update: true})(connect(mapStateToProps)(Billing))
//export default connect(mapStateToProps)(Billing);
