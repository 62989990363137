// @flow
import React from 'react';

import SyntaxHighlighterWithCopy from '../SyntaxHighlighterWithCopy';

export function instructions(apiKey: string, featureKey: string){
//const newProject = `mvn archetype:generate -B -DarchetypeGroupId=org.apache.maven.archetypes -DarchetypeArtifactId=maven-archetype-quickstart -DarchetypeVersion=1.1 -DartifactId=featureflow-java-starter -Dversion=1.0-SNAPSHOT`;
    const newProject = `mvn archetype:generate -DgroupId=com.mycompany.app -DartifactId=my-app -DarchetypeGroupId=io.featureflow -DarchetypeArtifactId=featureflow-tutorial -DarchetypeVersion=1.0.1 -DinteractiveMode=false
cd my-app`;
    const pomXml =
`<dependency>
  <groupId>io.featureflow</groupId>
  <artifactId>featureflow-java-sdk</artifactId>
  <version>1.1.2</version>
</dependency>`;

const importFeatureflow = `import io.featureflow.client.*;`;

const instantiateClient = `public class App
{
    public static void main( String... args )
    {
      String apiKey = "${apiKey}";
      FeatureflowClient featureflow = FeatureflowClient.builder(apiKey).build();

      FeatureflowUser user = new FeatureflowUser("jimmy@example.com")
          .withAttribute("firstName", "Jimmy")
          .withAttribute("lastName", "Hendrix");


      if(featureflow.evaluate("${featureKey || ''}", user).isOn()){
        System.out.println("Showing feature to user");
      }
      else{
        System.out.println("Not showing feature to user");
      }
    }
}`;


    return (
      <ol>
        <li>
          Create a new Java project with Maven
            <SyntaxHighlighterWithCopy language="bash" code={newProject}/>
        </li>
        <li>
            Add the Featureflow SDK to your pom.xml within the &lt;dependencies&gt; section
          <SyntaxHighlighterWithCopy language="xml" code={pomXml}/>
        </li>
        <li>
          Open App.java in "/src/main/java/com/mycompany/app" and import the featurelow Client
          <SyntaxHighlighterWithCopy language="java" code={importFeatureflow}/>
        </li>
        <li>
          Instantiate the featureflow client and evaluate a feature
          <SyntaxHighlighterWithCopy language="bash" code={instantiateClient}/>
        </li>

      </ol>
    )
}


export function runCommand(){
  const runCommand = `mvn clean package && java -jar target/my-app-1.0-SNAPSHOT.jar`
  return <SyntaxHighlighterWithCopy language="bash" code={runCommand}/>
}