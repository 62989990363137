// @flow
import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';

import Alert from 'react-s-alert';

import { FaCopy } from 'react-icons/lib/fa';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import FaQuestionCircle from 'react-icons/lib/fa/question-circle';

export default function(props: {apiKey: ?string, title: string, tooltip: string}): React.Element<any>{
  let key = props.apiKey || '';
    const tooltip = (
        <Tooltip id="tooltip">
            {props.tooltip}
        </Tooltip>
    );
  return (
    <div style={{display: 'flex', marginBottom: 10}}>
        {props.tooltip &&
            <OverlayTrigger placement="top" overlay={tooltip}>
                <button className="link-button" style={{padding: '3px'}}><FaQuestionCircle/></button>
            </OverlayTrigger>
        }
        {props.title &&
              <span style={{width: 430, display: 'inline-block', 'paddingRight': '5px', 'paddingTop': '2px', 'textAlign': 'right'}}>
                 {props.title}:
              </span>
        }
      <input type="text"
             className={'form-control'} readOnly={true}
             onFocus={(event)=>{event.target.select()}}
             value={key}
             onChange={()=>{}}
      />
      <CopyToClipboard text={key} onCopy={()=>Alert.success('Copied to clipboard')}>
          <button className="link-button" style={{padding: '5px'}}><FaCopy/></button>
      </CopyToClipboard>
    </div>
  );
}