// @flow
import store from 'store';

export function getAuthToken(): ?string{
  return store.get('authenticationToken');
}

export function setAuthToken(jwtToken: string): void{
  store.set('authenticationToken', jwtToken);
}

export function clearAuthToken(): void{
  store.remove('authenticationToken');
}