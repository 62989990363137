// @flow
import React from 'react';

import { Button } from 'react-bootstrap'

export default class MyStepper extends React.Component{
  state:any;

  constructor(props: any){
    super(props);
    this.state = {
      step: 0
    }
  }
  render():React.Element<any>{
    const onClose = this.props.onClose || function(){};
    return (
      <div>
        <div style={{minHeight: 300, padding: 10}}>
            {this.props.children[this.state.step]}
        </div>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1, textAlign: 'left'}}>
            <Button style={{marginRight: 10}} onClick={onClose}>Skip</Button>
            {this.state.step > 0 && <Button onClick={()=>this.setState({step: this.state.step-1})}>Back</Button>}
          </div>

          <div style={{flex: 1, textAlign: 'right'}}>
            {this.state.step < this.props.children.length-1 && <Button onClick={()=>this.setState({step: this.state.step+1})}>Next</Button>}
            {this.state.step === this.props.children.length-1 && <Button onClick={onClose}>Done</Button>}
          </div>
        </div>
      </div>
    )
  }
}