// @flow
import React from 'react';
import * as tokenApi from '../../api/token';
import {
    Button,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Modal,
    Table
} from 'react-bootstrap';

import Alert from 'react-s-alert';
import {FaPlusCircle, FaRefresh, FaSpinner, FaTrash} from 'react-icons/lib/fa';
import {confirmModal} from "../../modals/ConfirmModal";
import ApiKeyCopyField from "../../components/ApiKeyCopyField";
import type {TokenType} from "../../api/token";

class Tokens extends React.Component {
    state: {
        initialFetch: boolean,
        tokens: TokenType[],
        showUpdate: false,
        token: {
            name: ''
        },
        validationState: null,
    };

    constructor(props: { }) {
        super(props);
        this.state = {
            initialFetch: true,
            tokens: [],
            showUpdate: false,
            token: {},
            validationState: null
        };
        this.handleShow = this.handleShow.bind(this); //more boilerplate so we can access this.setState from extended react object
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.fetchTokens();
    }

    fetchTokens() {
        tokenApi.get()
            .then(tokens => {
                this.setState({
                    initialFetch: false,
                    tokens
                })
            }).catch(error => {
        })
    }

    deleteToken(token: TokenType) {
        this.setState({
            removing: token.id
        });
        tokenApi.remove(token.id).then(() => {
            this.setState({
                removing: undefined,
                tokens: this.state.tokens.filter(_token => _token.id !== token.id)
            });

            Alert.success('Token has been deleted');

        })
        .catch(err => {
            Alert.error('There was an error deleting the token');
            this.setState({
                removing: undefined
            })
        })
    }

    handleClose() {
        this.setState({ showUpdate: false });
    }

    handleSave(event) {
        event.preventDefault();
        if(this.validateForm()==='error'){
            return;
        }

        tokenApi.create(this.state.token).then(token => {
            this.setState({token: {}});
            this.fetchTokens();
            Alert.success(token.name + ' has been created');
        })
        .catch(err => {
            Alert.error('There was an error creating the token');
        });
        this.handleClose();
    }

    handleShow() {
        this.setState({
            validationState : '',
            showUpdate: true,
            token: {}
        });
    }
    onNameChange (e) {
        let token = {...this.state.token}; //dear god
        token.name = e.target.value;
        this.setState({token}, function () { //it has to be a callback as it may be async set - gotcha!
            this.validateForm();
        });


    }

    validateForm() { //machine code?
        let validation = 'success';
        if(!this.state.token.name || this.state.token.name.length === 0) validation = 'error';
        this.setState({validationState : validation});
        return validation;
    }

    render(): React.Element<any> {
        return (
            <div>
                <h2>Personal Access Tokens</h2>
                <p>Personal Access tokens are used to authenticate to our <a href="https://docs.featureflow.io/v2.0/reference" target="_blank" rel="noopener noreferrer"> REST API</a>. You must treat them as secret.</p>
                {this.state.initialFetch ?
                    <FaRefresh style={{display: 'block', fontSize: 100, margin: '40px auto'}} className="fa-spin"/>
                    :
                    <span>
                        <div className="text-right">
                        <Button bsStyle="primary"
                                onClick={() => {
                                    this.handleShow();
                                }}>
                            New Token <FaPlusCircle style={{verticalAlign: 'text-bottom'}}/>
                        </Button>
                    </div>
                    <Table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>Token Name</th>
                            <th>Token</th>
                            <th className="text-right"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.tokens.map((token) => {
                            return (
                                <tr key={token.id}>
                                    <td>{token.name}</td>
                                    <td><ApiKeyCopyField apiKey={token.key} /></td>
                                    <td className="text-right">
                                        {
                                            this.state.removing === token.id ?
                                                <FaSpinner className="fa-spin"/>
                                                :
                                                <Button bsStyle="danger"
                                                        bsSize="xsmall"
                                                        onClick={() => {
                                                            confirmModal('Confirm Delete', `This is an irreversible action. Are you sure you want to delete "${token.name}"?`)
                                                                .then(value => {
                                                                    if (value) {
                                                                        this.deleteToken(token);
                                                                    }
                                                                });
                                                        }}>
                                                    Delete Token <FaTrash/>
                                                </Button>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </span>
                }
                <Modal show={this.state.showUpdate} >
                    <Form horizontal onSubmit={this.handleSave}>
                        <Modal.Header>
                            <Modal.Title>Access token</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                <FormGroup
                                    controlId="name"
                                    validationState={this.state.validationState}>
                                    <Col componentClass={ControlLabel} sm={2}>
                                        Name
                                    </Col>
                                    <Col sm={10}>
                                        <FormControl type="name" placeholder="Name" onChange = {this.onNameChange} value={this.state.token.name} />
                                        <FormControl.Feedback />
                                    </Col>

                                </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="link" onClick={this.handleClose}>
                                Close
                            </Button>
                            <Button bsStyle="primary" type="submit" >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>

        )
    }
}

export default Tokens;