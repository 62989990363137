    // @flow

export function convertStringToKey(name: string): string {
    if(!name) return '';
    return name
        .replace(/^\s/, '')
        .replace(/_[\s\-_]+/, '_')
        .replace(/[\s\-_]{2,}/, '-')
        .toLowerCase()
        .replace(/[^a-z0-9_]+/g, '-');
}
export function convertStringToUpperCaseKey(name: string): string {
    if(!name) return '';
    return name
        .replace(/^\s/, '')
        .replace(/_[\s\-_]+/, '_')
        .replace(/[\s\-_]{2,}/, '-')
        .toUpperCase()
        .replace(/[^A-Z0-9_]+/g, '-');
}

export function convertNameToKey(name: string): string {
    //matchwords
    // - 1 word shorten to 5
    // - 2 words concat, shorten to 5
    if (typeof name === 'undefined' || name == null) {
        return "";
    }
    let words = name.trim().toLowerCase().replace(/[^A-Z^a-z0-9]+/g, ' ').split(' ');
    if (words.length > 2) {
        return words.reduce((acronymn, next) => {
            return acronymn + next[0]
        }, '').slice(0, 5);
    }
    else {
        return words.join('')
            .split('')
            .reduce((word, next) => {
                return word.length === 5
                    ? word.substr(0, 4) + next
                    : word + next
            }, '');
    }

}