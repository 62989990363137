// @flow
import React from 'react';
import {withFeatureflow} from 'react-featureflow-client';
import Col from 'react-bootstrap/lib/Col';
import {isAdmin} from "../../utils/authentication";

class JiraIntegration extends React.Component {
    render(): React.Element<any> {
        /*let props = this.props;
        let {organisation, principalUser, featureflow} = props;*/
        if (!isAdmin()) {
            return (
                <div>
                    <Col xs={12} style={{display: 'flex', paddingTop: 20, paddingBottom: 10}}>
                        <h2 style={{flex: 1, margin: 0}}>
                            Integrations
                            &nbsp;
                            &nbsp;
                        </h2>
                    </Col>
                    <Col xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
                        <div style={{textAlign: 'center', marginTop: 100, marginBottom: 100}}>
                            <p>Only organisation admins may manage integrations. </p>
                        </div>
                    </Col>
                </div>
            )
        }

        return (
            <div>

            </div>
        )
    }
}

export default withFeatureflow({update: true})(JiraIntegration);