// @flow
import React, {useEffect, useState} from 'react';

import console from '../../utils/debug';
import {isAllowed, restrictUser} from '../../utils/authentication';
import {withRouter} from 'react-router';
import Alert from "react-s-alert";
import {
    ListGroup,
    ListGroupItem,
    ButtonToolbar,
    Button,
    Tooltip,
    OverlayTrigger,
    Pagination,
    FormControl,
    FormGroup,
    ControlLabel,
    Row,
    Col,
    Form
} from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";

import ToggleButton from 'react-toggle-button';

import fuzzysearch from 'fuzzysearch';

import {FaEdit, FaFlag, FaSpinner} from 'react-icons/lib/fa';

import {updateFeatureModal} from '../../modals/FeatureFormModal';
import {updateRulesModal} from '../../modals/RulesFormModal';
import {confirmModal} from '../../modals/ConfirmModal';
import type { Limits } from '../../api/subscription';

import FeatureStatusIndicator from '../../components/FeatureStatusIndicator';

import { useFeatureflow } from 'react-featureflow-client';

import { useUpdateFeatureMutation, useUpdateFeatureControlMutation } from '../../api/featureflowApi';

import {dispatch} from '../../redux';
import {numberLessThanLimit} from "../../utils/limits";

import type { Environment } from '../../api/environment'
import {actions} from "react-redux-form";
type Props = {
    environment: Environment,
    featureList: any[],
    match: any[],
    filterText: string,
    limits: Limits, 
    project: any,
    createFeature: (featureName: string) => void,
    location: any
};

type State = {
    activePage: number,
    pageSize: number,
    filterText: string,
    updatingFeature: string,
}

const FeatureList = (props: Props): React.Element<any> => {
    const featureflow = useFeatureflow();
    const featureDetailPageFlag = featureflow.evaluate('new-feature-detail-page');

    const [state, setState] = useState<State>({
            activePage: 1,
            pageSize: 50,
            filterText: '',
            updatingFeature: ''
    });

    const [updateFeatureControl, { isLoading: updateFeatureControlIsLoading, isSuccess: updateFeatureIsSuccess, isError: updateFeatureIsError, error: updateFeatureError }] = useUpdateFeatureControlMutation();

    const doToggleFeature = (featureControl: any, enabled: boolean) => {
        setState({...state, updatingFeature: featureControl.id});
        enabled = !enabled;      
        updateFeatureControl({...featureControl, enabled});

    }
    const toggleFeature = (featureControl: any, value: boolean) => {
        if (!props.environment.production) {
            return doToggleFeature(featureControl, value);
        }
        confirmModal(
            <div>
                Toggling feature in a production environment
            </div>,
            <p>
                Warning, this is a production environment.<br/><br/>
                Are you sure you wish to turn <b>{value ? 'off' : 'on'}</b> <i>'{featureControl.key}'</i>?
            </p>
        ).then(response => {
            if (response) {
                doToggleFeature(featureControl, value);
            }
        })
    }
    const { createFeature, environment, featureList, filterText: filteredText, limits, project } = props;
    const paginationItemCount = Math.ceil(featureList.length / state.pageSize);
    let paginationItems = [];
    for (let number = 1; number <= paginationItemCount; number++) {
        paginationItems.push(
            <Pagination.Item key={number} active={number === state.activePage} onClick={() => {
                setState({...state, activePage: number})
            }}>{number}</Pagination.Item>
      );
    }
    const filterText = filteredText.trim().toLowerCase();
    const filteredFeatures = featureList.filter(feature => {
        return filterText.length === 0
            || fuzzysearch(filterText, feature.key)
            || fuzzysearch(filterText, feature.name)
            || (feature.tags || []).filter(tag => fuzzysearch(filterText, tag)).length > 0;
    });
    const filteredFeatureList = filteredFeatures.slice((state.activePage - 1) * state.pageSize, (state.activePage * state.pageSize));

    useEffect(() => {
        if(props.filterText !== state.filterText){
            setState({...state, activePage: 1});
        }
    }, [props]);

    useEffect(() => {
        if(updateFeatureIsSuccess){
            Alert.success(`Feature saved.`);
        }
    }, [updateFeatureIsSuccess]);

    useEffect(() => {
        if(updateFeatureIsError){
            Alert.warning(`We encountered an error, the feature was not saved. ${updateFeatureError.error || ''}`);
        }
    }, [updateFeatureIsError]);

    return (
        <>            
            <ListGroup className={"tourStep1"}>
                {featureList.length === 0 &&
                <div style={{margin: 50, textAlign: 'center', fontSize: 30}}>
                    There are no features in this project yet...
                </div>
                }
                {filterText.length > 0 && filteredFeatures.length === 0 &&
                <div style={{margin: 50, textAlign: 'center', fontSize: 30}}>
                    No results for "{filterText}"
                    <br/>
                    <br/>                    
                    <Button
                        disabled={(!numberLessThanLimit(featureList.length, limits.maxFeatures) && featureflow.evaluate('billing').isOn())}
                        onClick={() => {
                            createFeature(filterText)
                        }}>
                        Create new feature "{filterText}"
                    </Button>                                    
                </div>}
                {filteredFeatureList.map(feature => {
                    const featureControl = feature.controls[environment.key] || {};                
                    let featureDetailsTooltip = <Tooltip id={feature.id + 'details'}>Show Details
                        for {feature.key}</Tooltip>;
                    let editFeatureTooltip = <Tooltip id={feature.id + 'edit'}>Edit {feature.key}</Tooltip>;
                    let toggleTooltip = <Tooltip
                        id={feature.id + 'toggle'}>{featureControl.enabled ? 'Disable' : 'Enable'} {feature.name} in {environment.name}</Tooltip>;
                    let rulesTooltip = <Tooltip id={feature.id + 'rules'}>Update rules
                        for {feature.key} in {environment.name}</Tooltip>;
                    return (
                        <ListGroupItem key={feature.key}
                                        style={{
                                            display: 'flex',
                                            borderLeftColor: featureControl.enabled ? '#60c3a7' : '',
                                            borderLeftWidth: featureControl.enabled ? 3 : 1
                                        }}>
                            <div style={{flex: 1, overflow: 'hidden'}}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    marginRight: 10
                                }}>
                                    <div style={{flex: 1}}>
                                        {featureDetailPageFlag.isOff() &&
                                        <>
                                            {isAllowed('update', `projects/${project.key}/environments/${environment.id}/controls/${featureControl.key}`, feature.tags, environment.production ? ['production'] : []) &&
                                            <OverlayTrigger
                                                overlay={editFeatureTooltip} placement="top"
                                                delayShow={300} delayHide={150}
                                                onClick={() => updateFeatureModal(props, feature).catch(console.warn)}
                                            >
                                                <Button bsStyle="link" style={{padding: '5px 10px'}} size="lg">
                                                    <FaEdit size="1.2em"/>
                                                </Button>
                                            </OverlayTrigger>
                                            }
                                        </>
                                        }
                                        <LinkContainer to={{pathname: `${props.location.pathname}/${feature.key}`}}>
                                            <OverlayTrigger
                                                overlay={featureDetailsTooltip} placement="top"
                                                delayShow={300} delayHide={150}>
                                                <Button bsStyle="link" style={{padding: '5px 0'}}>
                                                    {feature.name}
                                                </Button>
                                            </OverlayTrigger>
                                        </LinkContainer>

                                        <span style={{
                                            color: feature.description ? '#777' : '#aaa',
                                            fontSize: 12,
                                            width: '100%',
                                            paddingRight: 10,
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis'
                                        }}>
                                    &nbsp;{feature.description || ''}
                                    </span>                                    
                                        <span className="Select--multi" style={{fontSize: 13}}>
                                            {(feature.tags || []).map((tag, index) => {
                                                return <span key={index} className="Select-value"><span
                                                    className="Select-value-label">{tag}</span></span>
                                            })}
                                        </span>
                                    </div>

                                </div>

                            </div>
                            <div style={{width: 320}}>
                                <div style={{
                                    height: 30,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}>
                                    <OverlayTrigger
                                        overlay={toggleTooltip} placement="top"
                                        delayShow={300} delayHide={150}>
                                        <div style={{paddingRight: 10, paddingLeft: 10}}>
                                            {featureControl && featureControl.key && isAllowed('update', `projects/${project.key}/environments/${environment.id}/controls/${featureControl.key}`, feature.tags, environment.production ? ['production'] : []) &&
                                                <ToggleButton 
                                                thumbIcon={updateFeatureControlIsLoading && state.updatingFeature===featureControl.id && <FaSpinner className="fa-spin" style={{verticalAlign: 'text-top', marginLeft: 2, marginTop: 1}}/>}
                                                value={featureControl.enabled}
                                                onToggle={(value) => toggleFeature({...featureControl}, value)}/>    
                                            }
                                        </div>
                                    </OverlayTrigger>
                                    <ButtonToolbar style={{display: 'inline-block'}}>
                                        {featureDetailPageFlag.isOff() &&
                                        <>
                                            {isAllowed('update', `projects/${project.key}/environments/${environment.id}/controls/${featureControl.key}`, feature.tags, environment.production ? ['production'] : []) &&
                                            <OverlayTrigger
                                                overlay={rulesTooltip} placement="top"
                                                delayShow={300} delayHide={150}
                                                onClick={
                                                    () => restrictUser('update', `projects/${project.key}/environments/${environment.id}/controls/${featureControl.key}`, feature.tags, environment.production ? ['production'] : [], () => {
                                                        updateRulesModal(
                                                            featureControl,
                                                            feature,
                                                            environment
                                                        ).catch(console.warn)
                                                    })
                                                }
                                            >
                                                <Button bsStyle="primary" bsSize="xsmall">
                                                    Update Rules <FaFlag/>
                                                </Button>
                                            </OverlayTrigger>
                                            }
                                        </>
                                        }
                                        <FeatureStatusIndicator featureKey={feature.key}
                                                                available={featureControl.available}
                                                                trafficStatus={featureControl.trafficStatus}
                                                                targetingStatus={featureControl.targetingStatus}
                                                                staleDays={featureControl.staleDays}
                                                                environment={environment.name}
                                                                style={{marginLeft: 10}}
                                        />
                                    </ButtonToolbar>
                                </div>
                            </div>
                        </ListGroupItem>
                    )
                })}
                {paginationItems && filteredFeatures.length > 0 &&
                    <Row style={{marginTop: 10}}>
                        <Col sm={9}>
                            <Pagination
                                bsSize="medium"
                            >{paginationItems}</Pagination>
                        </Col>
                        <Col sm={3} style={{textAlign: 'right'}}>
                            <Form inline>
                                <FormGroup controlId="pageSizeId" bsSize="small">
                                    <ControlLabel>Page Size</ControlLabel>
                                    &nbsp;
                                    <FormControl componentClass="select"
                                                    value={state.pageSize}
                                                    placeholder="select"
                                                    onChange={(event) => {
                                                        setState({
                                                            ...state,
                                                            pageSize: event.target.value,
                                                            activePage: 1
                                                        })
                                                    }
                                                    }>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={500}>500</option>
                                    </FormControl>
                                </FormGroup>
                            </Form>
                        </Col>

                    </Row>
                }
            </ListGroup>            
        </>
    )
}

export default withRouter(FeatureList);