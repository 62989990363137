// @flow
import store from 'store';

function key(userId:string): string{
  return `${userId}:route`;
}

function pathKey(userId:string): string{
    return `${userId}:path`;
}

type RouteStorage = {
  projectKey: string,
  environmentKey: string
}

export function setLastRoutePath(userId: string, path: string): void{
    store.set(pathKey(userId), {
        path
    });
}

export function getLastRoutePath(userId: string): ?RouteStorage{
    return store.get(pathKey(userId))?store.get(pathKey(userId)).path:null;
}

export function removeLastRoutePath(userId: string): void{
    return store.remove(pathKey(userId));
}

export function setLastRoute(userId: string, projectKey: string, environmentKey: string): void{
  store.set(key(userId), {
    projectKey,
    environmentKey
  });
}

export function getLastRoute(userId: string): ?RouteStorage{
  return store.get(key(userId));
}

export function removeLastRoute(userId: string): void{
  return store.remove(key(userId));
}