// @flow
import api from './';

type InviteType = {
  email: string,
  firstName: ?string,
  lastName: ?string
}

type InvitedPostType = {
  firstName: string,
  lastName: string,
  password: string,
  activationKey: string
}

export function inviteUsers(data: InviteType[]){
  return api('post', `/api/v1/invites`, {
    data
  })
}

export function getInvitedUser(activationKey: string){
  return api('get', `/api/v1/signup/invitee/${activationKey}`)
}


export function signupInvitedUser(data: InvitedPostType){
  return api('post', '/api/v1/signup/invitee/', {
    data
  })
}