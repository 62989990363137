import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-select/dist/react-select.css';
import './css/index.scss';
import './css/featureflow.scss';
import './css/cosmo3.scss';
import constants from './config/constants';

import { disconnect } from './socket';

const rootEl = document.getElementById('root');

ReactDOM.render(
  <App />,
  rootEl
);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    console.clear();
    disconnect();
    ReactDOM.render(
      <NextApp />,
      rootEl
    );
  });
}

window.Intercom("boot", {
    app_id: constants.INTERCOM_KEY
});