import React, {useState} from 'react';
import {Button, Col, FormControl, FormGroup, Panel, Row} from "react-bootstrap";

function EnterprisePricingPlanNew() {
    return (
        <Col lg={3} md={3} sm={6} xs={12}>
            <Panel>
                <Panel.Heading style={{fontSize: 18, textAlign: 'center'}}>Enterprise</Panel.Heading>
                <Panel.Body style={{textAlign: 'center'}}>
                    <h3 style={{marginTop: 0}}>sales@featureflow.io</h3>
                    <p style={{fontSize: 18}}>

                    </p>
                    <b>Features</b>
                    <ul style={{textAlign: 'left', paddingBottom: '38px'}}>
                        <li>Unlimited feature flags, projects and environments</li>
                        <li>Comprehensive feature targeting rules and progressive
                            rollout
                        </li>
                        <li>Feature flag dashboard, analytics and real time status
                        </li>
                        <li>Access to all SDKs across Server, Web and Mobile</li>
                        <li>Slack and Jira Integration</li>
                        <li>High retention Audit logs</li>
                        <li>Millions of frontend MAU</li>
                        <li>API access</li>
                        <li>Experimentation</li>
                        <li>Enterprise support plans</li>
                    </ul>

                    <Button onClick={() => {
                        window.Intercom('show');
                    }}
                            bsStyle="primary" bsSize="sm">
                        Contact us
                    </Button>
                </Panel.Body>
            </Panel>
        </Col>
    )
}

export default EnterprisePricingPlanNew;
