// @flow
import React from 'react';
import './Activate.scss';

import { dispatch } from '../redux';
import { actions, Form } from 'react-redux-form';
import { connect } from 'react-redux';

import * as signupFlowApi from '../api/signupFlow';

import { createSelector } from '@reduxjs/toolkit'

import ControlWrapper from '../forms/controls/ControlWrapper';

import { Nav, NavItem, Button } from 'react-bootstrap';

import ActionButton from '../forms/controls/ActionButton';

import PasswordStrength from '../components/PasswordStrength';

function pinMask(text: string): string{
  return text.trim().replace(/[^0-9]+/g, '').slice(0,6);
}

function domainMask(text: string):string{
  return text.trim().toLowerCase().replace(/[^a-z0-9]+/g, '-');
}

function verifyPinCheck(props, model: string, value: string){
  props.dispatch(actions.change(model, value));
  const { form } = props;
  if (value.length === 6 && !form.$form.pending){
    props.dispatch(actions.setTouched('form.signup.pin', true));
    props.dispatch(actions.setPending('form.signup', true));
    //TODO Add Debounce
    signupFlowApi.validatePin(props.signup.email, props.signup.once, value).then(response=>{
      props.dispatch(actions.focus('form.signup.password'));
      props.dispatch(actions.setPending('form.signup', false));
      props.dispatch(actions.setValidity('form.signup.pin', true));
      props.dispatch(actions.change('form.signup.activateIndex', 1));
    }).catch(err=>{
      props.dispatch(actions.setPending('form.signup', false));
      props.dispatch(actions.setErrors('form.signup.pin', {
        error: 'The pin you have entered is invalid.'
      }));
    });
  }
}

function verifyPasswordIsTheSame(props, otherPassword, model, value){
  props.dispatch(actions.change(model, value));
  if (value !== otherPassword && props.form.confirmPassword.touched){
    props.dispatch(actions.setErrors('form.signup.confirmPassword', {
      nomatch:'The two passwords do not match.'
    }));
  }
  else{
    props.dispatch(actions.setValidity('form.signup.confirmPassword', true));
  }
}

function confirmEmail(props){
  return (
    <div style={{textAlign: 'center'}}>
      <h2>Check your email!</h2>
      <p>
        We've sent you a six digit confirmation pin. Enter it below to confirm your email address.
      </p>
      <br/>
      <ControlWrapper model=".pin"
                     parser={ pinMask }
                     validateOn={['change']}
                      bsSize="lg"
                      formControlStyle={{
                        fontSize: 70,
                        height: 70,
                        textAlign: 'center',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        boxShadow: 'none'
                      }}
                     changeAction={ verifyPinCheck.bind(this, props) }
      />
      <Button
        disabled={!props.form.pin.valid || props.signup.pin.length < 6 || props.form.$form.pending}
        onClick={()=>dispatch(actions.change('form.signup.activateIndex', 1))}
      >
        Next
      </Button>
        <p>
           <br /> <b>No Email?</b> Give it a few seconds and ensure you check your spam folder!
        </p>
    </div>
  );
}


function setPassword(props){
  return (
    <div style={{textAlign: 'center'}}>
        <h3>Set Password!</h3>
      <ControlWrapper model=".password"
                     type="password"
                     label="Enter Password"
                     errorMessages={{
                       passwordMinLength: 'Your password should be greater than 4 characters long'
                     }}
                     changeAction={ verifyPasswordIsTheSame.bind(this, props, props.signup.confirmPassword)}
      />
      <PasswordStrength password={props.signup.password} style={{marginTop: -16, marginBottom: 8}}/>
      <ControlWrapper model=".confirmPassword"
                     label="Confirm Password"
                     validateOn={['change']}
                     type="password"
                     changeAction={ verifyPasswordIsTheSame.bind(this, props, props.signup.password) }
      />
      <Button disabled={props.signup.password !== props.signup.confirmPassword
        || !props.form.password.valid
        || !props.form.confirmPassword.valid}
              bsStyle="primary"
              onClick={()=>dispatch(actions.change('form.signup.activateIndex', 2))}
      >
        Next
      </Button>
    </div>
  );
}

function addUserInfo(props){
  return (
    <div style={{textAlign: 'center'}}>
      <h3>Add Your information</h3>
      <ControlWrapper model=".firstName"
                      type="text"
                      label="Enter First Name"
                      errorMessages={{
                       required: 'First name is required'
                     }}
      />
      <ControlWrapper model=".lastName"
                      label="Enter Last Name"
                      type="text"
                      errorMessages={{
                       required: 'Last name is required'
                     }}
      />
      <Button disabled={props.signup.firstName.length === 0 || props.signup.lastName.length === 0}
              bsStyle="primary"
              onClick={()=>dispatch(actions.change('form.signup.activateIndex', 3))}
      >
        Next
      </Button>
    </div>
  );
}


function checkDomainAvailability(props, model, value){
  props.dispatch(actions.change(model, value));
  props.dispatch(actions.setErrors('.organisationDomain', {
    unavailable: false
  }));
  if (value.length >= 4){
    props.dispatch(actions.setPending('form.signup', true));
    //TODO Add Debounce
    signupFlowApi.checkDomain(value)
      .then(()=>{
        props.dispatch(actions.setPending('form.signup', false));
        props.dispatch(actions.setValidity('form.signup.organisationDomain', true));
      })
      .catch(()=>{
        props.dispatch(actions.setErrors('form.signup.organisationDomain', {
          unavailable: 'The domain name is unavailable'
        }));
        props.dispatch(actions.setPending('form.signup', false));
      });
  }
}


function createOrganisation(props){
  return (
    <div style={{textAlign: 'center'}}>
        <h3>Create your organisation</h3>
        <p>Enter your organisation name and choose a subdomain - and welcome!</p>

        
      <ControlWrapper model=".organisationName"
                     type="text"
                     label="Enter your Organisation name"
                     errorMessages={{
                       isRequired: 'The Organisation name is required'
                     }}
      />
      <span>
             <ControlWrapper model=".organisationDomain"
                             type="text"
                             label="Choose the address you’ll use to sign in to featureflow"
                             errorMessages={{
                                 isRequired: 'The Organisation url is required',
                                 passwordMinLength: 'url must be at least 4 characters long'
                             }}
                             updateOn={['change']}
                             parser={domainMask}
                             changeAction={checkDomainAvailability.bind(this, props)}
             />
      </span>
      <h2 style={{textAlign: 'center'}}>
        <b>{props.signup.organisationDomain || <span style={{color:'#999'}}>[domain]</span>}</b>.featureflow.io
      </h2>
        {
            !props.form.organisationDomain.errors.unavailable &&
            !props.form.organisationDomain.errors.isRequired &&
            !props.form.organisationDomain.errors.passwordMinLength &&
            <div>
                You've found a good one - this domain is available!<br/>
            </div>
        }
        {
          props.form.organisationDomain.errors.unavailable &&
          <div>
            This is unavailable. Maybe try one below.<br/>
              <button className="link-button"
              onClick={
                   checkDomainAvailability.bind(this, props, 'form.signup.organisationDomain', props.signup.organisationDomain+'-global')
                 }>
                {props.signup.organisationDomain}-global
              </button><br/>
            <button className="link-button"
                    onClick={
                   checkDomainAvailability.bind(this, props, 'form.signup.organisationDomain', props.signup.organisationDomain+'-group')
                 }>
              {props.signup.organisationDomain}-group
            </button><br/>
            <button className="link-button"
                    onClick={
                   checkDomainAvailability.bind(this, props, 'form.signup.organisationDomain', props.signup.organisationDomain+'-company')
                 }>
              {props.signup.organisationDomain}-company
            </button><br/>
          </div>
        }
      <ActionButton disabled={!props.form.$form.valid || props.form.$form.pending}
                    spinner={props.form.$form.pending}
                    bsStyle="primary"
                    style={{margin: 20}}
                    onClick={()=>finishSignup(props)}>
        Create!
      </ActionButton>
    </div>
  );
}

function finishSignup(props){
  props.dispatch(actions.setPending('form.signup', true));
  signupFlowApi.createAccount(props.signup)
    .then(response=>{

      if (response.token){
        let newLocation = [props.signup.organisationDomain].concat(
          (window.location.hostname.split('.').slice(1))
        ).join('.');
        window.location = '//' +newLocation + '/login/' + response.token;
      }
      else{
        return Promise.reject('Signup failed, no signup token sent');
      }
    }).catch(error=>{
      props.dispatch(actions.setErrors('form.signup', {
        serverError: error
      }))
      props.dispatch(actions.setPending('form.signup', false));
    })
}

const selectForm = state => state.form;
const getSignup = createSelector(
  selectForm,
  form => form.signup
);

const getForm = createSelector(
  selectForm,
  form => form.forms.signup
);

function mapStateToProps(state){
  return {
    signup: getSignup(state),
    form: getForm(state)
  }
}


const steps = [
  {
    title: 'Confirm Email',
    render: confirmEmail
  },
  {
    title: 'Password',
    render: setPassword
  },
  {
    title: 'Your Name',
    render: addUserInfo
  },
  {
    title: 'Create Organisation',
    render: createOrganisation
  }
];

export default connect(mapStateToProps)(function(props: any): React.Element<any> {
  const navItems = steps.map(
    (step, index)=>(
      <NavItem key={index} disabled={true} eventKey={index} style={{padding: 'none'}}>
        {`${index+1}) ${step.title}`}
      </NavItem>
    )
  );

  return (
    <div id="activate-container">
      <div id="activate">
        <div id="activate-box">
          <Nav bsStyle="pills" activeKey={props.signup.activateIndex} justified={true}>
            {navItems}
          </Nav>
          <Form model="form.signup">
            {
              steps.map((step, index)=>{
                return (
                  <div key={index} style={props.signup.activateIndex !== index ? {display: 'none'} : undefined}>
                    {step.render(props)}
                  </div>
                )
              })
            }
          </Form>
        </div>
      </div>
    </div>
  );
})
