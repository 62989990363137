// @flow
const initialState = {
  id: undefined,
  name: '',
  url: '',
  color: '#1273de',
  local: false,
  isProduction: false,
  cloneEnvironmentId: undefined
};
export default initialState;