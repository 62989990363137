// @flow

const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  roles: []
};

export default initialState;
