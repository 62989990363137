// @flow
import React from 'react';

import * as modalController from '../redux/modalController';
import { Button} from 'react-bootstrap';

import { dispatch } from '../redux';

type ConfirmAction = {
  text: string,
  value: mixed,
  style?: 'primary'
    | 'default'
    | 'success'
    | 'info'
    | 'link'
    | 'warning'
    | 'danger'
}

const defaultActions: ConfirmAction[] = [
  {
    text: 'Cancel',
    value: false,
    style: 'default'
  },
  {
    text: 'Ok',
    value: true,
    style: 'primary'
  }
];

export function confirmModal(
  title: string | React.Element<any>,
  body: string | React.Element<any>,
  actions: ConfirmAction[] = defaultActions,
  closeButton: boolean = true,
  _static: boolean = true,
  _config: {[key: string]: any} = {}
): Promise<any>{
  return new Promise((resolve, reject)=>{
    dispatch(modalController.show({
      title,
      body: <div>{body}</div>,
      footer: (
        <div>
          {
            actions.map((action, index)=>(
              <Button key={index}
                      bsStyle={action.style}
                      onClick={()=>{
                        dispatch(modalController.close());
                        resolve(action.value);
                      }}
              >
                {action.text}
              </Button>
            ))}
        </div>
      ),
      closeButton,
      static: _static,
      keyboard: closeButton,
      ..._config
    }));
  })

}