// @flow
import api from './';



export function findOrgsByEmail(email: string){
  return api('post', '/api/v1/account/findorganisations', {
    data: {
      email
    }
  });
}

export function requestResetPassword(email: string) {
  return api('post', '/api/account/reset_password/init', {
    data: {
      email
    }
  });
}

export function resetPassword(key: string, password: string){
  return api('post', '/api/account/reset_password/finish', {
    data: {
      key,
      password
    }
  })
}