// @flow
import { createSelector } from '@reduxjs/toolkit'

const ORGANISATION_ADMIN = 'ROLE_ORGANISATION_ADMIN';

const selectPrincipalUserOutline = state => state.principalUserOutline;

const selectAuthorities = createSelector(
  selectPrincipalUserOutline,
  principalUser => principalUser.authorities || []
);

export const isOrganisationAdmin = createSelector(
  selectAuthorities,
  authorities => authorities.indexOf(ORGANISATION_ADMIN) >= 0
);