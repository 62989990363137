// @flow
import React from 'react';
import './ErrorContainer.scss';

export default  function(props: any): React.Element<any>{
    return (
        <small className="text-danger ErrorContainer" style={{display: 'block', minHeight: 20}}>
          {props.children}
        </small>
    )
}