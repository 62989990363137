import React from 'react';
import MyStepper from './MyStepper';


import AdminMenu from '../assets/images/onboarding/admin-menu.png';
import HomeMenu from '../assets/images/onboarding/home-menu.png';
import FeatureCreate from '../assets/images/onboarding/feature-create.png';
import FeatureRow from '../assets/images/onboarding/feature-row.png';
import NewFeatureButton from '../assets/images/onboarding/new-feature-button.png';
import ProjectMenu from '../assets/images/onboarding/project-menu.png';
import FeatureTargeting from '../assets/images/onboarding/feature-targeting.png';

export default function OnboardingCarousel(props){
  const { onClose } = props;
  return(
    <MyStepper onClose={onClose}>
      <div style={{textAlign:'center'}}>
        <h1><strong>Welcome to featureflow</strong></h1>
        <br/>
        <br/>
        <p>
          Thanks for signing up and welcome to featureflow!<br />
          Let's give you a brief introduction. You can view help at any time using the link in the top right of the page.
            <br/>
            Firstly bookmark this page now so that you can easily return later!
        </p>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>Administering projects and environments</strong></h1>
        <br/>
        <p>
          In featureflow you may create projects and within projects you may create environments.<br/>
          You would typically create a project for each application that you manage whilst your environments reflect your development, test and production environments.
        </p>
        <p>
          You can create and administer projects and environments in the projects admin page using the menu on the top right.
        </p>
        <img src={AdminMenu} alt="Admin Menu" style={{maxWidth: '90%', maxHeight: 450, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
            <h1><strong>Home</strong></h1>
            <br/>
            <p>The Home page gives you a list of recent features and any you have bookmarked or are maintaining.</p>
            <img src={HomeMenu} alt="Home Menu" style={{maxWidth: '90%', maxHeight: 200, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>Creating and managing features</strong></h1>
        <br/>
        <p>The projects and and environments menu allows you to view all features in a given project and environment.</p>
        <img src={ProjectMenu} alt="Project Menu" style={{maxWidth: '90%', maxHeight: 200, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>Creating and managing features</strong></h1>
        <br/>
        <p>
          Within a project you create features.
          You integrate the feature into your application using our open source SDKs
        </p>
        <p>You then have full control to targeting and rollout your feature from the featureflow dashboard.
        </p>
        <p>Clicking 'New Feature' in the features list allows you to define a new feature. A new feature is created for ALL environments.</p>
        <img src={NewFeatureButton} alt="New Feature Button" style={{maxWidth:  '90%', maxHeight: 60, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>Creating and managing features</strong></h1>
        <br/>
        <p>Give the feature a friendly name, a description and a unique key - they key is what your developer will use to identify the feature. You may wish to discuss this in your planning session with your developers.</p>
        <img src={FeatureCreate} alt="Feature Create" style={{maxHeight: 400, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>Creating and managing features</strong></h1>
        <br/>
        <p>Clicking the 'On/Off' toggle will toggle the feature as enabled or disabled in the current environment.</p>
        <p>Clicking the feature name shows the feature overview and allows you modify settings, rules and view an audit of changes.</p>
        <img src={FeatureRow} alt="Update Rules" style={{maxWidth: '90%', maxHeight: 200, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>Creating and managing features</strong></h1>
        <br/>
        <p>You can target your feature in many ways such as a percentage, or target specific users by any attribute.</p>
        <p>Saving the rules will save them for the specific environment only.</p>
        <img src={FeatureTargeting} alt="Rules Form" style={{maxWidth:  '90%', maxHeight: 400, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>Learn more</strong></h1>
        <br/>
        <br/>
        <p>There is plenty more to learn - head to <a href="http://docs.featureflow.io" target="_blank" rel="noopener noreferrer">docs.featureflow.io</a> for a more in depth tutorial,
        try the 5 minute quick starts and feel free to get in contact with us using the support links at the bottom of the page.</p>
        <br/>
        <p>Thanks and great to have you here!</p>
        <p>Oli and the featureflow team</p>
      </div>
    </MyStepper>
  );
}