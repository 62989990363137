import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Form, Fieldset} from 'react-redux-form';
import {createSelector} from 'reselect';

import Select from 'react-select';

import {convertStringToKey} from '../utils/formParsers';
import * as modalController from '../redux/modalController';

import {defaultVariant} from '../redux/form/feature';

import FaMinus from 'react-icons/lib/fa/minus';
import {isAllowed, isProdUser} from '../utils/authentication';

import ControlWrapper from './controls/ControlWrapper';
import FormErrors from './controls/FormErrors';
import {Button, Table, Row, Col, ButtonToolbar, Tooltip, OverlayTrigger} from 'react-bootstrap';
import ActionButton from './controls/ActionButton';
import JiraSelect from './controls/JiraSelect'

import {Control, actions} from 'react-redux-form';

import * as projectApi from '../api/project';

import {useFeatureflow} from 'react-featureflow-client'

import ToggleWrapper from './controls/ToggleWrapper';
import access from "safe-access";
import {browserHistory, withRouter} from "react-router";
import MemberSelect from "./controls/MemberSelect";
import {
    useGetProjectByKeyQuery,
    useDeleteFeatureMutation,
} from '../api/featureflowApi';

import {confirmModal} from "../modals/ConfirmModal";
import {dispatch} from '../redux';
import Alert from "react-s-alert";
import type {Feature, FeatureControl, Variant} from "../api/feature";
import {goToFeaturesRoute} from "../utils/routing";

const selectFeatures = state => state.features;

const getExistingKeys = createSelector(
    selectFeatures,
    features => Object.keys(features).map(key => features[key].key)
);

const selectFeatureForm = state => state.form.forms.feature;
const getKeyPristine = createSelector(
    selectFeatureForm,
    form => form.key.pristine
);

const getFeatureForm = createSelector(
    state => state.form.feature,
    form => form
)

function mapStateToProps(state, props) {
    return {
        existingKeys: getExistingKeys(state),
        keyPristine: getKeyPristine(state),
        featureForm: getFeatureForm(state),
        organisation: state.organisation,
    }
}

type Props = {
    feature: Feature,
    featureControl: FeatureControl,
    onSubmit: () => any,
    update: boolean
}

export default (withRouter(connect(mapStateToProps)(function (props: Props): React.Element<any> {
    const {featureForm, feature} = props;
    const featureflow = useFeatureflow();
    const {projectKey} = props.params;
    const {data: project, isLoading: isProjectLoading} = useGetProjectByKeyQuery(projectKey);


    const showVariants = featureForm.id || featureForm.showVariants;
    const prodUser = isProdUser();

    const isExistingVariant = (variant: Variant) => {
        if (!feature) {
            return false;
        }
        if (!feature || (feature.variants.filter(v => v.key === variant.key).length > 0 && feature.id)) {
            return true;
        }
        return false;
    }

    function showJira() {
        return featureflow.evaluate('jira-integration').isOn() &&
            access(props.organisation, 'settings.jiraEnabled');
    }

    function showMaintainer() {
        return featureflow.evaluate('maintainer').isOn();
    }

    if (isProjectLoading) {
        return <div></div>
    }

    return (
        <Form model="form.feature" onSubmit={props.onSubmit}>
            <Row>
                <Col sm={6}>
                    <ControlWrapper model=".name"
                                    type="text"
                                    label="Name"
                                    placeholder="Name"
                                    errorMessages={{
                                        isRequired: 'Name is required'
                                    }}
                                    changeAction={changeTest.bind(this, props)}
                    />
                </Col>
                <Col sm={6}>
                    <ControlWrapper model=".key"
                                    type="text"
                                    label="Key"
                                    placeholder="Key"
                                    errorMessages={{
                                        isRequired: 'Key is required'
                                    }}
                                    disabled={props.update}
                                    parser={convertStringToKey}
                                    changeAction={keyInputMask}
                    />
                </Col>
            </Row>
            <ControlWrapper model=".description"
                            componentClass="textarea"
                            label="Description"
                            placeholder="Description"
            />
            {featureflow.evaluate('tags').isOn() &&
                <ControlWrapper model=".tags"
                                label={'Tags'}
                                component={Select.AsyncCreatable}
                                multi={true}
                                ignore={["blur"]}
                                loadOptions={(input) => {
                                    return new Promise(resolve => {
                                        projectApi.getTags(project.key, convertStringToKey(input)).then(options => {
                                            resolve({
                                                options: options.map(option => {
                                                    return {
                                                        label: option,
                                                        value: option
                                                    }
                                                })
                                            })
                                        });
                                    })
                                }}
                                onInputChange={convertStringToKey}
                                promptTextCreator={input => {
                                    return `Add tag ${convertStringToKey(input)}`
                                }}
                                newOptionCreator={data => {
                                    const value = data.label.indexOf('Add tag ') === 0 ? data.label : convertStringToKey(data.label);
                                    return {
                                        value: value,
                                        label: value
                                    }
                                }}
                                isOptionUnique={data => {
                                    const {option, options, valueKey} = data;
                                    return options.map(option => option.value).indexOf(convertStringToKey(option[valueKey])) < 0;
                                }}
                                getValue={(value) => {
                                    if (!value) {
                                        return [];
                                    }
                                    return [].concat(value);
                                }}
                />
            }
            {showJira() &&

                <JiraSelect/>
            }
            {showMaintainer() &&
                <MemberSelect/>
            }


            <div style={{marginBottom: 20}}>
                <div style={{display: 'flex'}}>
                    <p style={{flex: 1, fontWeight: 'bold'}}>
                        Permanent Feature?
                    </p>
                    <div>
                        <Control.checkbox model=".permanent"
                                          component={ToggleWrapper}
                                          id="feature-permanent"
                                          label=""
                                          inactiveLabel="No"
                                          activeLabel="Yes"
                                          isToggle={true}
                        />
                    </div>
                </div>
            </div>
            {
                !showVariants &&
                <div style={{display: 'flex'}}>
                    <p style={{flex: 1}}>Using the default <b>on</b> and <b>off</b> variants.</p>
                    <Button onClick={() => {
                        props.dispatch(
                            actions.change(
                                `form.feature.showVariants`,
                                true
                            )
                        )
                    }} bsStyle="warning" bsSize="sm">Change variants</Button>
                </div>
            }
            {
                showVariants &&
                <div>
                    <h3>Variants</h3>
                    <Table condensed>
                        <thead>
                        <tr>
                            <th style={{border: 'none'}}>Name</th>
                            <th style={{border: 'none'}}>Key</th>
                            <th style={{border: 'none'}}>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.featureForm.variants.map((variant, variantIndex) => {
                            return (
                                <Fieldset component="tr" model={`.variants[${variantIndex}]`} key={variantIndex}>
                                    <td style={{borderTop: 'none'}}>
                                        <ControlWrapper model=".name"
                                                        type="text"
                                                        placeholder="Name"
                                                        errorMessages={{
                                                            isRequired: 'Name is required'
                                                        }}
                                                        style={{marginBottom: -10}}
                                        />
                                    </td>
                                    <td style={{borderTop: 'none'}}>
                                        <ControlWrapper model=".key"
                                                        type="text"
                                                        placeholder="Key"
                                                        disabled={isExistingVariant(variant)}
                                                        errorMessages={{
                                                            isRequired: 'Key is required'
                                                        }}
                                                        parser={convertStringToKey}
                                                        style={{marginBottom: -10}}
                                        />
                                    </td>
                                    <td style={{borderTop: 'none'}}>
                                        <div style={{display: 'flex'}}>
                                            <ControlWrapper model=".description"
                                                            type="text"
                                                            placeholder="Description"
                                                            style={{flex: 1, marginRight: 10, marginBottom: -10}}
                                            />
                                            <div>
                                                {!isExistingVariant(variant) &&
                                                    <Button bsStyle="danger"
                                                            bsSize="small"
                                                            style={{
                                                                fontSize: 12,
                                                                opacity: props.featureForm.variants.length <= 2 || isExistingVariant(variant) ? 0.5 : 1
                                                            }}
                                                            disabled={props.featureForm.variants.length <= 2 || isExistingVariant(variant)}
                                                            onClick={() => {
                                                                props.dispatch(
                                                                    actions.remove(
                                                                        `form.feature.variants`,
                                                                        variantIndex
                                                                    )
                                                                )
                                                            }}>
                                                        <FaMinus/>
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </td>
                                </Fieldset>
                            )
                        })}
                        </tbody>
                    </Table>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button bsStyle="primary"
                                disabled={!isAllowed('create', ``, [], [])}
                                bsSize="small"
                                onClick={() => {
                                    props.dispatch(
                                        actions.push(
                                            `form.feature.variants`,
                                            {...defaultVariant}
                                        )
                                    )
                                }}>
                            Add Variant
                        </Button>
                    </div>
                </div>
            }

            <FormErrors form="feature"/>
        </Form>
    )
})))

export const FeatureFormButtons = connect()((props: any): React.Element<any> => {
    const [deleteFeature, {isSuccess}] = useDeleteFeatureMutation();
    const {feature, environmentKey, onCancel, showDelete, featuresUrl} = props;
    const {projectKey, id: featureId, name} = feature;
    const deleteDisabled = !isAllowed('delete', ``, [], ['production']);
    const deleteFeatureTooltip = deleteDisabled ?
        <Tooltip id={feature.id + 'delete-tooltip'}>Only a production editor can delete a feature</Tooltip>
        : <Tooltip id={feature.id + 'delete-tooltip'}>Delete the feature across all environments</Tooltip>

    const prodUser = isProdUser();

    useEffect(() => {
        if (isSuccess) {
            browserHistory.push(featuresUrl);
            //goToFeaturesRoute(projectKey, environmentKey);
        }
    }, [isSuccess]);

    const doDeleteFeature = () => {
        if (!prodUser) {
            return Alert.error('You do not have permission to delete this feature');
        }
        if (feature && feature.id) {
            confirmModal('Delete Feature', `Are you sure you want to delete '${name}'? This will delete the feature in all environments.`)
                .then(value => {
                    if (value && feature) {
                        dispatch(modalController.closeAll());
                        deleteFeature({projectKey, featureId});
                        Alert.success(`${name} has been deleted`);
                        props.doAfterDelete && props.doAfterDelete();
                    }
                })
        }
    }
    return (
        <>
            <Row>
                <Col xs={6}>
                    {props.update && (showDelete === undefined || true) &&
                        <ButtonToolbar>
                            <OverlayTrigger
                                overlay={deleteFeatureTooltip} rootClose placement="right"
                            >
                                <Button bsStyle="link"
                                        onClick={doDeleteFeature}
                                        disabled={deleteDisabled}>
                                    Delete Feature
                                </Button>
                            </OverlayTrigger>
                        </ButtonToolbar>
                    }
                </Col>
                <Col xs={6}>
                    <ButtonToolbar className="pull-right">
                        {onCancel &&
                            <Button bsStyle="text"
                                    onClick={() => onCancel()}>
                                Cancel
                            </Button>
                        }
                        {(props.showUpdate === undefined || props.showUpdate) &&
                            <Control component={ActionButton}
                                     model="form.feature"
                                     type="submit"
                                     bsStyle="success"
                                     mapProps={{
                                         spinner: props => props.fieldValue.pending,
                                         disabled: props => props.fieldValue.pending || !isAllowed('create', ``, [], [])
                                     }}
                                     onClick={() => props.dispatch(actions.submit('form.feature'))}

                            >
                                {props.update ? 'Save Feature Settings' : 'Create Feature'}
                            </Control>
                        }
                    </ButtonToolbar>
                </Col>

            </Row>
        </>
    );
});

function changeTest(props, model, value): Thunk {
    return (dispatch) => {
        dispatch(actions.change(model, value));
        if (props.keyPristine && !props.update) {
            dispatch(actions.change('form.feature.key',
                avoidUniqueKeys(
                    convertStringToKey(value),
                    props.existingKeys
                )
            ));
            dispatch(actions.setPristine('form.feature.key'));
            dispatch(actions.setValidity('form.feature.key', true));
        }
    };
}

function keyInputMask(model, value): Thunk {
    return (dispatch) => {
        let key = convertStringToKey(value);
        dispatch(actions.change(model, value));
        if (key.length === 0) {
            // Let the name dictate again!
            dispatch(actions.setPristine(model))
        }
    }
}

function avoidUniqueKeys(name: string = '', keys: string[]): string {
    let newName = name;
    while (keys.indexOf(newName) >= 0) {
        let number = parseInt(newName.slice(-1), 10);
        if (isNaN(number)) {
            newName = convertStringToKey(newName + '-1');
        } else {
            newName = newName.replace(number.toString(), (number + 1).toString());
        }
    }
    return newName;
}