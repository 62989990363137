// @flow
import React from 'react';

import ReactSlider from 'react-slider';
import './Split.scss';

import {dispatch} from '../../redux';

import { actions } from 'react-redux-form';

function convertFromSplitsToValues(splits){
  let values = [];
  let runningTotal = 0;
  for (let i = 0; i < splits.length - 1; i++){
    runningTotal += splits[i].value;
    values.push(runningTotal);
  }
  return values;
}

function convertValuesBackToSplits(values, splits){
  values = [].concat(values);
  return splits.map((split, index)=>{
    let currentValue = values[index] === undefined ? 100 : values[index];
    let previousValue = values[index-1] === undefined ? 0 : values[index-1];
    split.value = currentValue - previousValue;
    return split;
  }).reduce((splits, split)=>{
    splits[split.key] = split.value;
    return splits;
  },{});
}


export default class Split extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      ...props.splitValues
    }
  }

  componentWillReceiveProps(newProps){
    this.setState({
      ...newProps.splitValues
    })
  }

  updateSplitValues(value, key){
    const props = this.props;
    value = parseInt(value || 0, 10);
    let originalBucketSize = 100 - this.props.splitValues[key];
    let newBucketSize = 100 - value;

    let variantsToUpdate = props.variants
      .filter(variant=>variant.key !== key);
    if (variantsToUpdate.reduce((prev, next)=>prev+this.props.splitValues[next.key], 0) + value === 0){
      let defaultSplits = Object.keys(this.props.splitValues).map(key=>{
        return {
          key,
          value:  Math.floor(100 / Object.keys(this.props.splitValues).length)
        }
      });

      let defaultToFill = newBucketSize - defaultSplits.reduce((prev, next)=>prev + next.value, 0);

      for (defaultToFill; defaultToFill > 0; defaultToFill--){
        defaultSplits[defaultToFill % defaultSplits.length].value++;
      }

      return dispatch(actions.change(`form.rules.${props.baseRuleModel}.splits`, defaultSplits.reduce((prev, next)=>{
        prev[next.key] = next.value;
        return prev;
      }, {})));
    }

    variantsToUpdate = variantsToUpdate
      .map(variant=>{
        const splitValue = this.props.splitValues[variant.key];
        return {
          key: variant.key,
          value: Math.floor(splitValue / originalBucketSize * newBucketSize)
        }
      });
    let toFill = newBucketSize - variantsToUpdate.reduce((prev, next)=>prev + next.value, 0);
    for (toFill; toFill > 0; toFill--){
      variantsToUpdate[toFill % variantsToUpdate.length].value++;
    }

    let newSplits = {
      ...variantsToUpdate.reduce((prev, next)=>{
        prev[next.key] = next.value;
        return prev;
      }, {}),
      [key]: value
    };

    return dispatch(actions.change(`form.rules.${props.baseRuleModel}.splits`, newSplits))
  }

  render(){
    let props = this.props;
    let splits = props.variants.map(variant=>{
      return {
        key: variant.key,
        value: props.splitValues[variant.key] || 0
      }
    });
    return (
      <div>
        {
          displaySplits(splits, (values)=>{
            dispatch(actions.change(`form.rules.${props.baseRuleModel}.splits`, convertValuesBackToSplits(values, splits)))
          })
        }
        <table className="splitTable">
          <thead>
          <tr>
            <th>Key</th>
            <th>Split</th>
            <th>Color</th>
          </tr>
          </thead>
          <tbody>
          {splits.map((split, index)=>{
            return <tr key={split.key}>
              <td>{split.key}</td>
              <td style={{width: 50}} className="percentInput">
                <input value={this.state[split.key]}
                       onBlur={(event)=>{
                         this.updateSplitValues(event.target.value, split.key)
                       }}
                       onKeyPress={e=>{
                         if (e.key === 'Enter'){
                           this.updateSplitValues(this.state[split.key], split.key);
                         }
                       }}
                       onChange={(event)=>{
                         let value = parseInt(event.target.value, 10);
                         if (value > 100){
                           value = 100;
                         }
                         else if (value < 0){
                           value = 0;
                         }
                         if (!isNaN(value)){
                           this.setState({
                             [split.key]: value
                           });
                         }
                         else{
                           this.setState({
                             [split.key]: ''
                           })
                         }
                      }}/></td>
              <td style={{width: 50}} className={'track-'+index}/>
            </tr>
          })}
          </tbody>
        </table>
      </div>
    )
  }
};


function displaySplits(splits, onChange){
  return (
    <ReactSlider withBars
                 value={convertFromSplitsToValues(splits)}
                 pearling={true}
                 onChange={onChange}/>

  )
}