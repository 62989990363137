// @flow
import * as reduxUtils from '../utils/redux';
import * as environmentApi from '../api/environment';
import type {Environment} from "../api/environment";

export function environmentForKey() {
}


type EnvironmentsState = {
  [key: string]: any,
  environment: Environment,
  environments: EnvironmentList
};
const PREFIX: string = "FF/Environments/";
const RESET: string = PREFIX + "RESET";
const SET_ENVIRONMENTS: string = PREFIX + "SET_ENVIRONMENTS";
const REMOVE_ENVIRONMENT: string = PREFIX + "REMOVE_ENVIRONMENT";

const initialState: EnvironmentsState = {
  environment: {},
  environments: []
};

export const environment = (state) => state.environment; //get the current environment

export default function (state: EnvironmentsState=initialState, action: Action): EnvironmentsState{
  switch(action.type){
    case(SET_ENVIRONMENTS):
       return {
        ...state,
        ...reduxUtils.reduceArrayToMap(action.payload.environments)
      };
    case(REMOVE_ENVIRONMENT):
      return {
        ...state,
        ...reduxUtils.archive(action.payload.environmentId, state)
      }
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setEnvironments(environments: any[]): Action{
  return {
    type: SET_ENVIRONMENTS,
    payload:{
      environments
    }
  }
}

export function removeEnvironment(environmentId: string): Action{
  return {
    type: REMOVE_ENVIRONMENT,
    payload:{
      environmentId
    }
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}


//Thunks
export function doUpdateEnvironment(projectKey: string, environment: any): Thunk{
  return (dispatch)=>{
    return environmentApi.update(projectKey, {
      ...environment
    }).then(response=>{
      dispatch(setEnvironments([response]));
      return Promise.resolve(response);
    }).catch(err=>{
      return Promise.reject(err);
    })
  }
}

export function doRemoveEnvironment(environmentId: string): Thunk{
  return (dispatch)=>{
    return environmentApi.remove(environmentId)
      .then(response=>{
        dispatch(removeEnvironment(environmentId));
        return Promise.resolve(response);
      })
      .catch(err=>{
        return Promise.reject(err);
      })
  }
}