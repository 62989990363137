// @flow
import * as React from 'react';
import {Nav, NavDropdown, MenuItem, NavItem} from 'react-bootstrap';

import {useSelector} from 'react-redux'

import {LinkContainer} from 'react-router-bootstrap';

import {FaLaptop} from 'react-icons/lib/fa';

import './AppLayout.scss';

import type {Project} from "../api/project";
import {
    useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery,
    useGetProjectByKeyQuery,
    useGetProjectsQuery
} from "../api/featureflowApi";


type MenuItemType = {
    header: boolean,
    id: string,
    name: string,
    local: ?boolean,
    to: ?boolean,
    color: string
}

type AppLayoutProps = {
    children: React.Element<any>,
    routes: any,
    params: any,
    projects: Project[],//[{id: string, name: string, key: string, environments: [{id: string, name: string}]}],
    currentProject: Project,
    basePath: string,
    dispatch: ()=>any,
    socketStatus: string,
    showTraffic: boolean,
    showExperiments: boolean,
    showSegments: boolean,
    showTutorial: boolean,
    menuItems: MenuItemType[]
}

export default function (props: AppLayoutProps): ?React.Element<any> {
    const {projectKey, environmentKey} = props.params;
    const {showExperiments, showSegments, showTraffic, showTutorial} = props;
    const {data: currentEnvironment} = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery({
        projectKey,
        environmentKey
    }, {skip: !projectKey || !environmentKey});
    const {data: currentProject} = useGetProjectByKeyQuery(projectKey, {skip: !projectKey});
    const {data: projects} = useGetProjectsQuery();
    const basePath = `/projects/${projectKey}/environments/${environmentKey}`;

    const menuItems =
        !projects ? undefined : projects
            .map((project) => {
                return {
                    key: project.key,
                    name: project.name,
                    environments: project.environments
                }
            }).reduce((menu, project) => {
                menu.push({
                    id: project.key,
                    name: `${project.name} (${project.key})`,
                    header: true
                });
                if (!project.environments) {
                    return menu;
                }
                return menu.concat(project.environments.map(environment => {
                    return {
                        header: false,
                        id: environment.id,
                        name: environment.name,
                        local: environment.local,
                        to: `/projects/${project.key}/environments/${environment.key}`,
                        color: environment.color || '#fff'
                    }
                }));
            }, [])

    if (!menuItems) {
        return null;
    }

    const currentEnvironmentName = currentEnvironment && currentProject && projectKey && environmentKey ?
        (
            <div style={{
                display: 'inline-block',
                marginTop: -30,
                paddingLeft: 30,
                transform: 'translateY(10px)',
                paddingRight: 5,
                position: 'relative'
            }}>
                <div style={{
                    width: 25,
                    height: 25,
                    borderRadius: 5,
                    position: 'absolute',
                    left: -5,
                    top: 10,
                    border: '1px solid #333',
                    background: currentEnvironment.color || '#fff'
                }}></div>
                <small>{currentProject.name}</small>
                <div style={{fontSize: 20}}>
                    {!currentEnvironment ? 'None' : currentEnvironment.local &&
                        <FaLaptop/>} {currentEnvironment.name}
                </div>
            </div>
        ) : 'Select Environment';


    return (
        <Nav>
            <NavDropdown eventKey={1}
                         title={currentEnvironmentName} id="basic-nav-dropdown" className={"scrollable-navmenu"}>
                {menuItems.map((menuItem) => {
                    const item = (
                        <MenuItem key={menuItem.id}
                                  eventKey={menuItem.id}
                                  header={menuItem.header}>
                            {!menuItem.header
                                && <div style={{
                                    verticalAlign: 'middle',
                                    display: 'inline-block',
                                    background: menuItem.color,
                                    width: 12,
                                    height: 12,
                                    marginRight: 4,
                                    borderRadius: 3,
                                    border: '1px solid #333'
                                }}></div>}
                            {menuItem.local && <FaLaptop/>}
                            {menuItem.name}
                        </MenuItem>
                    );

                    if (menuItem.header) {
                        return item;
                    } else {
                        return (
                            <LinkContainer key={menuItem.id} to={menuItem.to}>
                                {item}
                            </LinkContainer>
                        )
                    }
                })}
            </NavDropdown>
            {currentEnvironment && environmentKey &&
                <LinkContainer to={`${basePath}/features`}>
                    <NavItem>Features</NavItem>
                </LinkContainer>
            }
            {currentEnvironment && environmentKey && showTraffic &&
                <LinkContainer to={`${basePath}/users`}>
                    <NavItem>Users</NavItem>
                </LinkContainer>
            }
            {currentEnvironment && environmentKey && showExperiments &&
                <LinkContainer to={`${basePath}/experiments`}>
                    <NavItem>Experiments</NavItem>
                </LinkContainer>
            }
            {currentEnvironment && environmentKey && showSegments &&
                <LinkContainer to={`${basePath}/segments`}>
                    <NavItem>Segments</NavItem>
                </LinkContainer>
            }

            {currentEnvironment && environmentKey && showTutorial &&
                <LinkContainer to={`${basePath}/tutorial`}>
                    <NavItem>Tutorial</NavItem>
                </LinkContainer>
            }

        </Nav>
    )
};