// @flow
import React, {useEffect, useState} from 'react';

import * as modalController from '../redux/modalController';
import RulesForm, {RulesFormButtons, mapApiStructureToForm, mapFormToApiStructure} from '../forms/RulesForm';

import {actions} from 'react-redux-form';

import {dispatch} from '../redux';

import * as featureControls from '../redux/featureControls';

import DismissableAlert from '../components/DismissableAlert'

import type {Feature} from '../api/feature';

import {
    useUpdateFeatureControlMutation,
} from "../api/featureflowApi";
import {confirmModal} from "./ConfirmModal";
import type {Environment} from "../api/environment";
import Alert from "react-s-alert";

export type Props = {
    feature: Feature,
    featureControl: any,
    environment: Environment,
}
const RulesFormBody = (props: Props) => {
    const {environment, feature, featureControl} = props;
    const [updateFeatureControl] = useUpdateFeatureControlMutation();

    const doUpdateFeatureTargeting = (values) => {
        updateFeatureControl({...featureControl, ...mapFormToApiStructure(values, feature.variants)});
        Alert.success(`${feature.name} targeting rules saved.`);
        dispatch(modalController.closeAll());
    }
    const doOnSubmit = (values) => {
        if (!environment.production) {
            doUpdateFeatureTargeting(values);
        }else{
            confirmModal(
                <div>
                    Update feature targeting in a production environment
                </div>,
                <p>
                    Warning, this is a production environment.<br/><br/>
                    Are you sure you wish to update '{feature.name}' in {environment.name}?
                </p>
            ).then(response => {
                if (response) {
                    doUpdateFeatureTargeting(values);
                }else{
                    dispatch(actions.setSubmitFailed('form.rules'));
                }
            });
        }

    }

    return <div>
        <DismissableAlert>
            This is where you configure your feature targeting rules for <b>{feature.name}</b> ({feature.key})
            in <b>{environment.name}</b> environment. <br/>
            Add rules below to manage, test and target your feature.
        </DismissableAlert>
        <RulesForm variants={feature.variants} onSubmit={doOnSubmit}/>
    </div>
}

export function updateRulesModal(featureControl: any, feature: Feature, environment: Environment): Promise<any> {
    return new Promise((resolve, reject) => {
        dispatch(actions.reset('form.rules'));
        dispatch(actions.load('form.rules', mapApiStructureToForm(featureControl.enabled, featureControl.rules, featureControl.offVariantKey)));
        dispatch(modalController.show({
            title: <div>Rules for <b>{feature.name}</b> ({feature.key}) in <b>{environment.name}</b></div>,
            bsSize: 'large',
            body: <RulesFormBody feature={feature} featureControl={featureControl} environment={environment}/>,
            footer: <RulesFormButtons/>,
            closeButton: true,
            static: true,
        }));
    })

}
