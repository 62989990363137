// @flow
import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/eu';
import 'moment/locale/en-au';
import 'moment/locale/en-gb';



import DateTime from 'react-datetime';
import './DateTime.scss';

export default class DatePickerWrapper extends React.Component{
  state = {
    value: undefined,
    timezone: undefined,
    locale: window.navigator.userLanguage || window.navigator.language
  }


    render():React.Element<any>{
    const {
      placeholder,
      disabled,
      onChange,
      ...otherProps
    } = this.props;
    return (
      <div style={{display:'flex'}}>
        <div style={{flex: 1}}>
          <DateTime
            {...otherProps}
            inputProps={{
                          placeholder,
                          disabled,
                        }}
            utc={false}
            disableOnClickOutside={true}
            locale={this.state.locale}
            value={moment(this.props.value)}
            onChange={onChange}

        />
        </div>
        <div style={{padding: 4, marginLeft: 8}}>
          {moment().format("Z")}
        </div>
      </div>
    )
  }
}