// @flow
import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FormGroup, ControlLabel, Label } from 'react-bootstrap';
import {immutable as unique} from 'array-unique';
import { FaClose } from 'react-icons/lib/fa'
import ErrorContainer from './ErrorContainer';


import console from '../../utils/debug';

export default class Typeahead extends React.Component{
  state = {
    options:[],
    isLoading: false
  };

  render():React.Element<any>{
    return (
    <FormGroup style={this.props.style} validationState={this.props.errorMessage ? 'error' : null} bsSize="small">
      {this.props.label && <ControlLabel>{this.props.label}</ControlLabel>}        
        <AsyncTypeahead
          id={"goal"}
          {...this.props}
          options={this.state.options}
          selected={this.props.values}
          minLength={0}
          allowNew={true}
          onChange={values=>{
            let valuesFiltered = values.map(value=>{
              return value.label ? value.label : value;
            });
            this.props.onChange(unique(valuesFiltered));
          }}
          renderToken={(selectedItem, onRemove)=>{
            if (selectedItem.id){
              return undefined;
            }
              return (
                <Label bsStyle="primary"
                       key={selectedItem}
                       onClick={onRemove}
                       style={{cursor: 'pointer', margin: '-2px 8px 2px -4px', lineHeight: '14px', verticalAlign: 'top', display: 'inline-block'}}
                >
                {selectedItem}
                <FaClose/>
              </Label>
              )
          }}
          onSearch={(value=>{
            this.props.optionsEndpoint(value).then(options=>{
              this.setState({ options })
            }).catch(err=>{
              console.warn(err);
              this.setState({options:[]});
            })
          })}
          useCache={false}
        />
      <ErrorContainer>
        { this.props.errorMessage }
      </ErrorContainer>
    </FormGroup>
    )
  }
}