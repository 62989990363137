// @flow
import React, {useEffect} from 'react';

import * as modalController from '../redux/modalController';
import EnvironmentForm, {EnvironmentFormButtons} from '../forms/EnvironmentForm';

import {actions} from 'react-redux-form';
import type {Project} from '../api/project';

import DismissableAlert from '../components/DismissableAlert';


import {dispatch} from '../redux';

import {useUpdateEnvironmentMutation} from '../api/featureflowApi';
import Alert from "react-s-alert";

type Environment = {
    id: string,
    name: string,
    key: string,
    url: string,
    color: string,
    local: boolean,
    production: string,
    cloneEnvironmentId: ?string
}
type Props = {
    environment?: Environment,
    project: Project
}
const EnvironmentFormBody = (props: Props): React.Element<any> => {
    const {environment, project} = props;
    const [updateEnvironment, {isLoading, isSuccess, isError, error}] = useUpdateEnvironmentMutation();

    useEffect(() => {
        if (isSuccess) {
            dispatch(modalController.closeAll());
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const err = error.message;
            dispatch(actions.setSubmitFailed('form.feature'));
            dispatch(actions.setErrors('form.feature', {err}));
        }
    }, [isError]);

    return <>
        {!environment && <DismissableAlert>
            Create a separate environment for each physical environment that your application has. <br/><i>For example,
            "dev", "test", "staging" and "production".</i><br/>
            Features can be configured per each environment, and their configuration can be rolled out separately across
            them so that you can safely manage your features across your development lifecycle.
            <br/>
            <br/>
            A Production Environment can only be modified by Production Users.
        </DismissableAlert>}
        <EnvironmentForm update={!!environment}
                         projectKey={project.key}
                         onSubmit={(values) => {
                             updateEnvironment({...values, projectKey: project.key});
                             Alert.success(`Environment saved.`);
                         }}/>
    </>
}


export function updateEnvironmentModal(project: Project, environment?: Environment): Promise<any> {
    return new Promise((resolve, reject) => {
        //Must reset the form before we show it.

        dispatch(actions.reset('form.environment'));
        dispatch(actions.setInitial('form.environment'));

        if (environment) {
            const {id, name, key, url, local, color, production, cloneEnvironmentId} = environment;
            dispatch(actions.reset('form.environment'));
            dispatch(actions.load('form.environment', {
                id,
                name,
                key,
                url,
                local,
                color,
                production,
                cloneEnvironmentId
            }))
        }

        dispatch(modalController.show({
            title: environment ? `Update ${environment.name} (${project.name})` : `Create Environment (${project.name})`,
            body: <EnvironmentFormBody environment={environment} project={project}/>,
            footer: <EnvironmentFormButtons update={!!environment}/>,
            closeButton: true,
            static: true,
        }));
    })

}