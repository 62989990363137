// @flow
import React, { useEffect } from 'react';


import { confirmModal } from './ConfirmModal';

import * as modalController from '../redux/modalController';
import ExperimentForm, { ExperimentFormButtons } from '../forms/ExperimentForm';

import { actions } from 'react-redux-form';
import * as experiments from '../redux/experiments';

import DismissableAlert from '../components/DismissableAlert';


import { dispatch } from '../redux';
import type {Experiment} from '../api/experiment';
import type {Feature} from '../api/feature';
import type { Environment } from '../api/environment';

import { useDeleteExperimentMutation, useUpdateExperimentMutation } from '../api/featureflowApi';
type Props = {
  experiment?: Experiment,
  environment: Environment,
}

const ExperimentFormBody = (props: Props): React.Element<any> => {

  const { experiment, environment } = props;
  const [updateExperiment, { isLoading, isSuccess, isError, error }] = useUpdateExperimentMutation();
  const [deleteExperiment, { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess, isError: deleteIsError, error: deleteError }] = useDeleteExperimentMutation();

  useEffect(() => {
    if(isSuccess){
        dispatch(modalController.closeAll());
    }
  }, [isSuccess]);

  useEffect(() => {
    if(isError){
        const err = error.message;
        dispatch(actions.setSubmitFailed('form.feature'));
        dispatch(actions.setErrors('form.feature', {err}));
    }
  }, [isError]);
  return <>
      {!experiment && <DismissableAlert>
        Experiments determine which feature variant has the best conversion to a particular goal event.
      </DismissableAlert>}
      <ExperimentForm update={!!experiment}
        environmentKey={environment.key}
        projectKey={environment.projectKey}
        onSubmit={(values)=>{
          updateExperiment({
            ...values,
            environmentId: environment.id,
            environmentKey: environment.key,
            projectKey: environment.projectKey,
          });
        }}/>
    </>
  }

export function updateExperimentModal(environment: Environment, experiment?: Experiment, onDelete?: (experimentId: string) => void): Promise<any>{
  return new Promise((resolve, reject)=>{
    //Must reset the form before we show it.
    dispatch(actions.reset('form.experiment'));
    dispatch(actions.setInitial('form.experiment'));

    if (experiment){
      const { id, name, description, enabled, goalKey, featureId, control, variation} = experiment;
      dispatch(actions.load('form.experiment', {id, name, description, enabled, goalKey, featureId, control, variation}))
    }

    dispatch(modalController.show({
      title: experiment ? `Update ${experiment.name}` : 'Create Experiment',
      bsSize: 'large',
      body: <ExperimentFormBody environment={environment} experiment={experiment} />,
      footer: <ExperimentFormButtons update={!!experiment}
            onDelete={()=>{
              if (experiment){
                confirmModal('Delete Experiment', `Are you sure you want to delete '${experiment.name}'?`).then(value=>{
                  if (value && experiment && experiment.id){
                      onDelete(experiment.id)
                      dispatch(modalController.close());
                  }
                })
              }
          }}
      />,
      closeButton: true,
      static: true,
    }));
  })

}