// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { createSelector } from '@reduxjs/toolkit'

import { convertNameToKey } from '../utils/formParsers';

import ControlWrapper from './controls/ControlWrapper';
import FormErrors from './controls/FormErrors';
import ActionButton from './controls/ActionButton';
import { Control, actions } from 'react-redux-form';

const selectProjectKeys = state => Object.keys(state.projects);

const getExistingKeys = createSelector(
  selectProjectKeys,
  keys => keys
);

const selectProjectForm = state => state.form.forms.project;
const getKeyPristine = createSelector(
  selectProjectForm,
  form=> form.key.pristine
);

function mapStateToProps(state){
  return {
    existingKeys: getExistingKeys(state),
    keyPristine: getKeyPristine(state)
  }
}
export default connect(mapStateToProps)(function(props: any):React.Element<any>{
  return (
    <Form model="form.project" onSubmit={props.onSubmit}>
      <ControlWrapper model=".name"
                     type="text"
                     label="Name"
                     placeholder="Name"
                     errorMessages={{
                       isRequired: 'Name is required'
                     }}
                     changeAction={props.update ? undefined : changeTest.bind(this, props)}
      />
        {!props.update &&
            <span>
                <ControlWrapper model=".key"
                                type="text"
                                label="Key"
                                placeholder="Key"
                                errorMessages={{
                                    isRequired: 'Key is required'
                                }}
                                parser={convertNameToKey}
                                changeAction={keyInputMask}
                                validateOn={['change']}
                />
                < FormErrors form="project"/>
            </span>
        }
        {props.update &&
            <span>
                <ControlWrapper model=".key"
                                type="text"
                                label="Key"
                                placeholder="Key"
                                errorMessages={{
                                    isRequired: 'Key is required'
                                }}
                                disabled={props.update}
                                validateOn={['change']}
                />
                < FormErrors form="project"/>
            </span>
        }
    </Form>
  )
})

export const ProjectFormButtons = connect()((props: any): React.Element<any> => {
  return (
    <Control component={ActionButton}
             model="form.project"
             type="submit"
             mapProps={{
                 disabled: props=>props.fieldValue.pending || !props.fieldValue.valid,
                 spinner: props=>props.fieldValue.pending
               }}
             bsStyle="success"
             onClick={()=>props.dispatch(actions.submit('form.project'))}
    >
      { props.update ? 'Update Project' : 'Create Project' }
    </Control>
  );
});

function changeTest(props, model, value): Thunk {
  return (dispatch) => {
    dispatch(actions.change(model, value));
    if (props.keyPristine){
      dispatch(actions.change('form.project.key',
        avoidUniqueKeys(
          convertNameToKey(value),
          props.existingKeys
        )
      ));
      dispatch(actions.setPristine('form.project.key'));
    }
  };
}

function keyInputMask(model, value): Thunk {
  return (dispatch) => {
    let key = convertNameToKey(value);
    dispatch(actions.change(model,value));
    if (key.length === 0){
      // Let the name dictate again!
      dispatch(actions.setPristine('form.project.key'))
    }
  }
}

function avoidUniqueKeys(name: string = '', keys: string[]): string{
  let newName = name;
  while (keys.indexOf(newName) >= 0){
    let number = parseInt(newName.slice(-1), 10);
    if (isNaN(number)){
      newName = convertNameToKey(newName+'1');
    }
    else{
      newName = newName.replace(number.toString(), (number + 1).toString());
    }
  }
  return newName;
}