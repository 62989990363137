// @flow
import React, { useEffect, useState, useCallback } from 'react';
import { convertStringToKey } from '../utils/formParsers';

import store from 'store';
import StepHeader from '../components/Tutorial/StepHeader';
import TestFeature from '../components/Tutorial/TestFeature';
import CreateFeature from '../components/Tutorial/CreateFeature';
import AddCode from '../components/Tutorial/AddCode';
import Finished from "../components/Tutorial/Finished";

import {
    useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery,
    useGetFeatureByProjectKeyAndFeatureKeyQuery,
    useGetFeaturesByProjectKeyQuery,
    useGetProjectByKeyQuery,
    useCreateFeatureMutation
} from "../api/featureflowApi";

const Tutorial = ({ params }: { params: { projectKey: string, environmentKey: string } }) => {
    /*const featureflow = useFeatureflow();
    const limits = useSelector((state) => state.subscriptionOutline.limits);*/
    const { projectKey, environmentKey } = params;
    const localStorageKey = `ff:tutorial:${projectKey}:${environmentKey}`;

    const [state, setState] = useState({
        currentStep: 1,
        featureName: undefined,
        featureKey: undefined,
        feature: undefined,
        featureAvailable: false,
        language: 'Java',
        ...store.get(localStorageKey),
        featureFormValues: {},
        submitting: false,
    });

    // Queries
    const { data: project, isLoading: projectIsLoading } = useGetProjectByKeyQuery(projectKey);
    const { data: environment, isLoading: environmentIsLoading } = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery(
        { projectKey, environmentKey },
        { skip: !projectKey || !environmentKey }
    );
    const { data: features, isLoading: featuresIsLoading, refetch: refetchFeatures } = useGetFeaturesByProjectKeyQuery({ projectKey });
    const { data: feature, isLoading: featureIsLoading } = useGetFeatureByProjectKeyAndFeatureKeyQuery(
        { projectKey, featureKey: state.featureKey },
        { skip: !state.featureKey || state.featureAvailable, pollingInterval: 3000 }
    );

    // Mutations
    const [createFeature, { isLoading: createIsLoading, isSuccess: createIsSuccess }] = useCreateFeatureMutation();

    const saveProgress = useCallback(() => {
        store.set(localStorageKey, {
            currentStep: state.currentStep,
            featureKey: state.featureKey,
            featureName: state.featureName,
            language: state.language
        });
    }, [state.currentStep, state.featureKey, state.featureName, state.language, localStorageKey]);

    useEffect(() => {

        if (environment && feature && feature.controls[environment.key]) {
            const featureAvailable = feature.controls[environment.key].available || false;

            setState((prevState) => ({
                ...prevState,
                featureAvailable,
                feature,
                currentStep: featureAvailable ? 3 : 2,
                featureKey: feature.key
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                currentStep: 1,
                featureKey: undefined
            }));
        }
    }, [feature, environment]);

    useEffect(() => {
            if (createIsSuccess) {
            setState((prevState) => ({
                ...prevState,
                submitting: false
            }));
        }
    }, [createIsSuccess, feature]);


    useEffect(() => {
        saveProgress();
    }, [state.currentStep, state.feature, state.language, saveProgress]);

    const resetProgress = () => {
        setState({
            ...state,
            currentStep: 1,
            environment: undefined,
            feature: undefined,
            featureKey: undefined,
            featureName: undefined,
            language: 'Java',
            featureFormValues: {},
            submitting: false,
            featureAvailable: false,
        });
    };


    const doCreateFeature = useCallback((value: any) => {
        setState((prevState) => ({ ...prevState, submitting: true }));
        const payload = {
            name: value.name.trim(),
            key: convertStringToKey(value.name || ''),
            projectKey: environment.projectKey,
            description: '',
            variants: [{ key: 'on', name: 'On' }, { key: 'off', name: 'off' }]
        };

        const existingFeature = features.find(feature => feature.key === payload.key);

        if (existingFeature) {
            setState((prevState) => ({
                ...prevState,
                submitting: false,
                featureKey: payload.key,
                featureName: payload.name,
                feature: existingFeature,
                currentStep: 2
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                submitting: false,
                featureKey: payload.key,
                featureName: payload.name,
                currentStep: 1
            }));
            createFeature(payload);
        }
    }, [environment, features, createFeature]);


    if (projectIsLoading || environmentIsLoading || featuresIsLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>Tutorial Step {state.currentStep}</h2>
            <p>
                This tutorial will take you through the basics of setting up the featureflow SDK. <br />
                We will be using the <b>{project.name}</b> project's <b>{environment.name}</b> environment.
                {state.currentStep}
            </p>
            <StepHeader step={1} currentStep={state.currentStep} text={'Create a feature'}/>
            {state.currentStep > 0 && <CreateFeature
                submitting={createIsLoading || featureIsLoading}
                feature={state.feature}
                featureKey={state.featureKey}
                featureFormValues={state.featureFormValues}
                onCreateFeature={doCreateFeature}
                setFormValues={(values) => setState({ ...state, featureFormValues: values })}/>
            }
            <StepHeader step={2} currentStep={state.currentStep} text={'Evaluate your feature'}/>
            {state.currentStep > 1 && <AddCode
                language={state.language}
                apiKey={environment.apiKey}
                featureKey={state.featureKey}
                onSetLanguage={(key) => setState({...state, language: key})}
                onReset={resetProgress}/>
            }
            {state.currentStep > 1 && <TestFeature environment={environment} featureKey={state.featureKey} language={state.language} feature={feature} featureAvailable={state.featureAvailable} />}
            <StepHeader step={3} currentStep={state.currentStep} text={'Manage your feature'}/>
            {state.currentStep > 2 && <Finished />}
        </div>
    );
};

export default Tutorial;
