// @flow
import * as modalController from '../redux/modalController';
import { dispatch } from '../redux';

export function modal(title?: string, body: any, closeButton: boolean = true, _static: boolean = false, _config: any = {}): Promise<any>{
  return new Promise((resolve, reject)=>{
    dispatch(modalController.show({
      title,
      body,
      closeButton,
      static: _static,
      keyboard: closeButton,
      ..._config
    }));
  })

}

export function largeModal(title?: string, body: any, closeButton: boolean = true, _static: boolean = false, _config: any = {}): Promise<any>{
  return new Promise((resolve, reject)=>{
    dispatch(modalController.show({
      title,
      body,
      closeButton,
      static: _static,
      keyboard: closeButton,
      bsSize: 'large',
      ..._config
    }));
  })

}