// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { createSelector } from '@reduxjs/toolkit'


import ControlWrapper from './controls/ControlWrapper';
import { Button } from 'react-bootstrap';
import ActionButton from './controls/ActionButton';
import { Control, actions, Errors } from 'react-redux-form';

import ErrorContainer from './controls/ErrorContainer';

const selectFindOrgFormStatus = (state) => state.form.forms.findOrg.$form;

const getFormPending = createSelector(
  selectFindOrgFormStatus,
  form=>form.pending
);

const getFormSubmitted = createSelector(
  selectFindOrgFormStatus,
  form=>form.submitted
)

function mapStateToProps(state){
  return {
    pending: getFormPending(state),
    submitted: getFormSubmitted(state)
  }
}
export default connect(mapStateToProps)(function(props: any):React.Element<any>{
  return (
    <Form model="form.findOrg" onSubmit={props.onSubmit}>
      <p>
        Enter your email below and we'll send you a list of your Organisations and their URLs.
      </p>
      <ControlWrapper model=".email"
                     type="text"
                     disabled={props.submitted}
                     label="Email"
                     placeholder="Email"
                     errorMessages={{
                       isEmail: 'Must be a valid email address'
                     }}
      />
      <ErrorContainer>
        <Errors model="form.findOrg"/>
      </ErrorContainer>
      {props.submitted && <p>An email has been sent with your organisation details.</p>}
    </Form>
  )
})

export const FindOrgFormButtons = connect(mapStateToProps)((props: any): React.Element<any> => {
  if (props.submitted){
    return (
      <Button onClick={props.onClose.bind(this)}>
        Close
      </Button>
    );
  }
  else{
    return (
      <Control component={ActionButton}
               model="form.findOrg"
               type="submit"
               mapProps={{
                 disabled: props=>props.fieldValue.pending,
                 spinner: props=>props.fieldValue.pending
               }}
               bsStyle="success"
               onClick={()=>props.dispatch(actions.submit('form.findOrg'))}
      >
        Send
      </Control>
    );
  }
});