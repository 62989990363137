// @flow
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit'

import { convertStringToKey } from '../utils/formParsers';

import access from 'safe-access';

import ControlWrapper from './controls/ControlWrapper';
import Typeahead from './controls/Typeahead';
import FormErrors from './controls/FormErrors';
import ActionButton from './controls/ActionButton';
import { Form, Control, actions } from 'react-redux-form';

import * as projectApi from '../api/project';
import type { Feature } from '../api/feature';

import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import {
  useGetFeaturesByProjectKeyQuery,
  useGetFeatureByProjectKeyAndFeatureKeyQuery,
  useGetProjectByKeyQuery
} from "../api/featureflowApi";

const getExperimentGoalKeyForm = createSelector(
  state => state.form.forms.experiment.goalKey, //why oh why are we not just passing in the object to the component?
  form => form
);

const getExperimentGoalKeyValue = createSelector(
  state => state.form.experiment.goalKey,
  value => value
);

function mapStateToProps(state, props){
  return {    
    goalKeyForm: getExperimentGoalKeyForm(state),
    goalKeyValue: getExperimentGoalKeyValue(state),
  }
}

export type Props = {
  projectKey: string,
  environmentKey: string,
  onSubmit: () => void,
  goalKeyValue: string, 
  goalKeyForm: any, 
  update: boolean,
  params: any,
}
const ExperimentForm = (props: Props) : React.Element<any> => {
  const dispatch = useDispatch();
  const featureId = useSelector(state => state.form.experiment.featureId);
  const [feature, setFeature] = useState();
  const { onSubmit, goalKeyValue, goalKeyForm, update, projectKey, environmentKey } = props;
  const { data: features, error, isLoading: featuresIsLoading } = useGetFeaturesByProjectKeyQuery({projectKey});
  
  // const { data: feature, error: featureError, isLoading: featureIsLoading } 
  // = useGetFeatureByProjectKeyAndFeatureKeyQuery(
  //   {
  //       projectKey, 
  //       featureKey: featureKey
  //   }, 
  //   {
  //       skip: !featureKey
  //   });

  useEffect(() => {
    if(features && featureId){
      setFeature(features.filter(feature=>feature.id === featureId)[0]);
    }  
}, [featureId, features]);

  if(update && !feature){
    return <div></div>
  }

  function featureNameAndKey(feature: Feature) {
    return feature.name + " (" + feature.key + ")";
  }

  return (
    <Form model="form.experiment" onSubmit={onSubmit}>
      <ControlWrapper model=".name"
                     type="text"
                     label="Name"
                     placeholder="Name"
                     errorMessages={{
                       isRequired: 'Name is required'
                     }}
      />
      <ControlWrapper model=".description"
                     componentClass="textarea"
                     label="Description"
                     placeholder="Description"
      />
        {! update &&
          <span>
              <Typeahead
                  type="text"
                  label="Goal Key"
                  placeholder="Goal Key"
                  errorMessage={goalKeyForm.errors.required}
                  onChange={(values) => {
                      let value = convertStringToKey(values[0] || '');
                      if (value.length === 0) {
                          dispatch(actions.setErrors(`form.experiment.goalKey`, {required: 'Goal Key is required'}))
                      } else {
                          dispatch(actions.setValidity(`form.experiment.goalKey`, true));
                          dispatch(actions.change(`form.experiment.goalKey`, value));
                      }
                  }}
                  validateOn={['blur']}
                  values={[goalKeyValue || '']}
                  optionsEndpoint={projectApi.getGoals.bind(this, projectKey)}
              />

              <ControlWrapper model=".featureId"
                label="Target Feature"
                componentClass="select"
                errorMessages={{
                    isRequired: 'Feature is Required'
                }}
              >
                  <option value="">-- select --</option>
                  {features.map(feature => {
                      return <option key={feature.id} value={feature.id}>{feature.name} "{feature.key}"</option>
                  })}
              </ControlWrapper>
              {!!feature &&
                  <span>
                      <ControlWrapper model=".control"
                                      label="Control Variant"
                                      componentClass="select"
                                      errorMessages={{
                                          isRequired: 'Variant is Required'
                                      }}
                      >
                          <option value="">-- select --</option>
                          {feature.variants.map(variant => {
                              return <option key={variant.key} value={variant.key}>{variant.name} "{variant.key}"</option>
                          })}
                      </ControlWrapper>
                      <ControlWrapper model=".variation"
                                      label="Alternate Variant"
                                      componentClass="select"
                                      errorMessages={{
                                          isRequired: 'Variant is Required'
                                      }}
                      >
                          <option value="">-- select --</option>
                          {feature.variants.map(variant => {
                              return <option key={variant.key} value={variant.key}>{variant.name} "{variant.key}"</option>
                          })}
                      </ControlWrapper>
                  </span>
              }
          </span>
        }

        {update &&
            <span>
                <ControlWrapper model=".goalKey"
                                type="text"
                                label="Goal Key"
                                placeholder="Goal Key"
                                disabled={true}
                />

                <FormGroup>
                    <ControlLabel>Target Feature</ControlLabel>
                    <FormControl
                        type="text"
                        value={featureNameAndKey(feature)}
                        disabled={true}
                    />
                </FormGroup>
                <ControlWrapper model=".control"
                                type="text"
                                label="Control Variant"
                                disabled={true}
                />
                <ControlWrapper model=".variation"
                                type="text"
                                label="Alternate Variant"
                                disabled={true}
                />
            </span>
        }

      <FormErrors form="experiment"/>
    </Form>
  )
};

export const ExperimentFormButtons = (props: any): React.Element<any> => {
  
  const dispatch = useDispatch();

  const { update, onDelete } = props;

  return (
    <div>
      { update &&
      <Button bsStyle="danger"
              onClick={onDelete.bind(this)}>
        Delete Experiment
      </Button>
      }
      <Control component={ActionButton}
               model="form.experiment"
               type="submit"
               mapProps={{
                 disabled: props=>props.fieldValue.pending || !props.fieldValue.valid,
                 spinner: props=>props.fieldValue.pending
               }}
               bsStyle="success"
               onClick={()=>dispatch(actions.submit('form.experiment'))}
      >
        { update ? 'Update Experiment' : 'Create Experiment' }
      </Control>
    </div>

  );
};

export default connect(mapStateToProps)(ExperimentForm);
