// @flow

import * as NodeJS from './NodeJS';
import * as Java from './Java';
import * as Go from './Go';

export default {
  NodeJS,
  Java,
  Go
}