// @flow
import React from 'react';

import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { connect } from 'react-redux';
import { withFeatureflow } from 'react-featureflow-client';

function mapStateToProps(state, props){
  return {
    principalUser: state.principalUserOutline
  }
}

export default withFeatureflow({update: true})(connect(mapStateToProps)(function(props: any): React.Element<any>{
  const { principalUser } = props;
  return (
    <div>
      <h2>Profile</h2>
      <ListGroup>
        <ListGroupItem>Name: {principalUser.fullName}</ListGroupItem>
        <ListGroupItem>Email: {principalUser.email}</ListGroupItem>
      </ListGroup>
    </div>
  )
}))