// @flow

export function isEmail(val: string): boolean{
  return /^.+@.+\..+$/.test(val) || !val;
}

export function isRequired(val: ?string): boolean{
  if (val){
    return val.length > 0
  }
  return false;
}

export function isRequiredNumber(val: ?string): boolean{
  return !!val || val === 0;
}

export function isRequiredArray<T>(val: T[]): boolean{
  return val && val.length > 0;
}

export function passwordMinLength(val: ? string): boolean{
  if (val) {
    return val.length >= 4;
  }
  return false;
}