const StepHeader = ({ step, text, currentStep }) => {
    let color = '#333';
    if (currentStep < step) {
        color = '#ccc';
    } else if (currentStep > step) {
        color = '#52cd1e';
    }

    return (
        <h3>
            <span style={{
                borderRadius: 26,
                height: 26,
                width: 26,
                border: `2px solid ${color}`,
                display: 'inline-block',
                textAlign: 'center',
                marginRight: 5,
                fontSize: 20,
                lineHeight: '22px',
                color
            }}>
                {step}
            </span>
            {text}
        </h3>
    );
};

export default StepHeader;
