// @flow
import React from 'react';

import * as modalController from '../redux/modalController';
import UserInvitesForm, { UserInvitesFormButtons } from '../forms/UserInvitesForm';

import Alert from 'react-s-alert';

import * as userInvitesApi from '../api/userInvites'

import { actions } from 'react-redux-form';

import { dispatch } from '../redux';

export function userInvitesFormModal(): Promise<any>{
  return new Promise((resolve, reject)=>{

      dispatch(actions.reset('form.userInvites'));
      dispatch(actions.setInitial('form.userInvites'));

      dispatch(modalController.show({
        title: 'Invite Users',
        body: <UserInvitesForm onSubmit={(values)=>{
                            userInvitesApi.inviteUsers(values.invites)
                              .then(response=>{
                               dispatch(modalController.close());
                               Alert.success('Invitations sent.')
                               return response;
                             })
                             .then(resolve)
                             .catch(err=>{
                               dispatch(actions.setSubmitFailed('form.userInvites'));
                               dispatch(actions.setErrors('form.userInvites',{err}));
                               return reject(err);
                             });
                           }}/>,
        footer: <UserInvitesFormButtons/>,
        closeButton: true,
        static: true,
        bsSize: 'large'
      }));
  })

}