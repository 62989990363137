// @flow

type Organisation = {
  id: string,
  name: string,
  lastViewedProject: string
}

const PREFIX: string = "FF/Organisation/";
const RESET: string = PREFIX + "RESET";
const SET_ORGANISATION: string = PREFIX + "SET_ORGANISATION";

const initialState: ?Organisation = null;

export default function (state: ?Organisation=initialState, action: Action): ?Organisation{
  switch(action.type){
    case(SET_ORGANISATION):
      return  action.payload.organisation || null;
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setOrganisation(organisation: Organisation): Action{
  return {
    type: SET_ORGANISATION,
    payload: {
      organisation
    }
  }
}