// @flow
import React from 'react';
//
import Select from 'react-select';

import {convertStringToUpperCaseKey} from '../../utils/formParsers';

import ControlWrapper from './ControlWrapper';

export default function(props: any): React.Element<any> {
    const getOptions = (input, callback) => {
        setTimeout(() => {
            callback(null, {
                options: [],
                complete: true
            });
        }, 500);
    };
    /*const {
        ...otherProps
    } = props;*/
    
    return (
        <ControlWrapper model=".jiraIssueKeys"
                        label={'Jira Issues'}
                        component={Select.AsyncCreatable}
                        multi={true}
                        ignore={["blur"]}
                        autoload={false}
                        loadOptions={getOptions}
                        onInputChange={convertStringToUpperCaseKey}
                        promptTextCreator={input => {
                            return `Add jira issue ${convertStringToUpperCaseKey(input)}`
                        }}
                        newOptionCreator={data => {
                            const value = data.label.indexOf('Add jira issue ') === 0 ? data.label : convertStringToUpperCaseKey(data.label);
                            return {
                                value: value,
                                label: value
                            }
                        }}
                        isOptionUnique={data => {
                            const {option, options, valueKey} = data;
                            return options.map(option => option.value).indexOf(convertStringToUpperCaseKey(option[valueKey])) < 0;
                        }}
                        getValue={(value) => {
                            if (!value) {
                                return [];
                            }
                            return [].concat(value);
                        }}
        />
    )
}
