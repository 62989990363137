// @flow
const initialState = {
  email: '',
  once: '',
  pin: '',
  firstName: '',
  lastName: '',
  organisationName: '',
  organisationDomain: '',
  password: '',
  confirmPassword: '',
  activateIndex: 0
};
export default initialState;