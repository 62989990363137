// @flow
import { createSlice } from '@reduxjs/toolkit'
import { actions } from 'react-redux-form';
import * as projectApi from '../api/project';
import type {Project} from "../api/project";

/**
 * This is the project state slice - which is the reducer plus actions
 * it sits in the entire redux state as '.projects' as a map of projects.
 * The export default projectSlice.reducer is Combined in redux/index.js as 'projects'
 */
type ProjectsState = {
  [key: string]: Project
};

const initialState: ProjectsState = {};

const projectSlice = createSlice({
  name: 'FF/Projects',
  initialState,
  reducers: {
    setProjects(state, action){
      const newEntities = {};
      action.payload.forEach((project) => {
        newEntities[project.key] = project
      })
      state = newEntities;
      return state;
    },
    removeProject(state, action){
      //state.entities[action.payload].archived = true;
      delete state[action.payload];
    },
    addTargetToProject: {
      reducer(state, action) {
        const { projectKey, target } = action.payload;
        state[projectKey].targets.push(target);
      },
      prepare(projectKey: string, target: any){
        return {
          payload: { projectKey, target },
        }
      },
    },
    reset(state, action){
      return initialState;
    }
  }
})

export const { setProjects, removeProject, addTargetToProject, reset } = projectSlice.actions

export default projectSlice.reducer

//Thunks

export function doUpdateProject(project: any): Thunk{
  return (dispatch)=>{

    return projectApi.update(project)
        .then(response=>{
          dispatch(setProjects([response]));
          return Promise.resolve(response);
        }).catch(err=>{
          return Promise.reject(err);
        })
  }
}


export function doRemoveProject(projectKey: string): Thunk{
  return (dispatch)=>{
    return projectApi.remove(projectKey)
        .then(response=>{
          dispatch(removeProject(projectKey));
          return Promise.resolve(response);
        })
        .catch(err=>{
          return Promise.reject(err);
        })
  }
}

const TARGET_FORM = 'form.target';
export function createTarget(projectKey: string, target: Target){
  return dispatch=>{
    dispatch(actions.setPending(TARGET_FORM));
    return projectApi.createTarget(projectKey, target.key, target.name, target.type)
      .then(response=>{
        dispatch(addTargetToProject(projectKey, target));
        dispatch(actions.setPending(TARGET_FORM, false));
        return Promise.resolve(response);
      }).catch(err=>{
        dispatch(actions.setPending(TARGET_FORM, false));
        return Promise.reject(err);
      })
  }
}
