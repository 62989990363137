// @flow
import React from 'react';
import access from 'safe-access';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit'

import ControlWrapper from '../controls/ControlWrapper';

import Select from 'react-select';

import DatePickerWrapper from '../controls/DatePickerWrapper';

import ReactSelectSimple from '../controls/ReactSelectSimple';

import * as projectApi from '../../api/project';
import { getSegments } from '../../api/segment'


const selectProjectKey = (state, props) => props.params.projectKey;
const selectProject = (state, props) => state.projects[selectProjectKey(state, props)];

const getTargets = createSelector(
  selectProject,
  project => project.targets
);

const getRulesForm = createSelector(
  (state, props) => props.baseModel,
  (state)=>state.form.rules,
  (baseModel, rulesForm)=> access(rulesForm, baseModel)
)

const getSelectedTarget = createSelector(
  getRulesForm,
  rulesForm => {
    return access(rulesForm, 'target')
  }
);

const getTarget = createSelector(
  getTargets,
  getSelectedTarget,
  (targets, key) => targets.filter(target=>target.key === key)[0]
);


const getOperator = createSelector(
  getRulesForm,
  condition => access(condition, 'operator')
);

const getValues = createSelector(
  getRulesForm,
  condition => access(condition, 'values')
)

const getRulesFormMeta = createSelector(
  (state, props) => props.baseModel,
  (state)=>state.form.forms.rules,
  (baseModel, rulesFormMeta)=> access(rulesFormMeta, baseModel)
)

const hasErrors = createSelector(
  getRulesFormMeta,
  conditionMeta => !access(conditionMeta,'values.$form.valid')
)

function mapStateToProps(state, props){
  return {
    target: getTarget(state, props),
    operator: getOperator(state, props),
    values: getValues(state, props),
    projectKey: props.params.projectKey,
    hasErrors: hasErrors(state, props)
  }
}

type Target = {
  key: string,
  name: string,
  type: string
}

type ValueSelect = {
  params: any,
  baseModel: string,
  target: Target,
  operator: string,
  values: mixed[],
  projectKey: string,
  hasErrors: boolean,
  dispatch: any
}
export default withRouter(connect(mapStateToProps)(function(props: ValueSelect): React.Element<any>{
  let { target, operator, projectKey } = props;

  let type = 'string';

  if (target){
    type = target.type;
  }

  let commonProps = {
    model: ".values[0]",
    validateOn: ['blur', 'change'],
    disabled: !target || !operator
  }

  const loadSegmentOptions = async (searchQuery, loadedOptions) => {
    const response = await getSegments(projectKey, environmentKey, target, searchQuery);
    const segmentOptions  = response.json()
    return {
      options: segmentOptions
    };
  }
  switch(type){
    case 'date':
      return (
        <ControlWrapper
          {...commonProps}
          errorMessages={{
            isRequired: 'Required'
          }}
          placeholder="Date"
          component={DatePickerWrapper}
          getValue={(value) => {
            return value && value.toISOString ? value.toISOString() : value || ''
          }}
        />
      );
    case 'segment':
      return <ReactSelectSimple model=".values"
                                component={Select.AsyncCreatable}
                                placeholder={'Segments'}
                                label={commonProps.label}
                                style={commonProps.style}
                                disabled={commonProps.disabled}
                                multi={true}
                                errorMessages={{isRequiredArray: 'Segment is required'}}
                                loadOptions={loadSegmentOptions}/>;
    case 'string':
      let multi = operator === 'in' || operator === 'notIn' || operator === 'segment';
      return <ReactSelectSimple model=".values"
                                component={Select.AsyncCreatable}
                                placeholder={multi ? 'Values' : 'Value'}
                                label={commonProps.label}
                                style={commonProps.style}
                                disabled={commonProps.disabled}
                                multi={multi}
                                errorMessages={{isRequiredArray: 'Value is required'}}
                                loadOptions={(input)=>{
                                  return new Promise(resolve=>{
                                    projectApi.getTargetValues(projectKey, access(target,'key'), input).then(options=>{
                                      resolve({options: options.map(option=>{
                                        return {
                                          label: option,
                                          value: option
                                        }
                                      })})
                                    }).catch(err=>{
                                      resolve({options: []})
                                    })
                                  })
                                }}/>;
    case 'number':
      return (
        <ControlWrapper
          {...commonProps}
          placeholder="Number"
          type="number"
          parser={val => parseFloat(val)}
          errorMessages={{
              isRequiredNumber: 'Required'
            }}
        />
      );
    default:
      return (
        <ControlWrapper
          {...commonProps}
          placeholder="Value"
          errorMessages={{
            isRequired: 'Required'
          }}/>
      )
  }
}));
