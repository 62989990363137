// @flow
import React, {useEffect} from 'react';

import * as modalController from '../redux/modalController';
import ProjectForm, {ProjectFormButtons} from '../forms/ProjectForm';

import {actions} from 'react-redux-form';

import DismissableAlert from '../components/DismissableAlert';


import { dispatch } from '../redux';
import { useUpdateProjectMutation } from "../api/featureflowApi";
import Alert from "react-s-alert";
import {goToRoute} from "../utils/routing";

type Project = {
    id: string,
    name: string,
    key: string
}

const ProjectFormBody = (props: Props): React.Element<any> => {
    const { project } = props;
    const [updateProject, {data, isLoading, isSuccess, isError, error}] = useUpdateProjectMutation();

    useEffect(() => {
        if (isSuccess) {
            Alert.success(`Project saved.`);
            goToRoute(`/admin/projects/${data.key}`);
            dispatch(modalController.closeAll());
        }
    }, [isSuccess]);

    useEffect(() => {
        function handleError(error) {
            let errorMessage = '';
            if (error.status == 'FETCH_ERROR'){
                errorMessage = "We had connectivity issues, please try again."
            }
            else if(error.message){
                errorMessage = error.message;
            }
            Alert.error(errorMessage);
        }

        if (isError) {
            handleError(error)
            const err = error.message;
            dispatch(actions.setSubmitFailed('form.project'));
            dispatch(actions.setErrors('form.project', {err}));
        }
    }, [isError]);

    return <>
        {!project && <DismissableAlert>
            A project will typically represent a single manageable application. <br/>
            You may have multiple projects within your organisation and your project may then have multiple
            environments,
            features and controls from which you can manage all aspects of your application.
        </DismissableAlert>}
        <ProjectForm update={!!project}
                     onSubmit={(values) => {
                         updateProject(values);
                     }}/>
    </>
}


export function updateProjectModal(project?: Project): Promise<any> {
    return new Promise((resolve, reject) => {
        //Must reset the form before we show it.
        dispatch(actions.reset('form.project'));
        dispatch(actions.setInitial('form.project'));

        if (project) {
            const {id, name, key} = project;
            dispatch(actions.load('form.project', {id, name, key}))
        }

        dispatch(modalController.show({
            title: project ? `Update ${project.name}` : 'Create Project',
            body: <ProjectFormBody project={project}/>,
            footer: <ProjectFormButtons update={!!project}/>,
            closeButton: true,
            static: true,
        }));
    })

}