// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {useDispatch} from 'react-redux';
import {Button, FormGroup} from "react-bootstrap";
import ErrorContainer from "../../forms/controls/ErrorContainer";
import Select, {Async} from "react-select";
import * as projectApi from "../../api/project";
import {isAllowed} from "../../utils/authentication";
import * as features from "../../redux/features";
import {goToFeatureRoute} from "../../utils/routing";
import ActionButton from "../../forms/controls/ActionButton";
import {LinkContainer} from "react-router-bootstrap";
import {useGetFeaturesByProjectKeyQuery} from "../../api/featureflowApi";

export default function (props: any): React.Element<any> {
    const {handleSubmit, control, setError, setValue, formState: {errors}} = useForm();
    const [projectKey, setProjectKey] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [featureInputText, setFeatureInputText] = useState('');
    const [featureOptions, setFeatureOptions] = useState([]);
    const dispatch = useDispatch();

    const { location } = props;
    const issueKey = location.query.issueKey || "";

    const { data: featuresData, error, isFetching, isLoading } = useGetFeaturesByProjectKeyQuery({projectKey, featureInputText});


    useEffect(() => {
        if(!isLoading && featuresData){
            setFeatureOptions(featuresData.map(feature => {
                return {
                    label: `${feature.name} (${feature.projectKey}-${feature.key})`,
                    value: feature.id
                }
            }))
        }
    }, [featuresData]);

    const handleProjectChange = (e) => {
        setProjectKey(e.value);
        setValue('feature', '', { shouldValidate: false })
        return (e.value);
    }

    const onSubmit = values => {
        setSubmitting(true);
        dispatch(
            features.doLinkFeature({
                ...values,
                featureId: values.feature.value,
                jiraIssueKey: issueKey
            })
        ).then(response => {
            goToFeatureRoute(response.projectKey, response.key);
        }).then()
            .catch(err => {
                setError("general", "error", err);
                setSubmitting(false);
            });
    };

    const getProjectOptions = (input) => {
        return new Promise(resolve => {
            projectApi.find(input).then(results => {
                resolve({
                    options: results.map(project => {
                        return {
                            label: project.name,
                            value: project.key
                        }
                    })
                })
            });
        })
    }
    const noOptionsMessage = useMemo(() => (isFetching ? () => 'Loading…' : undefined), [isFetching]);
    const filterOption = useCallback(() => true, []);

    return (

        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <legend>Link feature</legend>
                <FormGroup controlId="project" validationState={errors.project ? 'error' : null} bsSize="small">
                    <label className="col-lg-2 control-label">Project</label>
                    <div className="col-lg-8">
                        <Controller
                            name="project"
                            control={control}
                            defaultValue={false}
                            onChange={handleProjectChange}
                            render={({ field}) => (
                                <Async
                                {...field}
                                loadOptions={getProjectOptions}
                                onChange={(e) => {
                                    field.onChange(e);
                                    handleProjectChange(e);
                                }}
                                />)
                            }
                        />
                        <ErrorContainer>
                            {errors.project && <p>Please select a project</p>}
                        </ErrorContainer>

                    </div>
                </FormGroup>
                <FormGroup controlId="feature" validationState={errors.feature ? 'error' : null} bsSize="small">
                    <label className="col-lg-2 control-label">Feature</label>
                    <div className="col-lg-8">
                        <Controller
                            name="feature"
                            value=""
                            control={control}
                            defaultValue={false}
                            rules={{required: true}}
                            render={({field}) => <Select
                                inputValue={featureInputText}
                                onInputChange={setFeatureInputText}
                                isLoading={isFetching}
                                options={!isFetching && featureOptions || []}
                                noOptionsMessage={noOptionsMessage}
                                filterOption={filterOption}
                                {...field}/>}
                        />
                        <ErrorContainer>
                            {errors.feature && <p>Please select a feature</p>}
                        </ErrorContainer>
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className="col-lg-8 col-lg-offset-2">
                        <ErrorContainer>
                            {errors.general && errors.general.message}
                        </ErrorContainer>
                        <ActionButton
                            spinner={submitting}
                            disabled={!isAllowed('create', `/project/${props.projectKey}/features/`, [], [])}
                            type="submit" className="btn btn-success pull-right" style={{marginLeft: 3}}> Link
                            Feature</ActionButton>

                        <LinkContainer to="/">
                            <Button type="reset" className="btn btn-default pull-right">Cancel</Button>
                        </LinkContainer>
                    </div>
                </FormGroup>
            </fieldset>
        </form>

    )
}