// @flow
import api from './';

/*type MemberData = {
  id: ?string,
  name: string,
  avatar: string,
  email: string
}*/

export function list(){
    return api('get', `/api/v1/members`)
}

export function find(query: string){
    if(query !== null && query.length > 0){
        return api('get', `/api/v1/members?query=${query}`)
    }else{
        return api('get', `/api/v1/members`)
    }

}
