// @flow
import React, { useEffect, useState } from 'react';

import console from '../utils/debug';

import access from 'safe-access';

import {useFeatureflow} from 'react-featureflow-client';

import {goToRoute} from '../utils/routing';

import { LoadingOverlay } from '../components/LoadingOverlay';


import ToggleButton from 'react-toggle-button';

import {FaBarChart, FaEdit} from 'react-icons/lib/fa';

import {updateExperimentModal} from '../modals/ExperimentFormModal';

import {Button, ButtonGroup, Label, ListGroup, ListGroupItem} from 'react-bootstrap';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {isReadOnlyUser} from "../utils/authentication";
import { useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery, useGetExperimentsQuery, useUpdateExperimentMutation, useDeleteExperimentMutation } from '../api/featureflowApi';

const Experiments = (props: any): React.Element<any> => {
    const featureflow = useFeatureflow();
    const { projectKey, environmentKey } = props.params;
    const { data: experiments, error, isLoading: experimentsIsLoading, isSuccess: experimentsIsSuccess } = useGetExperimentsQuery();
    const { data: environment, error: environmentError, isLoading: environmentIsLoading } = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery({projectKey, environmentKey});
    const [updateExperiment, { isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateExperimentMutation();
    const [deleteExperiment, { isLoading: deleteIsLoading, isSuccess: deleteIsSuccess, isError: deleteIsError, error: deleteError }] = useDeleteExperimentMutation();
    
    const [filteredExperiments, setFilteredExperiments] = useState(undefined);
    
    useEffect(() => {
        if(experiments && environment){
            setFilteredExperiments(
                experiments
                .filter(e => e.environmentId === environment.id)
                .filter(e => !e.archived)
            )
        }        
    }, [experiments, environment]);

    const doOnDelete = (experimentId) => {
        deleteExperiment(experimentId);
    }

    if (featureflow.evaluate('experiments').isOff()) {
        return <div>Access denied.</div>
    } else {
        featureflow.goal('experiments-page');
        return (<>        
                <LoadingOverlay visible={!filteredExperiments || !environment} />
                {filteredExperiments && environment && <div>
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <h2 style={{flex: 1}}>
                            Experiments
                        </h2>
                    </div>
                    <ListGroup>

                        {filteredExperiments.length === 0 &&
                        <ListGroupItem>There are no experiments for {environment.name} yet...</ListGroupItem>}
                        {filteredExperiments.map(experiment => {
                            return (
                                <ListGroupItem key={experiment.id} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                }}>
                                    <div>
                                        <Button bsStyle="link"
                                                style={{padding: '5px 0', marginRight: 16}}
                                                onClick={() => {
                                                    updateExperimentModal(environment, experiment, doOnDelete).catch(console.warn);
                                                }}
                                        >
                                            {experiment.name} <FaEdit/>
                                        </Button>
                                    </div>
                                    <div style={{flex: 1, color: experiment.description ? '#777' : '#f77'}}>
                                        {experiment.description || ''}
                                    </div>
                                    <div style={{padding: '0 8px'}}>
                                        <Label >Goal: {experiment.goalKey}</Label>
                                        &nbsp;
                                        {/* <Label bsStyle="default">{experiment.feature.key}</Label> */}
                                        &nbsp;
                                        {!! experiment.results.validResult && experiment.results.validResult === true &&
                                            <Label bsStyle="success">Winner: {experiment.results.winningVariation}</Label>
                                        }

                                    </div>
                                    <div style={{padding: '0 8px'}}>

                                        <ToggleButton value={experiment.enabled}
                                            onToggle={(value) => {
                                            updateExperiment({
                                                ...experiment,
                                                enabled: !value
                                            })                                                    
                                        }}/>
                                    </div>
                                    <ButtonGroup>
                                        <Button bsSize="xs" bsStyle="primary" disabled={!experiment.startDate}
                                                onClick={() => goToRoute(`experiments/${experiment.id}/stats`)}>Stats <FaBarChart/></Button>
                                    </ButtonGroup>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button bsStyle="primary"
                                disabled={isReadOnlyUser()}
                                onClick={() => {
                                    updateExperimentModal(environment).catch(console.warn);
                                }}
                        >
                            New Experiment
                        </Button>
                    </div>
                </div>
            }
            </>
        )
    }
};

export default Experiments