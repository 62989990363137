// @flow
import console from '../utils/debug';
import SockJS from 'sockjs-client'; 
import JWT from 'jwt-client';

import onMessage from './onMessage';

import store, { dispatch } from '../redux';

import * as application from '../redux/application';

import { getBaseUrl } from '../utils/api';

// Stomp uses a module.exports syntax which isn't compatible with import
const Stomp = require('stompjs');

let token:?string;
let socket;
let stompClient: any;
let connected: boolean = false;
let multiplier: number = 0;
let connectTimeout;

export default function(){
  store.subscribe(()=>{
    let storedToken = store.getState().authentication.token;
    //Token changed, disconnect
    if (token !== storedToken){
      token = storedToken;
      let socketUrl = getBaseUrl() + '/websocket/events?access_token=' + storedToken;
      disconnect();
      //If the token is valid, reconnect
      if (token){
        connect(socketUrl, subscribe);
      }
    }
  })
}
// My apologies but this library is super shit. It hijacks the onError and onClose callbacks and they dont fire.
// I have to manually check the debug message and fire off something :(
// check here fore more details on this monstrosity - https://github.com/jmesnil/stomp-websocket/issues/78
function noDebug(message: string){reconnectConnection(message);}
function prettyDebug(message: string){
  if (message[message.length-1].charCodeAt(0) === 0){
    message = message.slice(0, -1);
  }
  let lines = message.split('\n');
  let direction = lines.shift();
  let body = '';
  if (lines.length > 0){
    body = lines.pop();
    lines.pop();
  }

  let headers = lines.reduce((obj, next)=>{
    let split = next.split(':');
    obj[split[0]] = split[1];
    return obj;
  }, {});

  headers['content-type'] = headers['content-type'] || '';
  headers['destination'] = headers['destination'] || '';

  if (body && Object.keys(headers).length > 0){
    try{
      const bodyJSON = JSON.parse(body);
      console.debug(`${direction} ${headers['destination']}`, {headers, body:bodyJSON}, bodyJSON.type);
    }
    catch(err){};
  }
  else{
    console.debug(`${direction} ${headers['destination']}`);
  }

  reconnectConnection(message);
}

function reconnectConnection(message: string){
  if (message.indexOf('Whoops! Lost connection to') === 0 && store.getState().application.socketStatus === 'CONNECTED'){
    dispatch(application.setSocketStatus('DISCONNECTED'));
    connected = false;
    connect(socket.url);
  }
}


function connect(socketUrl: string){
  if (!connected){
    if (connectTimeout){
      clearTimeout(connectTimeout);
    }
    connectTimeout = setTimeout(()=>{
      socket = new SockJS(socketUrl);

      stompClient = Stomp.over(socket);
      if (process.env.NODE_ENV === 'production'){
        stompClient.debug = noDebug;
      }
      else{
        stompClient.debug = prettyDebug;
      }
      stompClient.connect(
        {},
        ()=>{
          clearTimeout(connectTimeout);
          connected = true;
          dispatch(application.setSocketStatus('CONNECTED'));
          multiplier = 0;
          subscribe();
        },
        ()=>{
          multiplier += 1;
          connect(socketUrl, subscribe)
        });
    }, multiplier * 1000);
  }
}

function subscribe(){
  stompClient.subscribe('/user/queue/event', onMessage);
  if (typeof token === "undefined"){
      return;
  }
  let session = JWT.read(token);
  if (session.claim && session.claim.sub){
    stompClient.subscribe('/topic/org.'+session.claim.sub.split(':')[0]+'.event', onMessage);
  }
}

export function disconnect(){
  console.debug('DISCONNECTING');
  if (stompClient){
    try{
      stompClient.disconnect();
    }
    catch(err){

    }
    stompClient = undefined;
    connected = false;
  }
  if (socket){
    socket.close();
  }
  dispatch(application.setSocketStatus('DISCONNECTED'));
}