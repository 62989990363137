// @flow
import React from 'react';
import { Alert } from 'react-bootstrap';

export default class DismissableAlert extends React.Component{
  state = {
    hide: false
  }
  render():React.Element<any>{
    return (
      this.state.hide ? <div></div> : <Alert {...this.props} style={this.props.style || {backgroundColor: '#efefef', border: '1px solid #ddd', color: '#333'}}
                                                             onDismiss={()=>this.setState({hide: true})}/>
    )
  }
}