// @flow
import React, {useEffect, useRef} from 'react';
import ReduxModal from '../components/ReduxModal';
import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';

import AlertTemplate from '../components/AlertTemplate'

export default function (props: any): React.Element<any> {

    const location = useRef();
    //check for route change and send a page change event to segment
    useEffect(() => {
        // Update the document title using the browser API
        if (props.location.pathname !== location.current) {
            location.current = props.location.pathname;
            analytics.page();
        }
    });


    return (
        <div style={{height: '100%', width: '100%', position: 'relative'}}>
            {props.children}
            <Alert contentTemplate={AlertTemplate}
                   stack={{limit: 3}}
                   position='top-right'
                   timeout={5000}
                   style={{position: 'fixed', zIndex: 9999}}
            />
            <ReduxModal/>
        </div>
    )
};