// @flow
import React from 'react';
import { FaCircleThin, FaCheckCircle, FaExchange, FaBullseye } from 'react-icons/lib/fa';

import {Tooltip, OverlayTrigger} from 'react-bootstrap';

type FeatureStatusIndicatorProps = {
  style: ?KeyValueObject,
  featureKey: string,
  environment: string,
  available: boolean,
  trafficStatus: 'never' | 'active' | 'inactive',
  targetingStatus: 'off' | 'targeted' | 'full',
  staleDays: number,
  [otherPropKeys: string]: any
};

export default function(props: FeatureStatusIndicatorProps): React.Element<any>{

  const {
    style,
    featureKey,
    environment,
    available,
    trafficStatus,
    targetingStatus,
    staleDays,
    ...otherProps
  } = props;
  let availabilityStyle = {
    fontSize: 16,
    lineHeight: '16px',
    color: '#6ac1a8'
  };
  let targetingStyle = {
    fontSize: 16,
    lineHeight: '16px',
    color: '#6ac1a8'
  };
  let trafficStyle = {
    fontSize: 16,
    lineHeight: '16px',
    color: '#6ac1a8'
  };

  let availabilityElement, trafficElement, targetingElement, availabilityText, trafficText, targetingText;

  //available?
  if (available === false){
    availabilityElement = <FaCircleThin/>;
    availabilityText = `This feature has not been registered in the ${environment} environment`;
  } else {
    availabilityElement = <FaCheckCircle/>;
    availabilityText = `This feature has been registered in the ${environment} environment`;
  }

  //targeted status
  if (targetingStatus === 'off') {
    targetingElement = <FaBullseye/>;
    targetingStyle.color = '#cccccc';
    targetingText = `This feature is turned off in the ${environment} environment`;
    //ttText = `This feature has not received traffic for over ${staleDays} days`;
  }
  else if (targetingStatus === 'targeted') {
    targetingElement = <FaBullseye/>;
    targetingStyle.color = '#e08300'
    targetingText = `This feature is subject to targeting rules in the ${environment} environment`;
  } else {
    targetingElement = <FaBullseye/>;
    targetingText = `This feature is targeted 100% to one variant in the ${environment} environment`;
  }
  trafficElement = <FaExchange/>;
  if(trafficStatus === 'never') {
    trafficStyle.color = '#cccccc';
    trafficText = `This feature has never received traffic in the ${environment} environment`;
  } else if(trafficStatus === 'inactive') {
    trafficStyle.color = '#cccccc';
    trafficText = `This feature has not received traffic for over ${staleDays} days in the ${environment} environment`;
  } else{
    trafficText = `This feature has received traffic in the last ${staleDays} days in the ${environment} environment`;
  }
  const availabilityTooltip = <Tooltip id={featureKey+'status'}>{availabilityText}</Tooltip>;
  const targetingTooltip = <Tooltip id={featureKey+'status'}>{targetingText}</Tooltip>;
  const trafficTooltip = <Tooltip placement="bottom" id={featureKey+'status'}>{trafficText}</Tooltip>;

  return (
      <span>
        <OverlayTrigger
            overlay={availabilityTooltip} placement="bottom"
            delayShow={300} delayHide={150}>
            <span style={{...availabilityStyle, ...style}} {...otherProps}>
              {availabilityElement}
            </span>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={targetingTooltip} placement="bottom"
          delayShow={300} delayHide={150}>
            <span style={{...targetingStyle, ...style}} {...otherProps}>
              {targetingElement}
            </span>
        </OverlayTrigger>
        <OverlayTrigger
            overlay={trafficTooltip} placement="bottom"
            delayShow={300} delayHide={150}>
          <span style={{...trafficStyle, ...style}} {...otherProps}>
            {trafficElement}
          </span>
        </OverlayTrigger>
      </span>
  )
}