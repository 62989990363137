// @flow
import React, {useState, useEffect} from 'react';
import * as auditApi from '../../api/audit';
import Timeago from 'react-timeago';
import moment from 'moment';
import {FaSpinner} from 'react-icons/lib/fa'
import ReactMarkdown from 'react-markdown'
import {ListGroup, ListGroupItem, Button, Row, Col, ButtonToolbar} from 'react-bootstrap';

type AuditType = {
    createdAt: string,

    changeType: string,
    description: string,
    changeFrom: any,
    changeTo: any,

    title: string,
    organisationId: string,
    projectKey: string,
    projectId: string,
    environmentId: string,
    resourceId: string
}

function Audit({projectKey, featureKey, resourceId}) {
    const [items: AuditType[], setItems] = useState([]);
    const [loading: boolean, setLoading] = useState(false);
    const [last: boolean, setLast] = useState(false);
    const [page: number, setPage] = useState(0);

/*    useEffect(() => {
        loadMore();
    }, []);*/

    useEffect(() => {
        loadMore();
    }, [page]);

    const nextPage = () => {
        setPage(page => page + 1);
    }
    const loadMore = () => {
        setLoading(loading => true);
        const query = {
            resourceId,
            projectKey,
            featureKey,
            page
        };

        auditApi.query(query)
            .then(response => {
                setItems(prevItems => {
                    return page == 0 ? [...response.content] : [...prevItems, ...response.content];
                });
                setLast(last => response.last);
                setLoading(loading => false);
            })
            .catch(() => {
                setLoading(loading => false);
            });
    }

    let refresh = () => {
        if(page == 0){
            loadMore();
        } else {
            setPage(page => 0);
        }
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <ButtonToolbar className="pull-right">
                        <Button bsStyle="link" disabled={loading} onClick={refresh}>
                            Refresh {loading && <FaSpinner className="fa-spin"/>}
                        </Button>
                    </ButtonToolbar>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <ListGroup>
                        {items.length === 0 && <ListGroupItem>No items</ListGroupItem>}
                        {items.map((item, index) => {
                            return (
                                <ListGroupItem key={index} style={{display: 'flex'}}>
                <span style={{flex: 1}}>
                  <h4><ReactMarkdown source={item.title}/></h4>
                    {
                        item.description &&
                        <p><ReactMarkdown source={item.description}/></p>
                    }
                </span>
                                    <div>
                                        <Timeago date={item.createdDate}/>
                                        <small style={{
                                            display: 'block',
                                            color: '#999'
                                        }}>{moment(item.createdDate).format('MMM Do YYYY, h:mm a')}</small>
                                    </div>
                                </ListGroupItem>
                            )
                        })}

                    </ListGroup>
                    {
                        !last &&
                        <Button bsStyle="link" block disabled={loading} onClick={nextPage}>
                            Load More {loading && <FaSpinner className="fa-spin"/>}
                        </Button>
                    }
                </Col>
            </Row>
        </>
    )
}

export default Audit;
