// @flow
import React from 'react';
import Select from 'react-select';
import ControlWrapper from './ControlWrapper';
import * as memberApi from "../../api/member";

export default function(props: any): React.Element<any> {

    return (
        <ControlWrapper model=".maintainer"
                        label={'Maintainer'}
                        component={Select.Async}
                        ignore={["blur"]}
                        autoload={true}
            /*simpleValue={true}*/

            //value={props.options.filter(option => option.label === props.maintainerId)}
                        loadOptions={(input) => {
                            return new Promise(resolve => {
                                memberApi.find(input).then(results => {
                                    resolve({
                                        options: results.map(member => {
                                            return {
                                                label: member.name,
                                                value: member.id
                                            }
                                        })
                                    })
                                });
                            })
                        }}
                        defaultOptions

        />
    )
}
