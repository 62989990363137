// @flow
import React, {useState} from 'react';
import {useGetProductsQuery} from "../../../api/featureflowApi";
import LoadingOverlay from "../../../components/LoadingOverlay";
import PerSeatProduct from "./PerSeatProduct";
import FreeProduct from "./FreeProduct";
import type { CancelSubscription, SubscribeToPrice } from "../../../api/billing/types";
import {Col, Nav, NavItem, Row} from "react-bootstrap";
import EnterprisePricingPlanNew from "./EnterprisePricingPlanNew";
import {useFeatureflow} from "react-featureflow-client";

export type AvailableProductsProps = {
    currentPriceId: string,
    currentProductId: string,
    subscribeToPrice: SubscribeToPrice,
    cancelSubscription: CancelSubscription,
    paidSeats: number,
    usedSeats:number
}
const AvailableProducts = ({
                               currentPriceId,
                               currentProductId,
                               subscribeToPrice,
                               cancelSubscription,
                               paidSeats,
                               usedSeats
                           }: AvailableProductsProps) => {
    const {data: products, isLoading} = useGetProductsQuery();
    const [interval, setInterval] = useState<string>('month');
    const featureflow = useFeatureflow();
    if (isLoading) {
        return <LoadingOverlay visible={isLoading}/>
    }

    const handleSelect = () => {
        setInterval(interval === 'year' ? 'month' : 'year');
    }
    return (
        <>
            {featureflow.evaluate('yearly-plans').isOn() &&
                <Row>
                    <Col xs={12} className="show-grid" style={{textAlign: 'center', marginBottom: '15px'}}>
                        <div>
                            <Nav bsStyle="pills" activeKey={interval === 'month' ? 1 : 2} onSelect={handleSelect}>
                                <NavItem eventKey={1}>
                                    Monthly
                                </NavItem>
                                <NavItem eventKey={2} title="Yearly">
                                    Yearly
                                </NavItem>
                            </Nav>
                        </div>
                    </Col>
                </Row>
            }
            <Row>
            {
                products && products.map(product => {
                    return (
                        <>
                            {
                                (product.free) &&
                                <FreeProduct
                                    product={product}
                                    currentProductId={currentProductId}
                                    currentPriceId={currentPriceId}
                                    subscribeToPrice={subscribeToPrice}/>

                            }
                            {
                                (!product.free) &&
                                <PerSeatProduct
                                    product={product}
                                    currentPriceId={currentPriceId}
                                    visiblePrice={interval}
                                    subscribeToPrice={subscribeToPrice}
                                    cancelSubscription={cancelSubscription}
                                    paidSeats={ paidSeats }
                                    usedSeats = { usedSeats }
                                />
                            }
                        </>
                    )
                })}
                <EnterprisePricingPlanNew/>
            </Row>

        </>
    )
}

export default AvailableProducts;
