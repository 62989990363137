import React, {useEffect} from "react";

import {Col, Row} from "react-bootstrap";
import FeatureForm, {FeatureFormButtons} from "../../forms/FeatureForm";
import {dispatch} from "../../redux";
import {actions} from "react-redux-form";
import {getInitialVariants} from "../../redux/form/feature";
import {useCreateFeatureMutation, useUpdateFeatureMutation} from "../../api/featureflowApi";
import Alert from "react-s-alert";
import {confirmModal} from "../../modals/ConfirmModal";

export const FeatureSettingsTab = (props: any) => {
    const { feature, featureControl, environmentKey, featuresUrl } = props;
    const [createFeature, { isSuccess: createIsSuccess, isError: createIsError, error: createError }] = useCreateFeatureMutation();
    const [updateFeature, { isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateFeatureMutation();
    useEffect(() => {
        if(createIsSuccess){
            Alert.success(`${feature.name} settings saved.`);
        }
    }, [createIsSuccess]);

    useEffect(() => {
        if(updateIsSuccess){
            Alert.success(`${feature.name} settings saved.`);
        }
    }, [updateIsSuccess]);

    useEffect(() => {
        if(createIsError){
            Alert.warning(`We encountered an error, ${feature.name} was not saved. ${updateError.error || ''}`);
            dispatch(actions.setSubmitFailed('form.feature'));
        }
    }, [createIsError]);

    useEffect(() => {
        if(updateIsError){
            Alert.warning(`We encountered an error, ${feature.name} was not saved. ${updateError.error || ''}`);
            dispatch(actions.setSubmitFailed('form.feature'));
        }
    }, [updateIsError]);

    const resetForm = () => {
        dispatch(actions.reset('form.feature')); // no idea why I have to do both but I do....
        dispatch(actions.setInitial('form.feature'));
        dispatch(actions.reset('form.feature'));
        dispatch(actions.change('form.feature.variants', getInitialVariants()));

        if (feature && feature.id) { //if we are editing
            let {id, name, key, description, variants, permanent, tags, jiraIssues, projectKey, maintainer} = feature;
            permanent = permanent || false;
            tags = (tags || []).map((tag, index) => {
                return {
                    value: tag,
                    label: tag
                }
            });
            if(maintainer){
                maintainer = {
                    value: maintainer.id,
                    label: maintainer.name
                };
            }else{
                maintainer = {
                    value: '',
                    label: ''
                };
            }
            let jiraIssueKeys = (jiraIssues || []).map((jiraIssue, index) => {
                return {
                    value: jiraIssue.key,
                    label: jiraIssue.title
                }
            });
            dispatch(actions.load('form.feature', {
                id,
                name,
                key,
                description,
                variants,
                permanent,
                tags,
                jiraIssueKeys,
                projectKey,
                maintainer
            }))
        }
    }
    useEffect(() => {
        resetForm();
    }, [feature, featureControl])

    const doUpdateFeature = (values) => {
        const featurePayload = {
            ...feature,
            ...values,
            tags: (values.tags || []).map(tag => tag.label),
            jiraIssueKeys: (values.jiraIssueKeys || []).map(jiraIssueKey => jiraIssueKey.label),
            maintainer: (values.maintainer ? { id: values.maintainer.value, name: values.maintainer.label } : {})
        };
        feature.id ?
            updateFeature(featurePayload)
            :
            createFeature(featurePayload);
    }
    const doOnSubmit = (values) => {
        const update = values.id ? 'Update' : 'Create';
        confirmModal(
            <div>
                {update} feature settings
            </div>,
            <div>
                {values.id && <p>Feature settings apply across all environments.</p>}
                <p>Are you sure you wish to {update} '{values.name}'?</p>
            </div>,
            [
                {
                    text: 'Cancel',
                    value: false,
                    style: 'default'
                },
                {
                    text: `${update} feature`,
                    value: true,
                    style: 'primary'
                }
            ]
        ).then(response => {
            if (response) {
                doUpdateFeature(values);
            } else {
                dispatch(actions.setSubmitFailed('form.feature'));
            }
        });
    }



    return <>
        <Row>
            <Col xs={12} lg={8}>
                <h3>Feature Settings</h3>
                <p>Settings apply across all environments</p>
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={8}>
                <FeatureForm
                    feature={feature}
                    featureControl={featureControl}
                    onSubmit={doOnSubmit}
                    update={true}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={8} className="text-right">
                <FeatureFormButtons update={feature && feature.id}
                                    showUpdate={true}
                                    feature={feature}
                                    environmentKey={environmentKey}
                                    onCancel={() => resetForm()}
                                    featuresUrl={featuresUrl}
                />
            </Col>
        </Row>
    </>
}