// @flow
import React from 'react';

import SyntaxHighlighterWithCopy from '../SyntaxHighlighterWithCopy';

export function instructions(apiKey: string, featureKey: string){
const newProject = `mkdir featureflow-go-starter
cd featureflow-go-starter
go mod init featureflow-go-starter
`;

const goGet = `go get github.com/featureflow/featureflow-go-sdk/featureflow`;

const importFeatureflow = `package main

import (
  "github.com/featureflow/featureflow-go-sdk/featureflow"
  "log"
  "time"
)`;

const instantiateClient = `func main(){
  client, _ := featureflow.Client("${apiKey}", featureflow.Config{})
  user, _ := featureflow.NewUserBuilder("jimmy@example.com").
                        WithAttribute("firstName", "Jimmy").
                        WithAttribute("lastName", "Hendrix").
                        Build()
  
  if client.Evaluate("${featureKey}", user).IsOn() {
    log.Println("Showing feature to user")
  } else {
    log.Println("Not showing feature to user")
  }
  
  time.Sleep(time.Second * 2)
}`;
    
    return (
      <ol>
        <li>
          Create a new Go Project
          <SyntaxHighlighterWithCopy language="bash" code={newProject}/>
        </li>
        <li>
          Install the Featureflow SDK from Go Get
          <SyntaxHighlighterWithCopy language="bash" code={goGet}/>
        </li>
        <li>
          Create a file called "main.go" in the root of your project import the featureflow sdk
          <SyntaxHighlighterWithCopy language="go" code={importFeatureflow}/>
        </li>
        <li>
          Instantiate the featureflow client and evaluate a feature against a user
          <SyntaxHighlighterWithCopy language="go" code={instantiateClient}/>
        </li>
      </ol>
    )
}


export function runCommand(){
  return <SyntaxHighlighterWithCopy language="bash" code="go run main.go"/>
}