import React from 'react';
import { FaSpinner } from 'react-icons/lib/fa';
import * as TutorialCode from '../TutorialCode';


const TestFeature = ({ environment, featureKey, language, feature, featureAvailable }) => {
    let runCodeInstructions;
    if (TutorialCode.default[language]) {
        runCodeInstructions = TutorialCode.default[language].runCommand(environment.apiKey, featureKey);
    }

    if (feature) {
        const featureEnabled = feature.controls[environment.key].enabled ? 'enabled' : 'not enabled';
        const rulesDescription =  feature.controls[environment.key].rulesDescription;
        return (
            <div>
                {!featureAvailable &&
                    <p>
                        Waiting to see <b>{feature.name}</b> in your environment, follow the steps above and
                        run the command. <FaSpinner className="fa-spin"/>
                    </p>
                }
                {runCodeInstructions}
                {
                    featureAvailable && (
                        <div>
                            <p><b>{feature.name}</b> has been seen in the {environment.name} environment, currently the feature is {featureEnabled}</p>
                            <p>When the feature is enabled, the targeting rules are '{rulesDescription}'</p>
                        </div>
                    )
                }
            </div>

        );
    } else {
        return (
            <></>
        );
    }
};

export default TestFeature;
