// @flow

export const defaultUserInvite = {
  firstName: '',
  lastName: '',
  email: ''
};

const initialState = {
  invites:[{
    firstName: '',
    lastName: '',
    email: ''
  }]
};

export default initialState;
