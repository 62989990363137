// @flow
import React from 'react';

import { FormControl, InputGroup } from 'react-bootstrap';

import { Control, actions } from 'react-redux-form';

import { FaTimesCircle, FaSearch } from 'react-icons/lib/fa';

import { connect } from 'react-redux';

import access from 'safe-access';

function mapStateToProps(state, props){
  return {
    filterText: access(state, props.model) || ''
  }
}

export default connect(mapStateToProps)(function(props: any): React.Element<any>{
    return (
      <InputGroup bsSize="sm" style={{position: 'relative'}}>
        <FaSearch style={{left: 10, top: 8, zIndex: 10, position: 'absolute', color: '#666'}}/>
        <Control
          model={props.model}
          component={FormControl}
          className="focus-input-filter-width-grow"
          style={{paddingLeft: 30, paddingRight: 20}}
          placeholder={props.placeholder || 'Search'}
        />
        {props.filterText.length > 0 &&
        <FaTimesCircle
          style={{right: 5, top: 8, zIndex: 10, position: 'absolute', color: '#999', cursor: 'pointer'}}
          onClick={()=>{props.dispatch(actions.reset(props.model))}}
        />}
      </InputGroup>
    )
});

