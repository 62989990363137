// @flow
import React from 'react';
import {actions} from 'react-redux-form'
import ControlWrapper from './ControlWrapper';

export default function (props: any): React.Element<any> {
    return (
        <ControlWrapper ignore={["blur"]}
                        {...props}
                        changeAction={(model, values = []) => {
                            return dispatch => {
                                dispatch(actions.change(model, values.map(value => value.value)));
                            }
                        }}
                        getValue={(value) => {
                            if (!value) {
                                return [];
                            }
                            return [].concat(value);
                        }}
                        mapProps={{
                            value: props => {
                                let value = props.modelValue.map(value => {
                                    return {
                                        value: value,
                                        label: value
                                    }
                                })
                                return props.multi ? value : value[0];
                            }
                        }}/>
    )
}