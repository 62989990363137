// @flow
import React from 'react';
import './Activate.scss';

import { actions, Form } from 'react-redux-form';
import ControlWrapper from '../forms/controls/ControlWrapper';
import FormErrors from '../forms/controls/FormErrors';
import { connect } from 'react-redux';

import access from 'safe-access';

import { Link } from 'react-router';

import * as accountApi from '../api/account';

import CircleONotch from 'react-icons/lib/fa/circle-o-notch';

import { Button } from 'react-bootstrap';

import PasswordStrength from '../components/PasswordStrength';

function mapStateToProps(state){
  return {
    resetPassword: state.form.resetPassword,
    form: state.form.forms.resetPassword,
  }
}

function verifyPasswordIsTheSame(props, otherPassword, model, value){
  props.dispatch(actions.change(model, value));
  if (value !== otherPassword && props.form.confirmPassword.touched){
    props.dispatch(actions.setErrors('form.resetPassword.confirmPassword', {
      nomatch:'The two passwords do not match.'
    }));
  }
  else{
    props.dispatch(actions.setValidity('form.resetPassword.confirmPassword', true));
  }
}

export default connect(mapStateToProps)(function(props: any): React.Element<any> {
  return (
    <div id="activate-container">
      <div id="activate">
        <div id="activate-box">
          {props.form.$form.submitted
            ? (
              <div>
                <h2>You have successfully reset your password</h2>
                <p><Link to="/login">Click here</Link> to go back to your login screen</p>
              </div>
            )
            : (
              <div>
                <h1>Reset Password</h1>
                <p>Enter new password</p>
                <Form
                  model="form.resetPassword"
                  onSubmit={(values)=>{
                    props.dispatch(actions.setPending('form.resetPassword', true));
                    let key = access(props, 'location.query.key') || '';
                    let password = values.password;
                    accountApi.resetPassword(key, password)
                      .then(response=>{
                        props.dispatch(actions.setSubmitted('form.resetPassword', true))
                      })
                      .catch(err=>{
                        let message = err ? err.message : undefined;
                        props.dispatch(actions.setPending('form.resetPassword', false));
                        props.dispatch(actions.setErrors('form.resetPassword', message || 'There was an error resetting your password'))
                      })
                  }}>
                  <ControlWrapper
                    model="form.resetPassword.password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    errorMessages={{
                       passwordMinLength: 'Your password should be greater than 4 characters long'
                     }}
                    changeAction={ verifyPasswordIsTheSame.bind(this, props, props.resetPassword.confirmPassword) }
                  />

                  <PasswordStrength password={props.resetPassword.password}/>

                  <ControlWrapper
                    model="form.resetPassword.confirmPassword"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type="password"
                    validateOn={['change']}
                    changeAction={ verifyPasswordIsTheSame.bind(this, props, props.resetPassword.password) }
                  />
                  <FormErrors form="resetPassword"/>
                  <br/>
                  <Button type="submit"
                          bsStyle="primary"
                          disabled={props.resetPassword.password !== props.resetPassword.confirmPassword
                                    || !props.form.password.valid
                                    || !props.form.confirmPassword.valid
                                    || props.form.$form.pending
                          }>
                    Reset Password
                  </Button>
                  {props.form.$form.pending && <CircleONotch style={{marginLeft: 10, fontSize: 20}} className="fa-spin"/>}
                </Form>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
})