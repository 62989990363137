// @flow
import React from 'react';

import * as modalController from '../redux/modalController';
import CreateTargetForm, { CreateTargetFormButtons } from '../forms/CreateTargetForm';

import { actions } from 'react-redux-form';

import * as projects from '../redux/projects';



import { dispatch } from '../redux';

export function createTargetModal(key, project: Project): Promise<any>{
  dispatch(actions.change('form.target', {
    key,
    name: key,
    type: 'string'
  }));
  return new Promise((resolve, reject)=>{
    dispatch(modalController.show({
      title: 'Create Target',
      body: <CreateTargetForm onSubmit={(target)=>{
              return dispatch(projects.createTarget(project.key, target))
                .then(target=>{
                  dispatch(modalController.close());
                  resolve(target);
                }).catch(reject);
            }}/>,
      footer: <CreateTargetFormButtons onClose={()=>{dispatch(modalController.close())}}/>,
      closeButton: true,
      static: true,
    }));
  })

}