import React from 'react';
import * as TutorialCode from '../TutorialCode';
import {Button, DropdownButton, MenuItem} from "react-bootstrap";

const AddCode = ({ language, apiKey, featureKey, onSetLanguage, onReset}) => {
    const addCodeInstructions = TutorialCode.default[language].instructions(apiKey, featureKey);
    return (
        <div>
                <div>
                    Select a Language
                    <DropdownButton bsStyle={'primary'}
                                    id="code-language-select"
                                    style={{margin: '0 10px 5px'}}
                                    bsSize="sm"
                                    onSelect={(key) => {
                                        onSetLanguage(key);
                                    }}
                                    title={language}
                    >
                        {Object.keys(TutorialCode.default).map(key => <MenuItem eventKey={key}
                                                                                key={key}> {key} </MenuItem>)}
                    </DropdownButton>
                    <Button bsSize="xs" onClick={onReset}> Reset tutorial</Button>

                    {addCodeInstructions}
                </div>
        </div>
    );
};

export default AddCode;