// @flow
import React from 'react';
import {Navbar, Nav, NavDropdown, MenuItem, NavItem} from 'react-bootstrap';

import {goToRoute} from '../utils/routing';
import {isAdmin} from '../utils/authentication';

import {connect} from 'react-redux';

import {createSelector} from 'reselect';

import * as LocalEnvironmentAlert from '../modals/LocalEnvironmentAlert';

import {FaQuestionCircle, FaSignOut, FaLaptop} from 'react-icons/lib/fa';

import Logo from '../assets/images/logos/logo-header-white.png';

import {LinkContainer} from 'react-router-bootstrap';

import {withFeatureflow} from 'react-featureflow-client';

import './AppLayout.scss';

import EnvironmentNavDropdown from './EnvironmentNavDropdown';

type SubscriptionType = {
    plan: SubscriptionPlanType,
    inTrial: boolean,
    daysUntilEndOfTrial: number
}


type AppLayoutProps = {
    children: React.Element<any>,
    routes: any,
    params: any,
    featureflow: any,
    environment: any,
    subscription: SubscriptionType
}

const getCurrentEnvironment = createSelector(
    state => state.environments,
    (state, props) => props.params.environmentId,
    (environments, environmentId) => environments[environmentId] || {}
);

function mapStateToProps(state, props) {
    return {
        environment: getCurrentEnvironment(state, props),
        principalUser: state.principalUserOutline,
        subscription: state.subscriptionOutline,
    }
}

class AppLayout extends React.Component {
    props: AppLayoutProps;

    render(): React.Element<any> {
        const props = this.props;
        const showExperiments = props.featureflow.evaluate('experiments').isOn();
        const showSegments = props.featureflow.evaluate('segments').isOn();
        const showTutorial = props.featureflow.evaluate('tutorial-tab').isOn();
        const showTraffic = props.featureflow.evaluate('traffic').isOn();

        if (!props.principalUser) {
            return <div></div>
        }
        //const {plan, inTrial, daysUntilEndOfTrial} = props.subscription;
        return (
            <div style={{paddingTop: 50, paddingBottom: 80, minHeight: '100%', position: 'relative'}}>
                <Navbar fixedTop fluid style={{backgroundColor: 'rgb(77,77,77)', borderColor: 'rgb(66,66,66)'}}>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <img src={Logo} alt="FeatureFlow"/>
                        </Navbar.Brand>                        
                    </Navbar.Header>                    
                    <Navbar.Collapse>
                        {props.featureflow.evaluate('home').isOn() &&
                        <Nav>
                            <LinkContainer to={{pathname: `/home`}}>
                                <NavItem eventKey='home'>Home</NavItem>
                            </LinkContainer>
                        </Nav>
                        }
                        <EnvironmentNavDropdown showTraffic={showTraffic} showExperiments={showExperiments} showSegments={showSegments} params={props.params} showTutorial={showTutorial}/> {/*variable naming 101 fail params={props.params}*/}
                        <Nav>
                            {props.subscription.inTrial &&
                                <LinkContainer to={{pathname: `/admin/billing`}}>
                                    <NavItem>
                                        <NavItem eventKey='admin'>You have {props.subscription.daysUntilEndOfTrial} {props.subscription.daysUntilEndOfTrial === 1 ? 'day' : 'days'} left in your trial</NavItem>
                                    </NavItem>
                                </LinkContainer>
                            }
                            {props.subscription.status === 'inactive' &&
                                <LinkContainer to={{pathname: `/admin/billing`}}>
                                    <NavItem eventKey='admin'>Your trial has expired!</NavItem>
                                </LinkContainer>
                            }

                        </Nav>
                        <Nav pullRight>
                            <NavDropdown eventKey={1} title={props.principalUser.fullName || props.principalUser.email} id="profile-dropdown">
                                {props.featureflow.evaluate('profile').isOn() &&
                                <LinkContainer to={{pathname: `/profile`}}>
                                    <MenuItem eventKey='admin'>Profile</MenuItem>
                                </LinkContainer>
                                }
                                {
                                    isAdmin() && <LinkContainer to={{pathname: `/admin`}}>
                                        <MenuItem eventKey='admin'>Admin</MenuItem>
                                    </LinkContainer>
                                }
                                <MenuItem divider/>
                                <MenuItem eventKey={1.3} onClick={() => goToRoute('/logout')}>
                                    <FaSignOut className="pull-left"/>
                                    Log Out
                                </MenuItem>
                            </NavDropdown>
                            <NavItem href="http://docs.featureflow.io" target="_blank">
                                <FaQuestionCircle/>
                            </NavItem>
                        </Nav>

                    </Navbar.Collapse>                    
                </Navbar>
                {props.environment.local &&
                <div style={{background: '#60c3a7', color: 'white', textAlign: 'center', position: 'absolute', left: 0, right: 0, cursor: 'pointer'}}
                     onClick={LocalEnvironmentAlert.show.bind(this)}>
                    <FaLaptop style={{verticalAlign: 'text-bottom'}}/> LOCAL ENVIRONMENT - <b>Learn More</b>
                </div>}
                {props.environment.production &&
                <div style={{background: 'white', color: 'black', fontWeight: 'bold', textAlign: 'center', position: 'absolute', left: 0, right: 0}}>
                    PRODUCTION ENVIRONMENT
                </div>}
                <div style={{padding: 20}}>
                    {props.children}
                </div>
            </div>
        )
    }
}


export default withFeatureflow({update: true})(connect(mapStateToProps)(AppLayout));