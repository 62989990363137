// @flow
import React from 'react';
import { Button } from 'react-bootstrap';

import {FaSpinner} from 'react-icons/lib/fa'

export default function(props: any): React.Element<any>{
  const {
    spinner,
    children,
    ...otherProps
  } = props;
    return (
      <Button {...otherProps}>
        {children}
        {spinner && <FaSpinner className="fa-spin" style={{verticalAlign: 'text-top', marginLeft: 5, marginRight: -5}}/>}
      </Button>
    )
}