// @flow
import api from './';

export type QueryParams = {
  id?: string,
  page?: number,
  resourceId?: string,
  lastSeenId?: string,
  featureKey?: string,
  projectKey?: string,
}

export function query(params: QueryParams){
  return api('get', '/api/v1/audit-items', {
    params: {
      ...params,
      pageSize: 50
    }
  });
}