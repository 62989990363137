// @flow
import React, {useState} from 'react';
import {Button, ButtonToolbar, Col, Row, Table} from "react-bootstrap";
import {FaTrash, FaEdit} from "react-icons/lib/fa";
import {
    useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery,
    useGetProjectByKeyQuery, useGetSegmentsQuery
} from "../../api/featureflowApi";
import {LoadingOverlay} from "../../components/LoadingOverlay";
import {isAllowed} from "../../utils/authentication";
import console from "../../utils/debug";
import {CreateSegmentModal} from "./CreateSegmentModal";

//previously known as a target
type ContextAttribute = {
    key: string,
    name: string,
    type: 'string' | 'number' | 'date'
}

export type Props = {
    params: {}
}

function Segments (props: Props) {

    const projectKey = props.params.projectKey;
    const environmentKey = props.params.environmentKey;
    const query = props.location.query.query || '';

    const [showCreateModal, setShowCreateModal] = useState(false);

    console.log(projectKey, environmentKey, query);

    const { data: environment, error: environmentError, isLoading: environmentIsLoading }
        = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery({projectKey, environmentKey});
    const { data: project, error: projectError, isLoading: projectIsLoading }
        = useGetProjectByKeyQuery(projectKey);
    console.log(query);
    const { data: segments, error: segmentsError, isLoading: segmentsIsLoading }
        = useGetSegmentsQuery({projectKey, environmentKey, query});
    if( segmentsError ){
        console.log(segmentsError);
    }
    if(!segments){
        return <></>
    }



    function createSegment(projectKey, environmentKey) {
        setShowCreateModal(true);
        console.log("createSegment");
    }

    return (
            <>
                <LoadingOverlay visible={segmentsIsLoading || environmentIsLoading || projectIsLoading} />
                {environment && project && segments &&
                    <>
                        <Row>
                            <Col xs={12} style={{paddingBottom: 10}}>
                                <h2 >
                                    Segments
                                </h2>
                                <small>
                                    Segments for {project && project.name} / {environment && environment.name} environment
                                </small>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} style={{marginTop: 21}}>
                                {isAllowed('create', `segment`, environment.tags || [] , environment.production ? ['production'] : []) &&
                                <div style={{display: 'flex', justifyContent: 'flex-end'}} className="step-new-segment-button">
                                    <Button bsStyle="primary"
                                            onClick={() => {
                                                createSegment({projectKey: projectKey, environmentKey: environmentKey});
                                            }}
                                    >
                                        New Segment
                                    </Button>
                                </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            {segments.length === 0 && <Col xs={12} style={{margin: 50, textAlign: 'center', fontSize: 30}}>
                                    Segments allow you to create defined user cohorts.
                                </Col>
                            }
                            <Col xs={12}>
                                <div className="bs-component">
                                    <Table className="table table-striped table-hover">
                                        <tbody>
                                        {segments.map(segment => <tr>
                                                <td>{segment.name}</td>
                                                <td>{segment.description}</td>
                                                <td className="text-right">
                                                    <ButtonToolbar className="pull-right">
                                                        <Button bsStyle="danger"
                                                                bsSize="xsmall">
                                                            Delete <FaTrash/>
                                                        </Button>
                                                        <Button bsStyle="danger"
                                                                bsSize="xsmall">
                                                            Edit <FaEdit/>
                                                        </Button>
                                                    </ButtonToolbar>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
                <CreateSegmentModal
                    onClose={()=>setShowCreateModal(false)}
                    visible={showCreateModal}
                    environmentKey={{environmentKey}}
                    projectKey={{projectKey}}/>
            </>
        );
}
Segments.defaultProps = {
    contextAttributes: [],
};

export default Segments