// @flow
import React from 'react';
import {Button, ButtonToolbar, Col, Label, ListGroup, ListGroupItem, Row} from 'react-bootstrap';
import ActionButton from './controls/ActionButton';
import {connect} from 'react-redux';
import {actions, Form, Control, Fieldset} from 'react-redux-form';

import Select from 'react-select';

import {FaPlus, FaMinus, FaExclamationTriangle} from 'react-icons/lib/fa';

import {defaultRule, defaultCondition} from '../redux/form/rules';

import {createSelector} from '@reduxjs/toolkit'

import ControlWrapper from '../forms/controls/ControlWrapper';

import TargetsSelector from './RulesForm/TargetsSelector';
import OperatorSelector from './RulesForm/OperatorSelector';
import ValueSelector from './RulesForm/ValueSelector';


import Split from './RulesForm/Split';
import ToggleWrapper from "./controls/ToggleWrapper";

const rulesSort = (a, b) => {
    if (a.defaultRule) {
        return 1;
    }
    if (b.defaultRule) {
        return -1;
    }
    return 0;
}

const rulesSelector = (state) => state.form.rules;

const getRules = createSelector(
    rulesSelector,
    (rules) => {
        return {
            rules: rules.rules.map((rule, index) => {
                return {
                    ...rule,
                    index
                }
            }).sort(rulesSort)
        }
    }
);

function mapStateToProps(state) {
    return {
        enabled: state.form.rules.enabled,
        rules: getRules(state)
    }
}

function displayVariantPicker(props, rule) {
    return (
        <div style={{flex: 1}}>
      <span style={{marginRight: 8, lineHeight: '28px', verticalAlign: 'top'}}>
        {rule.defaultRule
            ? <span style={{verticalAlign: 'top'}}>Show variant</span>
            : <span style={{verticalAlign: 'top'}}>Then show variant</span>
        }
      </span>
            <ControlWrapper style={{marginRight: 8, width: 200, display: 'inline-block'}}
                            placeholder="Variants"
                            model=".split"
                            component={Select}
                            validateOn={['change']}
                            errorMessages={{isRequired: 'Required'}}
                            ignore={['blur']}
                            clearable={false}
                            options={
                                props.variants.map(variant => {
                                    return {
                                        value: variant.key,
                                        label: variant.name
                                    }
                                }).concat({
                                    value: '$$SPLIT',
                                    label: 'Split'
                                })
                            }
                            changeAction={(model = "", value = {}) => {
                                return dispatch => {
                                    value = value.value;
                                    const ruleModel = model.substr(0, model.lastIndexOf('.'));
                                    let splits;
                                    let changeValue = {
                                        split: value
                                    };
                                    if (value === '$$SPLIT') {
                                        splits = props.variants.map(variant => {
                                            return {
                                                key: variant.key,
                                                value: rule.splits[variant.key] || 0
                                            }
                                        });
                                        changeValue.splits = getDefaultSplitValues(splits);
                                    }
                                    dispatch(actions.merge(ruleModel, changeValue));
                                }
                            }}
            />
        </div>
    )
}

function displayDefaultRule(props, rule) {
    const baseRuleModel = `rules[${rule.index}]`;
    return (
        <Fieldset component={ListGroupItem} model={`.rules[${rule.index}]`} key={rule.index} style={{padding: 10}}>
            <div style={{position: 'absolute', top: 12, right: 10}}>
                <Button bsSize="small"
                        bsStyle="success"
                        onClick={() => props.dispatch(actions.push('form.rules.rules', {...defaultRule}))}>
                    Add Rule
                </Button>
            </div>
            <div style={{display: 'flex', marginBottom: -20}}>
                {props.rules.rules.length > 1 &&
                <span style={{
                    minWidth: 80,
                    fontSize: 24,
                    marginBottom: 8,
                    marginRight: 8,
                    lineHeight: '28px',
                    display: 'inline-block'
                }}>
            <Label bsStyle="primary">ELSE</Label>
          </span>
                }
                {displayVariantPicker(props, rule)}
            </div>
            {displaySplit(rule.split, baseRuleModel, props.variants, rule.splits)}
        </Fieldset>
    )
}

function displayRule(props, rule, index) {
    if (rule.defaultRule) {
        return displayDefaultRule(props, rule);
    }
    const baseRuleModel = `rules[${rule.index}]`;
    return (
        <Fieldset component={ListGroupItem} model={`.rules[${rule.index}]`} key={rule.index} style={{padding: 10}}>
            {rule.conditions.map((condition, conditionIndex) => {
                const baseConditionModel = `${baseRuleModel}.conditions[${conditionIndex}]`;
                return (
                    <Fieldset model={`.conditions[${conditionIndex}]`} style={{display: 'flex'}} key={conditionIndex}>
            <span style={{minWidth: 80, fontSize: 24, lineHeight: '24px', marginRight: 8}} className="text-primary">
              {
                  conditionIndex === 0
                      ? <Label bsStyle="primary">{index === 0 ? 'IF' : 'ELSE'}</Label>
                      : <small>AND</small>
              }
            </span>
                        <div style={{display: 'flex', width: '100%'}}>
                            <div style={{flex: 1, marginRight: 8}}>
                                <TargetsSelector baseModel={baseConditionModel}/>
                            </div>
                            <OperatorSelector baseModel={baseConditionModel}/>
                            <div style={{flex: 1.5, marginRight: 8}}>
                                <ValueSelector baseModel={baseConditionModel}/>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}>
                                {(rule.conditions.length > 1) &&

                                <Button bsStyle="danger"
                                        bsSize="small"
                                        style={{marginRight: 8, fontSize: 12}}
                                        onClick={() => {
                                            props.dispatch(
                                                actions.remove(
                                                    `form.rules.rules[${rule.index}].conditions`,
                                                    conditionIndex
                                                )
                                            )
                                            props.dispatch(actions.resetValidity(`form.rules.rules[${rule.index}].conditions.${conditionIndex}`));
                                        }}> <FaMinus/> </Button>

                                }
                            </div>
                            <div style={{flex: 1}}>
                                {(conditionIndex === rule.conditions.length - 1) &&
                                <Button
                                    bsStyle="success"
                                    bsSize="small"
                                    style={{marginRight: 8, fontSize: 12}}
                                    onClick={() => {
                                        props.dispatch(
                                            actions.push(
                                                `form.rules.rules[${rule.index}].conditions`,
                                                {...defaultCondition}
                                            )
                                        )
                                    }}> <FaPlus/> </Button>
                                }
                            </div>
                        </div>
                    </Fieldset>
                )
            })}
            <div>
                <div style={{display: 'flex', marginBottom: -20, marginLeft: 90}}>
                    {displayVariantPicker(props, rule)}
                </div>
                {displaySplit(rule.split, baseRuleModel, props.variants, rule.splits)}
            </div>
            {!rule.defaultRule && (
                <Button
                    bsSize="small"
                    bsStyle="danger"
                    style={{position: 'absolute', right: 8, bottom: 8}}
                    onClick={() => {
                        props.dispatch(actions.remove(`form.rules.rules`, rule.index));
                        props.dispatch(actions.resetValidity(`form.rules.rules.${rule.index}`));
                    }}>
                    Remove
                </Button>
            )}
        </Fieldset>
    )
}

function displayMessageDefaultRuleEvaluatesToRule(rules) {
    const defaultVariantText = variantText(getDefaultRule(rules));
    return rules.filter(rule => !rule.defaultRule).filter(rule => variantText(rule) === defaultVariantText).length > 0
}

function getDefaultRule(rules = []) {
    return rules.filter(rule => rule.defaultRule)[0] || {};
}

function variantText(rule) {
    if (rule.split === '$$SPLIT') {
        return Object.keys(rule.splits)
            .map(key => `${key} ${rule.splits[key]}%`).join(', ');
    } else {
        return rule.split;
    }
}

export default connect(mapStateToProps)(function (props: any): React.Element<any> {
    return (
        <Form model="form.rules" onSubmit={props.onSubmit}>
            <Row>
                <Col xs={12}>
                    <h4 className="text-primary">Feature Targeting is</h4>
                </Col>
            </Row>
            <Row style={{marginBottom: 28}}>
                <Col xs={12}>
                    <div>

                        <Control.checkbox model=".enabled"
                                          component={ToggleWrapper}
                                          id="targeting-enabled"
                                          label="Feature Targeting"
                                          inactiveLabel="Off"
                                          activeLabel="On"
                                          isToggle={true}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h4 className="text-primary">When feature targeting is enabled</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>

                    <ListGroup>
                        {props.rules.rules.map((rule, index) => {
                            return displayRule(props, rule, index);
                        })}
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>

                    <div style={{display: 'flex', marginTop: 10, marginBottom: -30}}>
                        <div style={{marginRight: 8, fontSize: 18}}>
                            <span className="text-danger">Disabled features</span> should all go to
                        </div>
                        <ControlWrapper style={{marginRight: 8, width: 200}}
                                        placeholder="Off Variant"
                                        model=".offVariantKey"
                                        component={Select}
                                        validateOn={['change']}
                                        errorMessages={{isRequired: 'Required'}}
                                        ignore={['blur']}
                                        clearable={false}
                                        options={
                                            props.variants.map(variant => {
                                                return {
                                                    value: variant.key,
                                                    label: variant.name
                                                }
                                            })
                                        }
                                        changeAction={(model = "", value = {}) => {
                                            return dispatch => {
                                                dispatch(actions.change(model, value.value));
                                            }
                                        }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {
                        displayMessageDefaultRuleEvaluatesToRule(props.rules.rules) &&
                        <div className="text-warning">
                            <FaExclamationTriangle/> Your default variant matches one or more targeted rules. Whilst
                            this is
                            valid, you may wish to review your matching logic.
                        </div>
                    }
                </Col>
            </Row>
        </Form>
    )
});

function displaySplit(value, baseRuleModel, variants, splitValues) {
    if (value === '$$SPLIT') {
        return <Split variants={variants} baseRuleModel={baseRuleModel} splitValues={splitValues}/>
    }
}

// Used in the case where we previously had a single variant selected,
// we don't want to default to a slider where one variant is 100% and everything else is 0%
// it is better if everything (in the case of 4 variants) is 25%.
function getDefaultSplitValues(splits) {
    //If there is a split already set we don't want to spread this evenly
    splits = splits.map(split => {
        split.value = typeof split.value === 'number' ? split.value : 0;
        return split;
    });
    if (splits.filter(split => split.value === 100).length > 0 || splits.filter(split => !split.value).length === splits.length) {
        /*
         * To cater for the fact we want to use integers, in the instance that we don't divide into 100% evenly
         * we need to spread the remainder between each variant.
         *
         * For example with 6 variants:
         *    100 / 6 = 16.666r
         *    floor(16.666r) = 16
         *    16 * 6 = 96
         *    100 - 96 = 4 (remaining)
         *
         *    Therefore in the case of six, we need to add 1% to four of the variants
         *
         *    Hence, index < remainders
         *
         * */
        splits = splits.map((split, index) => {
            split.value = Math.floor(100 / splits.length);
            let remainders = (100 - Math.floor(100 / splits.length) * splits.length);
            if (index < remainders) {
                split.value += 1;
            }
            return split;
        })
    }
    return splits.reduce((splits, split) => {
        splits[split.key] = split.value;
        return splits;
    }, {});
}

export const RulesFormButtons = connect(mapStateToProps)((props: any): React.Element<any> => {
    const {onCancel} = props;
    return (
        <ButtonToolbar className="pull-right">
            {onCancel &&
            <Button bsStyle="text"
                    onClick={() => onCancel()}>
                Cancel
            </Button>
            }

            <Control
                component={ActionButton}
                     model="form.rules"
                     type="submit"
                     disabled={fieldValue => fieldValue.pending}
                     mapProps={{
                         disabled: props => props.fieldValue.pending,
                         spinner: props => props.fieldValue.pending
                     }}
                     bsStyle="success"
                     onClick={() => props.dispatch(actions.submit('form.rules'))}
            >
                Save Targeting
            </Control>
        </ButtonToolbar>
    );
});


export function mapFormToApiStructure(formValues: any, variants: any) {
    return {
        rules: formValues.rules.map((rule, index) => {
            return {
                defaultRule: rule.defaultRule,
                audience: rule.defaultRule ? null : {
                    conditions: rule.conditions.map(condition => {
                        return {
                            ...condition,
                            values: [].concat(condition.values)
                        }
                    })
                },
                variantSplits: variants.map(variant => {
                    return {
                        variantKey: variant.key,
                        split: rule.split === '$$SPLIT'
                            ? Math.max(parseInt(rule.splits[variant.key], 10), 0)
                            : (rule.split === variant.key ? 100 : 0)
                    }
                })
            }
        }).sort(rulesSort),
        offVariantKey: formValues.offVariantKey,
        enabled: formValues.enabled
    };
}

export function mapApiStructureToForm(enabled: boolean, rules: any, offVariantKey: string) {
    return {
        enabled,
        offVariantKey,
        rules: rules.map(rule => {
            return {
                defaultRule: rule.defaultRule,
                conditions: !rule.audience
                    ? []
                    : rule.audience.conditions
                /*.map(condition=>{
                return {
                  ...condition,
                  values: condition.values.map(value=>{
                    return {
                      value,
                      label: value
                    }
                  })
                }
              })*/,
                splits: rule.variantSplits.reduce((splits, split) => {
                    splits[split.variantKey] = split.split;
                    return splits;
                }, {}),
                split: rule.variantSplits
                    .filter(split => split.split > 0)
                    .reduce((prev, next, index) => index > 0 ? '$$SPLIT' : next.variantKey, '$$SPLIT')
            }
        }),

    };
}