// @flow
import React from 'react';
import { connect } from 'react-redux';

import { dispatch } from '../redux';

import { Form, Control, actions, Errors } from 'react-redux-form';
import { FormGroup } from 'react-bootstrap';
import ActionButton from './controls/ActionButton';

import {goToRoute, goToSubdomain} from '../utils/routing';

import * as signupFlowApi from '../api/signupFlow';

import ErrorContainer from './controls/ErrorContainer';

import ControlWrapper from './controls/ControlWrapper';

import { findOrgModal } from '../modals/FindOrgModal';

import { withFeatureflow } from 'react-featureflow-client';


function handleSubmit(values: any) {
  dispatch(actions.setPending('form.signup'));
  signupFlowApi.registerEmail(values.email, values.beta)
    .then(response=>{
      dispatch(actions.setPending('form.signup', false));
      return dispatch(actions.change('form.signup.once', response.once));
    }).then(()=>{
    goToRoute('/activate');
  }).catch((error)=>{
    dispatch(actions.setPending('form.signup', false));
    dispatch(actions.setErrors('form.signup', {
      submit: error
    }));
  });
}

function handleLoginSubmit(values: any) {
    dispatch(actions.setPending('form.login'));
    goToSubdomain(values.domain);
    dispatch(actions.setPending('form.login', false));
}


export default withFeatureflow()(connect()(function(props: any): React.Element<any>{
  return (


      <div>
          <Form
              model="form.signup"
              onSubmit={handleSubmit}
          >
              <ControlWrapper model=".email"
                              type="email"
                              label="Enter your email address"
                              placeholder="Email"
                              errorMessages={{
                                  isRequired: 'Please provide an email address.',
                                  isEmail: (val) => `${val} is not a valid email.`,
                              }}/>
              <FormGroup>
                  <Control component={ActionButton}
                           model="form.signup"
                           type="submit"
                           block
                           mapProps={{
                               disabled: props=>props.fieldValue.pending,
                               spinner: props=>props.fieldValue.pending
                           }}
                           bsStyle="success"
                  >
                      Sign up for free
                  </Control>
              </FormGroup>
              <ErrorContainer>
                  <Errors model="form.signup"/>
              </ErrorContainer>
              <br/>
          </Form>
          <p>
              Already using Featureflow?
          </p>
          {props.featureflow.evaluate('app-login').isOn() &&
          <Form
              model="form.login"
              onSubmit={handleLoginSubmit}
          >
              <p>Enter your domain. <small>(e.g. <i>domain</i>.featureflow.io)</small></p>
              <ControlWrapper model=".domain"
                              type="text"
                              placeholder="Domain"
                              errorMessages={{
                                  isRequired: 'Please enter your domain.'
                              }}/>
              <FormGroup>
                  <Control component={ActionButton}
                           model="form.login"
                           type="submit"
                           block
                           mapProps={{
                               disabled: props => props.fieldValue.pending,
                               spinner: props => props.fieldValue.pending
                           }}

                           className="btn-outline-primary"
                  >
                      Login
                  </Control>
              </FormGroup>
              <ErrorContainer>
                  <Errors model="form.login"/>
              </ErrorContainer>
              <br/>
          </Form>
          }

          <p>
              <button className="link-button" onClick={()=>{
                  findOrgModal()
              }}>Find your organisation</button>
          </p>

      </div>


  )
}));