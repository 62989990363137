// @flow
import api from './';


type UserType = {
  id: string,
  fullName: string,
  firstName: string,
  lastName: string,
  email: string,
  authorities: string[],
  introReadDate: ?string,
  activated: boolean
}


export type UserSettings = {
  introReadDate: ?string
}

export type PrincipalUserOutline = {
  id: string,
  firstName: string,
  lastName: string,
  initials: string,
  email: string,
  activated: boolean,
  organisationId: string,
  version: string,
  login: string,
  authorities: [string],
  settings: UserSettings
}

export function get(){
  return api('get', `/api/v1/users`)
}

export function update(data: UserType){
  return api('put', `/api/v1/users`, {
    data
  })
}

export function deleteUser(id: string){
  return api('delete', `/api/v1/users/${id}`)
}


export function markIntroAsRead(id: string){
  return api('put', `/api/v1/users/${id}/settings/intro/read`)
}