// @flow
import React, { useState, useEffect } from 'react';
import {FaBookmark, FaBookmarkO} from 'react-icons/lib/fa';

import {Tooltip, OverlayTrigger, Button} from 'react-bootstrap';
import StateManager from 'react-select';

type BookmarkFeatureProps = {
    featureId: string,
    bookmarked: boolean,
    onClick: (featureId: string, bookmarked: boolean) => void
};

export const BookmarkFeatureButton = (props: BookmarkFeatureProps): React.Element<any> => {
    const {
        featureId,
        bookmarked,
        onClick,
    } = props;

    let [bookmark, setBookmark] = useState(bookmarked);

    useEffect(() => {
        setBookmark(props.bookmarked);
    }, [props.bookmarked]);

    let iconStyle = {
        color: '#ffd600',
    };

    const tooltip = <Tooltip
        id={featureId + 'status'}>{bookmark ? 'Un-bookmark this feature' : 'Bookmark this feature'}</Tooltip>;

    let doOnClick = () => {
        const b = !bookmark;
        setBookmark(b)
        onClick(featureId, b);
    };

    return (
        <span>
            <OverlayTrigger
                overlay={tooltip} placement="bottom"
                delayShow={300} delayHide={150}>
                <Button onClick={doOnClick} bsStyle={'light'}>
                    {bookmark ?
                        <FaBookmark style={iconStyle}/>
                        :
                        <FaBookmarkO/>
                    }
                </Button>
            </OverlayTrigger>
        </span>
    )
}

export default BookmarkFeatureButton;