// @flow
import * as React from 'react';

import {ListGroup, ListGroupItem, Tabs, Tab} from 'react-bootstrap';

import {getDomain} from '../utils/api';

import {goToRoute} from '../utils/routing';

import {connect} from 'react-redux';

import UpgradeLink from '../components/UpgradeLink';

import Projects from './Admin/Projects';
import Audit from './Admin/Audit';
import Billing from './Admin/Billing/Billing';
import Integrations from './Admin/Integrations';
import UserManagement from './Admin/UserManagement';
import RoleManagement from './Admin/RoleManagement';

import * as authSelectors from '../selectors/auth';
import Tokens from "./Admin/Tokens";

import { useFeatureflow } from 'react-featureflow-client';
import { useGetProjectsQuery } from '../api/featureflowApi';

const limitsSelector = state => state.subscriptionOutline.limits;
function mapStateToProps(state) {
    return {
        principalUser: state.principalUserOutline,
        organisation: state.organisation,
        limits: limitsSelector(state),
        isOrganisationAdmin: authSelectors.isOrganisationAdmin(state),
        integrations: state.integrations
    }
}

const Admin = (props: any): React.Element<any> => {
    let {organisation, limits, principalUser, integrations, params} = props;
    let { adminTab, projectKey } = params;
    const { data: projects, error, isLoading: isProjectsLoading } = useGetProjectsQuery();
    let environmentsCount = 0;
    

    const featureflow = useFeatureflow();

    if( isProjectsLoading){
        return <></>;
    }
    if(projects){
        projects.forEach(project => environmentsCount += project.environments ? project.environments.length : 0);
    }
    return (
        <div>
            <h1>Administration</h1>
            <Tabs defaultActiveKey={'projects'}
                  id="administration-tabs"
                  activeKey={adminTab}
                  animation={false}
                  onSelect={(key) => goToRoute(`/admin/${key}`)}>
                <Tab eventKey={'projects'} title="Projects">
                    <Projects projects={projects} environmentsCount={environmentsCount} limits={limits} selectedProject={projectKey}/>
                </Tab>
                {false && <Tab eventKey={'org'} title="Organisation">
                    <h2>Organisation</h2>
                    <ListGroup>
                        <ListGroupItem>
                            {organisation.name} {`(https://${organisation.domain}.${getDomain()})`}
                        </ListGroupItem>
                    </ListGroup>
                </Tab>}
                {props.isOrganisationAdmin &&
                <Tab eventKey={'users'} title="Team">
                    <UserManagement principalUser={props.principalUser} limits={limits}/>
                </Tab>
                }
                {props.isOrganisationAdmin && featureflow.evaluate('roles-admin').isOn() &&
                <Tab eventKey={'roles'} title="Roles">
                    <RoleManagement principalUser={props.principalUser}/>
                </Tab>
                }
                {props.isOrganisationAdmin && featureflow.evaluate('access-tokens').isOn() &&
                <Tab eventKey={'tokens'} title="Access Tokens">
                    <Tokens principalUser={props.principalUser}/>
                </Tab>
                }
                <Tab eventKey={'audit'} title="Audit">
                    <h2>Audit</h2>
                    {limits.auditLog || featureflow.evaluate('billing').isOff()
                        ? <Audit/>
                        : <p> Audit logs are not available with the current plan. <UpgradeLink/></p>}
                </Tab>
                {featureflow.evaluate('billing').isOn() &&
                <Tab eventKey={'billing'} title="Billing" mountOnEnter={true} unmountOnExit={true}>
                    <Billing/>
                </Tab>

                }
                {/*This is if we have integrations on its own tab - se Projects.js otherwise*/}
                {featureflow.evaluate('integrations').isOn() &&
                <Tab eventKey={'integrations'} title="Integrations" mountOnEnter={true} unmountOnExit={true}>
                    <Integrations principalUser={principalUser} integrations={integrations}/>
                </Tab>

                }
            </Tabs>
        </div>
    );
};

export default connect(mapStateToProps)(Admin);