// @flow
import React, { useState, useEffect } from 'react';

type Props = {
    visible: boolean
};

export const LoadingOverlay = (props: Props): React.Element<any> => {
    const { visible } = props;
    const [isVisible, setIsVisible] = useState(false);
    
    useEffect(() => {      
        setIsVisible(visible)
    }, [visible]);
    
    return (
        <div id="loading-overlay" className={isVisible ? 'fadeIn' : 'fadeOut'}>
            <div className="spinner">
                <div className="spinner-middle">
                    <div className="spinner-inner"></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingOverlay;