// @flow
import React from 'react';
import * as userApi from '../../api/user';
import {Button, Col, Label, Table} from 'react-bootstrap';
import { userInvitesFormModal } from '../../modals/UserInvitesFormModal';
import { updateUserModal } from '../../modals/UserFormModal';

import Alert from 'react-s-alert';

import { FaSpinner, FaRefresh } from 'react-icons/lib/fa';
import {numberLessThanLimit} from "../../utils/limits";
import UpgradeLink from "../../components/UpgradeLink";

// import { confirmModal } from '../../modals/ConfirmModal';

type UserType = {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  fullName: string,
  authorities: string[],
  activated: boolean
}

type Authority = {
  name: string,
  description: string
}


class UserManagement extends React.Component{
  state: {
    users: UserType[],
    authorities: Authority[],
    initialFetch: boolean,
    error: boolean,
    removing: ?string
  }
  constructor(props: {principalUser: UserType, limits: any}){
    super(props);
    this.state = {
      initialFetch: true,
      users: [],
      authorities: [],
      error: false,
      removing: undefined,
    };
    this.fetchUsers();
  }

  fetchUsers(){
    userApi.get()
      .then(users=>{
        this.setState({
          initialFetch: false,
          users
        })
      }).catch(error=>{

    })
  }

  inviteUsers(){
    userInvitesFormModal().then(repsonse=>{
      this.fetchUsers();
    })
  }

  isOrganisationAdmin(user: UserType){
    return user.authorities.indexOf('ROLE_ORGANISATION_ADMIN') >= 0
  }

  deleteUser(user: UserType){
    this.setState({
      removing: user.id
    });
    userApi.deleteUser(user.id).then(()=>{
      this.setState({
        removing: undefined,
        users: this.state.users.filter(_user=>_user.id !== user.id)
      });

      Alert.success('User has been removed');

    })
      .catch(err=>{
        Alert.error('There was an error deleting the user');
        this.setState({
          removing: undefined
        })
      })
  }

  render():React.Element<any>{
    return (
      <div>
        <Col xs={12}>
          <h2>Team Management</h2>
        </Col>
        <Col xs={12} >
          <Button disabled={!numberLessThanLimit(this.state.users.length, this.props.limits.maxOrganisationUsers)} onClick={this.inviteUsers.bind(this)}>Invite new Team Members</Button>
        </Col>
          <Col xs={12}><p>Used {this.state.users.length} of {this.props.limits.maxOrganisationUsers} seats</p></Col>
        <Col xs={12} style={{marginTop: 10}}>
          {
            !numberLessThanLimit(this.state.users.length, this.props.limits.maxOrganisationUsers) &&
            <p>
              Only {this.props.limits.maxOrganisationUsers} Team member{this.props.limits.maxOrganisationUsers !== 1 && 's'} allowed. <UpgradeLink/>
            </p>
          }
        </Col>
        <br/>
        <Col xs={12}>
        <h3>Team</h3>
        {this.state.initialFetch ?
          <FaRefresh style={{display: 'block', fontSize: 100, margin:'40px auto'}} className="fa-spin"/>
          :
          <Table className="table table-striped table-hover">
            <thead>
            <tr>
              <th>Email</th>
              <th>Full Name</th>
              <th>Roles</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {this.state.users.map((user) => {
              let isPendingInvite = !user.activated;
              let isCurrentUser = user.id === this.props.principalUser.id;
              return (
                <tr key={user.id}>
                  <td>{user.email} {isCurrentUser && '(You)'}</td>
                  <td>{user.firstName} {user.lastName}</td>
                  <td>
                      {
                        user.authority === 'ROLE_ADMIN' &&
                        <Label bsStyle="primary">Admin</Label>
                      }
                      {
                          user.authority === 'ROLE_ORGANISATION_ADMIN' &&
                          <Label bsStyle="primary">Organisation admin</Label>
                      }
                      {
                          user.authority === 'ROLE_ORGANISATION_PROD_EDITOR' &&
                          <Label bsStyle="primary">Production editor</Label>
                      }
                      {
                          user.authority === 'ROLE_ORGANISATION_EDITOR' &&
                          <Label bsStyle="primary">Non-Production editor</Label>
                      }
                      {
                          user.authority === 'ROLE_ORGANISATION_READONLY_USER' &&
                          <Label bsStyle="primary">Read-only user</Label>
                      }


                  </td>
                  <td>
                    {
                      isPendingInvite ?
                        <Label bsStyle="default" style={{marginRight: 10}}>Invitation Pending</Label>
                        : 'Active'
                    }
                  </td>
                  <td>
                    {
                      this.state.removing === user.id ?
                        <FaSpinner className="fa-spin"/>
                        :
                        isPendingInvite ?
                          <Button bsStyle="warning"
                                  bsSize="xs"
                                  onClick={()=>this.deleteUser(user)}
                          >
                            Cancel Invite
                          </Button>
                          :
                          <Button bsStyle="primary"
                                  bsSize="xs"
                                  onClick={()=>updateUserModal(user, this.deleteUser.bind(this)).then(user=>{
                                    this.setState({users: this.state.users.map(stateUser=>{
                                      if (stateUser.id === user.id){
                                        return user;
                                      }
                                      else{
                                        return stateUser;
                                      }
                                    })})
                                  })}
                          >
                            Edit Member
                          </Button>
                    }
                  </td>
                </tr>
              )
            })}
            </tbody>
          </Table>
        }
        </Col>
      </div>
    )
  }
}

export default UserManagement;