// @flow
import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {withFeatureflow} from 'react-featureflow-client';
import {connect} from 'react-redux';
import {Button, FormGroup} from "react-bootstrap";
import ErrorContainer from "../../forms/controls/ErrorContainer";
import {Async} from "react-select";
import * as projectApi from "../../api/project";
import {isAllowed} from "../../utils/authentication";
import {convertStringToKey} from "../../utils/formParsers";
import {dispatch} from "../../redux";
import * as features from "../../redux/features";
import {goToFeatureRoute} from "../../utils/routing";
import ActionButton from "../../forms/controls/ActionButton";
import {LinkContainer} from "react-router-bootstrap";


function mapStateToProps(state, props) {
    return {
        principalUser: state.principalUserOutline,
        organisation: state.organisation
    }
}

export default withFeatureflow({update: true})(connect(mapStateToProps)(function (props: any): React.Element<any> {
        const {register, handleSubmit, control, setError, formState: { errors }} = useForm();
        let { location } = props;
        let issueKey = location.query.issueKey || "";
        const [submitting, setSubmitting] = useState(false);

        const onSubmit = values => {
            setSubmitting(true);
            dispatch(
                features.doUpdateFeature({
                    ...values,
                    projectKey: values.project.value,
                    jiraIssueKeys: [values.jira]


                })
            ).then(response => {
                goToFeatureRoute(response.projectKey, response.key);
            }).then()
                .catch(err => {
                    setError("general", "error", err);
                    setSubmitting(false);
                });
            //save hte feature
        }; // your form submit function which will invoke after successful validation

        const getOptions = (input) => {
            return new Promise(resolve => {
                projectApi.find(input).then(results => {
                    resolve({
                        options: results.map(project => {
                            return {
                                label: project.name,
                                value: project.key
                            }
                        })
                    })
                });
            })
        }
        return (

            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <legend>Create feature</legend>
                    <FormGroup validationState={errors.project ? 'error' : null} bsSize="small">
                        <label htmlFor="inputEmail" className="col-lg-2 control-label">Project</label>
                        <div className="col-lg-8">
                            <Controller
                                name="project"
                                value=""
                                control={control}
                                defaultValue={false}
                                rules={{required: true}}
                                render={({ field }) => <Async
                                    loadOptions={getOptions}
                                    {...field}/>}
                            />
                            <ErrorContainer>
                                {errors.project && <p>Please select a project</p>}
                            </ErrorContainer>

                        </div>
                    </FormGroup>
                    <FormGroup validationState={errors.name ? 'error' : null} bsSize="small">
                        <label htmlFor="inputName" className="col-lg-2 control-label">Name</label>
                        <div className="col-lg-8">
                            <input name="name" type="text" className="form-control" id="inputName" placeholder="Name"
                                   autoComplete="off"
                                   {...register("name", {required: true})}
                            />
                            <ErrorContainer>
                                {errors.name && <p>Name is required</p>}
                            </ErrorContainer>
                        </div>
                    </FormGroup>
                    <FormGroup validationState={errors.key ? 'error' : null} bsSize="small">
                        <label htmlFor="inputKey" className="col-lg-2 control-label">Key</label>
                        <div className="col-lg-8">
                            <input type="text" className="form-control" name="key" id="inputKey" placeholder="Key"
                                   autoComplete="off"
                                   {...register("key", {required: true})}
                                   onChange={e => {
                                       e.target.value = convertStringToKey(e.target.value);
                                   }}
                            />
                            <ErrorContainer>
                                {errors.key && <p>Key is required</p>}
                            </ErrorContainer>
                        </div>
                    </FormGroup>
                    <FormGroup validationState={errors.jira ? 'error' : null} bsSize="small">
                        <label htmlFor="jira" className="col-lg-2 control-label">Jira</label>
                        <div className="col-lg-8">
                            <input type="text" className="form-control" name="jira" placeholder="Jira"
                                   autoComplete="off"
                                   defaultValue={issueKey}
                                   {...register("jira")}
                                   style={{
                                       backgroundImage: 'url("data:image/png',
                                       backgroundRepeat: 'no-repeat',
                                       backgroundAttachment: 'scroll',
                                       backgroundSize: '16px 18px',
                                       backgroundPosition: '98% 50%',
                                       cursor: 'auto'
                                   }}/>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="col-lg-8 col-lg-offset-2">
                            <ErrorContainer>
                                {errors.general && errors.general.message}
                            </ErrorContainer>
                            <ActionButton
                                spinner={submitting} disabled={!isAllowed('create', `/project/${props.projectKey}/features/`, [], [])}
                                type="submit" className="btn btn-success pull-right" style={{marginLeft: 3}}> Create Feature</ActionButton>
                            <LinkContainer to="/">
                                <Button type="reset" className="btn btn-default pull-right" >Cancel</Button>
                            </LinkContainer>

                        </div>
                    </FormGroup>
                </fieldset>
            </form>
        )
    }
))