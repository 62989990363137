// @flow
import React from 'react';

import './PasswordStrength.scss';

const colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];

type PasswordStrengthProps = {
  password: string,
  style?: any
}
export default function(props: PasswordStrengthProps): React.Element<any>{
  const strength = getPasswordStrength(props.password);
  const color = getColorFromPasswordStrength(strength);
  return (
    <div className="password-strength-bar" style={props.style}>
      <small>Password strength:</small>
      <ul>
        {colors.map((c, index)=>{
          return (
            <li key={index}
                className="point"
                style={index <= color.index && props.password.length > 0 ? { backgroundColor: color.color } : undefined}
            />
          )
        })}
      </ul>
    </div>
  )
}

function getPasswordStrength(p: string): number{
  let _force = 0;
  // False positive on no-useless-escape (no idea why that is)
  // eslint-disable-next-line
  let _regex = /[$-/:-?{-~!"^_`\[\]]/g;

  let _lowerLetters = /[a-z]+/.test(p);
  let _upperLetters = /[A-Z]+/.test(p);
  let _numbers = /[0-9]+/.test(p);
  let _symbols = _regex.test(p);

  let _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];

  let _passedMatches = _flags.filter((el) => {
    return el === true;
  }).length;

  _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
  _force += _passedMatches * 10;

  // penality (short password)
  _force = (p.length <= 6) ? Math.min(_force, 10) : _force;

  // penality (poor variety of characters)
  _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
  _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
  _force = (_passedMatches === 3) ? Math.min(_force, 40) : _force;

  return _force;
}

function getColorFromPasswordStrength(strength: number){
  let index;
  if (strength <= 10) {
    index = 0;
  }
  else if (strength <= 20) {
    index = 1;
  }
  else if (strength <= 30) {
    index = 2;
  }
  else if (strength <= 40) {
    index = 3;
  }
  else {
    index = 4;
  }
  return {
    index,
    color: colors[index]
  }
}