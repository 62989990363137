// @flow
import React from 'react';
import { Modal } from 'react-bootstrap';

import { connect } from 'react-redux';
import * as modalController from '../redux/modalController';

function mapStateToProps(state){
  return {
    modalController: state.modalController
  }
}

function showModalTitle(title, closeButton): ?React.Element<any>{
  if (title || closeButton){
    return (
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
    )
  }
}

function showModalBody(body): ?React.Element<any>{
  if (body){
    return (
      <Modal.Body>
        {body}
      </Modal.Body>
    )
  }
}

function showModalFooter(footer): ?React.Element<any>{
  if (footer){
    return (
      <Modal.Footer>
        {footer}
      </Modal.Footer>
    )
  }
}

function closeModal(props){
  props.dispatch(modalController.close())
}


type ReduxModalProps = {
  dispatch: (Action)=>any,
  modalController: any
}
export default connect(mapStateToProps)(function(props: ReduxModalProps): React.Element<any>{
  const modals = props.modalController.modals;
  return (
    <div>
    {
      modals.map((modal, index)=>{
      return <Modal onHide={closeModal.bind(this, props)}
                    key={modal.key}
                    backdrop={modal.static ? 'static' : true}
                    show={props.modalController.show === (index + 1)}
                    bsSize={modal.bsSize}
                    restoreFocus={false}
      >
        {showModalTitle(modal.title, modal.closeButton)}
        {showModalBody(modal.body)}
        {showModalFooter(modal.footer)}
      </Modal>
    })}
  </div>
  );
})