// @flow
import api from './';


type ProjectSummary = {
    id: string,
    organisationId: string,
    name: string,
    key: string,
}
type FeatureData = {
    id?: string,
    key: string,
    name: string,
    description: string,
    projectKey: string,
    project: ProjectSummary,
    variants: Variant[],
}

export type Variant = {
    description?: String,
    key: String,
    meta?: string,
    name: string,
}

export type Feature = {
    id: string,
    ...FeatureData,
    controls: FeatureControl[],
    projectName: string;
}

export type FeatureControl = {
    archived: boolean,
    available: boolean,
    createdDate: string,
    enabled: boolean,
    environmentKey: string,
    featureId: string,
    id: string,
    key: string,
    offVariantKey: string,
    projectKey: string,
    rules: Rule[],
    rulesDescription?: string,
    staleDays: number,
    statistics: any,
    targetingStatus: string,
    trafficStatus: string
}

export type Rule = {
    defaultRule: boolean,
    description: string,
    variantSplits: VariantSplit[]
}

export type VariantSplit = {
    variantKey: string,
    split: number
}

export type FeatureQuery = {
    projectKey?: String,
    environmentKey?: String,
    filter?: string,
    page?: number,
    size?: number,
    sort?: string,
}


export function getRecent(): Feature[] {
    return getFilter("recent");
}
export function getBookmarked(): Feature[] {
    return getFilter("bookmarked");
}
export function getMaintaining(): Feature[] {
    return getFilter("maintaining");
}
export function fetchByProjectKey(projectKey){
    return get({ projectKey });
}
function getFilter(filter: string) {
    return api('get', `/api/v1/features?filter=${filter}`);
}
export function get(query: FeatureQuery) {
    let url = '';
    let {projectKey, environmentKey, filter} = query;
    url = `/api/v1/features`;
    if (projectKey || environmentKey || filter ) {
        url += `?`;
    }
    if (projectKey) {
        url = url + `projectKey=${projectKey}&`
    }
    if (environmentKey) {
        url = url + `environmentKey=${environmentKey}&`
    }
    if (filter) {
        url = url + `query=${filter}`
    }
    return api('get', url);
}


export function update(data: FeatureData) {
    return api(data.id ? 'put' : 'post', `/api/v1/projects/${data.projectKey}/features`, {
        data
    })
}

export function link(data: FeatureData) {
    return api('put', `/api/v1/features/link`, {
        data
    })
}

export function bookmark(featureId: string, bookmarked: boolean) {
    return api('put', `/api/v1/features/${featureId}/bookmark/${bookmarked}`)
}


export function remove(projectKey: string, featureId: string) {
    return api('delete', `/api/v1/project/${projectKey}/features/${featureId}`);
}