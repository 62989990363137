import React from 'react';
import { Link } from 'react-router';
import { getRoute } from '../../utils/routing';

const RenderFinished = () => {
    return (
        <div>
            <p>Now you have finished creating a feature you can go and manage it.</p>
            <Link to={getRoute("./features")} onlyActiveOnIndex={false}>Click here to go to your environment</Link>
        </div>
    );
};

export default RenderFinished;