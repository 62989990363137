// @flow
import React from 'react';
import {IndexRoute, Route, IndexRedirect} from 'react-router';

import Loading from './Loading';
import Admin from './Admin';
import FeatureCreate from "./Features/FeatureCreate";
import Profile from './Profile';
import Tutorial from './Tutorial';
import FeatureControls from './FeatureControls';
import Login from './Login';
import OrganisationLogin from "./OrganisationLogin";
import Signup from './Signup';
import InvitedSignup from './InvitedSignup';
import Activate from './Activate';
import ResetPassword from './ResetPassword';
import PageNotFound from './PageNotFound';
import Experiments from './Experiments';
import ExperimentStats from './ExperimentStats';
import Test401 from './Test401';
import JiraConnect from "./Admin/JiraConnect";
import JiraIntegration from "./Admin/JiraIntegration";
import UsersList from "./UsersList"

import AppLayout from '../layouts/AppLayout';
import GlobalLayout from '../layouts/GlobalLayout';

import * as auth from '../redux/authentication';
import * as JWTService from '../utils/JWTService';
import * as routeStorage from '../utils/RouteStorageService';
import * as Socket from '../socket';

import store, {dispatch} from '../redux';
import featureflow from '../config/featureflow';

import {goToRoute} from '../utils/routing';

import Alert from 'react-s-alert';

import {actions} from 'react-redux-form';

import * as loginApi from '../api/login';

import {getDomain, getSubdomain} from '../utils/api';

import {setToken} from '../redux/authentication';
import FeatureDetailPage from './FeatureControls/FeatureDetailPage';
import FeatureLink from "./Features/FeatureLink";
import Cookies from "js-cookie";
import Home from "./Home";
import { Project } from "../api/project";
import type {Environment} from "../api/environment";
import Segments from "./Segments/Segments";


function requireAuth(nextState, replace) {
    //1. -> /signup on anything -> app.ff/signup
    //-> /login on app then show generic login
    //-> /login on subdomain then subdomain login  - if authenticated then -> /
    //-> app.ff/ -> -> cookie && token? -> subdomain.ff else cookie? -> subdomain login else generic login
    //-> app.ff/other -> cookie && token? -> subdomain.ff/other else cookie? subdomain login else generic login
    //->
    const state = store.getState();


    //if we are root path, /signup or have no subdomain cookie then send to signup
    /*if(nextState.location.pathname == 'signup' || (nextState.location.pathname == "" && !cookieSubdomain)){
      goToRoute('/signup', true);
      return;
    }*/
    const currentDomain = getDomain();
    const cookieSubdomain = Cookies.get('ffsubdomains');
    let subdomain = getSubdomain();
    let redirect = subdomain === 'redirect';
    if (redirect) {
        subdomain = cookieSubdomain ? cookieSubdomain : 'app';
        redirect = true;
    }


    if (nextState.location.pathname === "signup") {
        return;
    }

    if (!state.authentication.token) {
        if (nextState.location.pathname !== '/') {
            routeStorage.setLastRoutePath('current', nextState.location.pathname + nextState.location.search);
        }

        //auto redirect if we are on mysite example redirect domain (we use this for jira integration)
        if (redirect) {
            window.location.href = `http://${subdomain}.${currentDomain}${nextState.location.pathname}${nextState.location.search}`;
            return;
        }

        if (subdomain === 'app') {
            replace({
                pathname: '/signup', //?redirect_to=' + nextState.location.pathname + nextState.location.search,
                state: {
                    nextPathname: nextState.location.pathname,
                    nextPathsearch: nextState.location.search
                }
            });
        } else {
            replace({
                pathname: '/login', //?redirect_to=' + nextState.location.pathname + nextState.location.search,
                state: {
                    nextPathname: nextState.location.pathname,
                    nextPathsearch: nextState.location.search
                }
            });
        }
    } else {
        if (redirect) {
            window.location.href = `http://${subdomain}.${currentDomain}${nextState.location.pathname}${nextState.location.search}`;
            return;
        }
    }
    checkSubscriptionValid(null, nextState, replace);
}

function logout(nextState, replace) {
    dispatch(auth.reset());
    JWTService.clearAuthToken();
    Socket.disconnect();
    featureflow.updateUser();
    window.Intercom("shutdown");
    replace({
        pathname: '/login',
        state: {nextPathname: nextState.location.pathname}
    })
}

function hasAuth() {
    if (store.getState().authentication.token) {
        goToRoute('/', true);
    }
}

function redirectToFeature(nextState, replace) {
    const state = store.getState();
    const projectKey = nextState.params.projectKey;
    //got to the first environment if not specified
    if (!nextState.params.environmentKey && state.projects[projectKey]) {
        nextState.params.environmentKey = state.projects[projectKey].environments[0].key;
    }
    const environmentKey = nextState.params.environmentKey;
    const featureKey = nextState.params.featureKey;
    goToRoute("/projects/" + projectKey + "/environments/" + environmentKey + "/features/" + featureKey, true);

}

function redirectToEnvironmentKey(nextState, replace) {
    const state = store.getState();
    const projectKey = nextState.params.projectKey;
    const envKeyOrId = nextState.params.environmentKey;
    const currentProject: Project = state.projects[projectKey];

    if ( currentProject && !currentProject.environments.find(e => e.key === envKeyOrId)) {
        const env: Environment = currentProject.environments.find(e => e.id === envKeyOrId);
        if(env){
            nextState.params.environmentKey = env.key;
            var pathArray = nextState.location.pathname.split('/');
            let newPathName = "";
            let updateNext = false;
            for (let i = 0; i < pathArray.length; i++) {
                if(i>0){
                    newPathName += "/";
                }
                if(updateNext){
                    newPathName += env.key;
                    updateNext = false;
                }else{
                    newPathName += pathArray[i];
                }
                if(pathArray[i] === 'environments'){
                    updateNext = true;
                }
            }
            if(nextState.location.query['key']){
                newPathName += "/" + nextState.location.query['key'];
            }

            replace(newPathName);
        }
    }
}

function hasOnceToken(nextState, replace) {
    const state = store.getState();
    if (!state.form.signup.once) {
        replace({
            pathname: '/login'
        })
    }
}

function hasResetPasswordKey(nextState, replace) {
    if (!nextState.location.query.key) {
        replace({
            pathname: '/login'
        })
    }
}

//TODO FIND A BETTER PLACE FOR THIS
function checkSubscriptionValid(prevState, nextState, replace) {
    const state = store.getState();
    let subscription = state.subscriptionOutline;
    if (subscription) {
        if (subscription.status === 'inactive') {
            if (state.principalUserOutline.authorities.indexOf('ROLE_ORGANISATION_ADMIN') >= 0) {
                if (nextState.location.pathname.indexOf('/admin') !== 0) {
                    replace({pathname: '/admin/billing'});
                }
            } else {
                if (nextState.location.pathname.indexOf('/logout') !== 0) {
                    replace({pathname: '/logout'});
                }
            }
            setTimeout(() => {
                Alert.closeAll();
                Alert.error('Your account is inactive. Please purchase a subscription.');
            }, 500)
        }
    }

}

// This is used to clear the filters used on each page.
function clearFilters() {
    dispatch(actions.reset('form.filters'));
}



export default function (): React.Element<any> {

    function loginWithToken(nextState, replace) {

        let token = nextState.params.token;
        if (!token) {
            return replace('/');
        }

        loginApi.authenticate({
            token,
            domain: getSubdomain(),
            rememberMe: true
        }).then(response => {
            const currentSubdomain = getSubdomain();
            if (currentSubdomain != null && currentSubdomain !== 'app') {
                Cookies.set('ffsubdomains', currentSubdomain, {domain: `.${getDomain()}`, expires: 365})
            }
            JWTService.setAuthToken(response.id_token);
            dispatch(setToken(response.id_token));
            goToRoute('/', true);
        }).catch((err) => {
            goToRoute('/login', true);
        });
    }

    return (
        <Route component={GlobalLayout} onChange={clearFilters}>
            <Route path="login/:token" component={Loading} onEnter={loginWithToken}/>
            <Route path="login" component={Login} onEnter={hasAuth}/>
            <Route path=":organisation/login" component={OrganisationLogin} onEnter={hasAuth}/>
            <Route path="signup" component={Signup}/>
            <Route path="activate" component={Activate} onEnter={hasOnceToken}/>
            <Route path="invited/:activationKey" component={InvitedSignup}/>
            <Route path="resetpassword" component={ResetPassword} onEnter={hasResetPasswordKey}/>
            <Route path="logout" onEnter={logout}/>
            <Route path="/" name="RootApp" component={AppLayout} onEnter={requireAuth} onChange={checkSubscriptionValid}>
                <IndexRoute component={Home}/>
                <Route path="home" component={Home}/>
                <Route path="features/create" component={FeatureCreate}/>
                <Route path="features/link" component={FeatureLink}/>
                <Route path="projects/:projectKey">
                    <Route path="features/:featureKey" onEnter={redirectToFeature}/>
                    <Route path="environments/:environmentKey" onEnter={redirectToEnvironmentKey}>
                        <IndexRedirect to="features"/>
                        <Route path="features/:featureKey" component={FeatureDetailPage}>
                            <Route path=":featureDetailPageTab" name="FeatureDetailPageTab" component={FeatureDetailPage}/>
                        </Route>
                        <Route path="features" component={FeatureControls}/>
                        <Route path="experiments">
                            <IndexRoute component={Experiments}/>
                            <Route path=":experimentId/stats" component={ExperimentStats}/>
                        </Route>
                        <Route path="tutorial" name="Tutorial" component={Tutorial}/>
                        <Route path="users" name="Users" component={UsersList}/>
                        <Route path="segments" name="Segments" component={Segments}/>
                    </Route>
                </Route>
                <Route path="profile" name="Profile" component={Profile}/>
                <Route path="admin" name="Admin">
                    <Route path=":adminTab" name="Admin" component={Admin}/>
                    <Route path=":adminTab/:projectKey" name="Project Admin" component={Admin}/>
                    <Route path="integrations/jira/connect" component={JiraConnect}/>
                    <Route path="integrations/jira/:jiraIntegrationId" component={JiraIntegration}/>
                    <IndexRedirect to="projects"/>
                </Route>
                <Route path="test/401" component={Test401}/>
                <Route path="*" component={PageNotFound}/>
            </Route>
        </Route>
    )
}