// @flow
import * as JWTService from '../utils/JWTService';

type AuthenticationState = {
  token: ?string
};
const PREFIX: string = 'FF/Authentication/';
const RESET: string = PREFIX + 'RESET';
const SET_TOKEN: string = PREFIX + 'SET_TOKEN';

const initialState: AuthenticationState = {
  token: undefined
};

export default function (state: AuthenticationState=initialState, action: Action): AuthenticationState{
  switch(action.type){
    case(SET_TOKEN):
      return {
        ...state,
        token: action.payload.token,
      }
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setToken(token: string): Action{
  JWTService.setAuthToken(token);
  return {
    type: SET_TOKEN,
    payload: {
      token
    }
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}