// @flow
import React from 'react';
import console from '../utils/debug';
import * as testingApi from '../api/testing';
export default class Test401 extends React.Component{
  state = {
    time: 10
  }
  constructor(){
    super();
    const interval = setInterval(()=>{
      if (this.state.time === 0){
        clearInterval(interval);
        testingApi.get401().catch(console.warn);
      }
      else{
        this.setState({
          time: this.state.time - 1
        })
      }
    }, 1000)
  }
  render(){
    return <div style={{padding: 50, textAlign: 'center'}}>
      <h1>Testing 401</h1>
      <p>Firing API call{this.state.time > 0 && ` in ${this.state.time} seconds`}...</p>
    </div>
  }
}