// @flow
import React from 'react';
import * as roleApi from '../../api/role';
import {Button, ButtonToolbar, Table} from 'react-bootstrap';

import Alert from 'react-s-alert';

import {FaRefresh, FaSpinner, FaTrash} from 'react-icons/lib/fa';
import {updateRoleModal} from "../../modals/RoleFormModal";

type RoleType = {
    id: string,
    name: string
}
type Authority = {
    name: string,
    description: string
}

type UserType = {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    fullName: string,
    authorities: string[],
    activated: boolean
}


class RoleManagement extends React.Component {
    state: {
        initialFetch: boolean,
        roles: RoleType[],
        authorities: Authority[],
        error: boolean,
        removing: ?string
    }

    constructor(props: { principalUser: UserType }) {
        super(props);
        this.state = {
            initialFetch: true,
            roles: [],
            authorities: [],
            error: false,
            removing: undefined,
        };
        this.fetchRoles();
    }

    fetchRoles() {
        roleApi.get()
            .then(roles => {
                this.setState({
                    initialFetch: false,
                    roles
                })
            }).catch(error => {
        })
    }


    deleteRole(role: RoleType) {
        this.setState({
            removing: role.id
        });
        roleApi.deleteRole(role.id).then(() => {
            this.setState({
                removing: undefined,
                roles: this.state.roles.filter(_role => _role.id !== role.id)
            });

            Alert.success('Role has been deleted');

        })
            .catch(err => {
                Alert.error('There was an error deleting the role');
                this.setState({
                    removing: undefined
                })
            })
    }

    render(): React.Element<any> {
        return (
            <div>
                <h2>Role Management</h2>
                {/*<h3>Roles</h3>*/}
                {this.state.initialFetch ?
                    <FaRefresh style={{display: 'block', fontSize: 100, margin: '40px auto'}} className="fa-spin"/>
                    :

                    <Table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>Role</th>
                            <th>Description</th>
                            <th className="text-right">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Product Owner</td>
                            <td>
                                Product owner that may manage features in production.
                            </td>
                            <td className="text-right">
                                <ButtonToolbar className="pull-right">
                                    <Button bsStyle="primary" bsSize="xs">
                                        Edit Role
                                    </Button>
                                    <Button bsStyle="danger"
                                            bsSize="xsmall">
                                        Delete Role <FaTrash/>
                                    </Button>
                                </ButtonToolbar>
                            </td>
                        </tr>
                        <tr>
                            <td>PVT Tester</td>
                            <td>
                                PVT Tester may manage features tagged PVT in production
                            </td>
                            <td className="text-right">
                                <ButtonToolbar className="pull-right">
                                    <Button bsStyle="primary" bsSize="xs">
                                        Edit Role
                                    </Button>
                                    <Button bsStyle="danger"
                                            bsSize="xsmall">
                                        Delete Role <FaTrash/>
                                    </Button>
                                </ButtonToolbar>
                            </td>
                        </tr>
                        {this.state.roles.map((role) => {
                            return (
                                <tr key={role.id}>
                                    <td>{role.name}</td>
                                    <td>
                                        {
                                            this.state.removing === role.id ?
                                                <FaSpinner className="fa-spin"/>
                                                :
                                                <Button bsStyle="primary"
                                                        bsSize="xs"
                                                        onClick={() => updateRoleModal(role, this.deleteRole.bind(this)).then(role => {
                                                            this.setState({
                                                                roles: this.state.roles.map(stateRole => {
                                                                    if (stateRole.id === role.id) {
                                                                        return role;
                                                                    }
                                                                    else {
                                                                        return stateRole;
                                                                    }
                                                                })
                                                            })
                                                        })}
                                                >
                                                    Edit User
                                                </Button>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                }
            </div>
        )
    }
}

export default RoleManagement;