// @flow
import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Panel, Row} from "react-bootstrap";
import type {CancelSubscription, Price, Product, SubscribeToPrice} from "../../../api/billing/types";


type Props = {
    product: Product,
    currentPriceId: string,
    visiblePrice: string, //the price type we are showing (monthly or yearly)
    subscribeToPrice: SubscribeToPrice,
    cancelSubscription: CancelSubscription,
    paidSeats: number,
    usedSeats: number
};


function PerSeatProduct({product, cancelSubscription, subscribeToPrice, visiblePrice, currentPriceId, paidSeats, usedSeats}: Props) {
    const [seatMinimum, setSeatMinimum] = useState(product.seatMinimum);
    const [seats, setSeats] = useState(product.seatMinimum);
    const { name } = product;
    const price: Price = product.prices[visiblePrice];
    const isCurrentPrice = currentPriceId === price.id;

    const onSeatsChange = (e) => {
        setSeats(e.target.value);
    }

    const doSubscribeToPrice = (price, seats) => () => {
        subscribeToPrice(price, seats);
    }
    useEffect(() => {
        const min = Math.max(product.seatMinimum, usedSeats)
        setSeatMinimum(min)
    }, [paidSeats, product, usedSeats]);

    useEffect(() => {
        setSeats(isCurrentPrice ? paidSeats : Math.max(product.seatMinimum, usedSeats))
    }, [isCurrentPrice, paidSeats, product, usedSeats]);



    const monthlyPrice = price.interval === 'month' ? price.amount : price.amount / 12;
    return (
        <Col lg={3} md={3} sm={6} xs={12} key={`${price.id}_${price.interval}`}>
            <Panel>
                <Panel.Heading
                    style={{textAlign: 'center', fontSize: 18}}>{product.name}</Panel.Heading>
                <Panel.Body style={{textAlign: 'center'}}>
                    <Row>
                        <Col xs={12}>
                            <h2 style={{marginTop: 0}}>${(monthlyPrice / 100).toFixed(2)}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h5>({price.currency.toUpperCase()} / seat / month, billed {price.interval === 'month' ? 'monthly' : 'yearly'} )</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <b>Features</b>
                            <ul style={{textAlign: 'left'}}>
                                {product.features.map(feature =>
                                    <li>{feature}</li>
                                )}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <FormGroup controlId="formControlsSelect">
                                <input type="number"
                                       className={'form-control'}
                                       value={seats}
                                       min={ seatMinimum }
                                       max={product.seatMaximum}
                                       onChange={(e)=>{onSeatsChange(e)}}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6} style={{textAlign: 'left'}}>
                            <h4>seats</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p style={{fontSize: 18}}>
                                {price.description}
                            </p>
                        </Col>
                    </Row>
                    <Button onClick={doSubscribeToPrice(price, seats)}
                            bsStyle="primary" bsSize="sm">
                        {isCurrentPrice ? 'Update Seats' : `Subscribe to ${name}`}
                    </Button>
                    {isCurrentPrice && price.amount > 0 && <div style={{marginTop: 10}}>
                        <Button onClick={cancelSubscription.bind(this, price)}
                                bsStyle="default" bsSize="xs">
                            Cancel Subscription
                        </Button>
                    </div>}
                </Panel.Body>
            </Panel>
        </Col>
    )
}

export default PerSeatProduct;
