// @flow
import React from 'react';
import FixedPricingPlan from "./FixedPricingPlan";
import type {CancelSubscription, Price, SubscribeToPrice} from "../../../api/billing/types";
import {Row} from "react-bootstrap";
import EnterprisePricingPlanNew from "./EnterprisePricingPlanNew";
import EnterprisePricingPlan from "./EnterprisePricingPlan";

/**
 * Legacy pricing view
 */
export type AvailablePricesProps = {
    prices: Price[],
    currentPriceId: string,
    subscribeToPrice: SubscribeToPrice,
    cancelSubscription: CancelSubscription
}
const AvailablePrices = ({prices, currentPriceId, subscribeToPrice, cancelSubscription}: AvailablePricesProps) => {
    return (
        <Row>
            {
                prices && prices.map(price => {
                    const currentPlan = price.id === currentPriceId;
                    return (
                        <>
                            {
                                (!price.unitLabel && price.id !== 'free-tier') &&
                                <FixedPricingPlan
                                    key={price.id}
                                    price={price}
                                    currentPlan={currentPlan}
                                    subscribeToPrice={subscribeToPrice}
                                    cancelSubscription={cancelSubscription}/>
                            }
                        </>
                    )
                })}
            <EnterprisePricingPlan/>
        </Row>
    )
}

export default AvailablePrices;
