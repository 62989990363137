// @flow
import console from '../utils/debug';

type SocketStatus = 'CONNECTED' | 'CONNECTING' | 'DISCONNECTED';

type ApplicationState = {
  socketStatus: SocketStatus
};
const PREFIX: string = "FF/Application/";
const RESET: string = PREFIX + "RESET";
const SET_SOCKET_STATUS: string = PREFIX + "SET_SOCKET_STATUS";

const initialState: ApplicationState = {
  socketStatus: 'DISCONNECTED'
};

export default function (state: ApplicationState=initialState, action: Action): ApplicationState{
  switch(action.type){
    case(RESET):
      return initialState;
    case(SET_SOCKET_STATUS):
      return {
        ...state,
        socketStatus: action.payload.socketStatus
      }
    default:
      return state;
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}

export function setSocketStatus(socketStatus: SocketStatus): Action{
  return {
    type: SET_SOCKET_STATUS,
    payload: {
      socketStatus
    }
  }
}
