// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';

import { withFeatureflow } from 'react-featureflow-client';

import ControlWrapper from './controls/ControlWrapper';
import FormErrors from './controls/FormErrors';
import ActionButton from './controls/ActionButton';
import { Control, actions } from 'react-redux-form';

import ButtonColorPicker from '../components/ButtonColorPicker';

import ToggleButton from 'react-toggle-button';

import Select from 'react-select';
import {convertStringToKey} from "../utils/formParsers";

function mapStateToProps(state, props){
  return {
    environment: state.form.environment,
    environments: [{
      label: 'Do not clone',
      value: undefined
    }].concat(state.projects[props.projectKey].environments
      .map(environment=>{
        return {
          label: `Clone "${environment.name}"`,
          value: environment.id
        }
      }))
  }
}

const EnvironmentForm = (props: any):React.Element<any> => {
  return (
    <Form model="form.environment" onSubmit={props.onSubmit}>
      <ControlWrapper model=".name"
                     type="text"
                     placeholder="Name"
                     label="Name"
                     errorMessages={{
                       isRequired: 'Name is required'
                     }}
      />
        {!props.update &&
            <ControlWrapper model=".key"
                            type="text"
                            label="Key"
                            placeholder="Key"
                            errorMessages={{
                                isRequired: 'Key is required'
                            }}
                            parser={convertStringToKey}
                            changeAction={keyInputMask}
                            validateOn={['change']}
            />
        }
        {props.update &&
            <ControlWrapper model=".key"
                            type="text"
                            label="Key"
                            placeholder="Key"
                            errorMessages={{
                                isRequired: 'Key is required'
                            }}
                            disabled={true}
                            validateOn={['change']}
            />
        }
      <ControlWrapper model=".url"
                     type="text"
                     label="Url"
                     placeholder="Url"/>
      {!props.update &&
        <ControlWrapper model=".cloneEnvironmentId"
                        label="Clone Existing Environment?"
                        placeholder="Do not clone"
                        component={Select}
                        validateOn={['blur','change']}
                        ignore={['blur']}
                        simpleValue={true}
                        searchable={false}
                        options={props.environments}

        />
      }
      <div style={{display: 'flex'}}>
        <label>Environment Color</label>
        <Control model=".color"
                 component={ButtonColorPicker}
                 style={{marginLeft: 10}}
                 errorMessages={{
                   isRequired: 'Color is required'
                 }}
        />
      </div>
      <div style={{marginTop: 10, paddingTop: 10, borderTop: '1px solid #eee'}}>
        <div style={{display: 'flex'}}>
          <p style={{flex: 1, fontWeight: 'bold'}}>
            Production Environment?
          </p>
          <div>
            <ToggleButton value={props.environment.production || false}
                          inactiveLabel="No"
                          activeLabel="Yes"
                          onToggle={()=>{
                            props.dispatch(actions.toggle('form.environment.production'))}
                          }
            />
          </div>
        </div>
      </div>
      <FormErrors form="environment"/>
    </Form>
  )
};

export const EnvironmentFormButtons = connect()((props: any): React.Element<any> => {
  return (
    <Control component={ActionButton}
             model="form.environment"
             type="submit"
             mapProps={{
                 disabled: props=>props.fieldValue.pending || !props.fieldValue.valid,
                 spinner: props=>props.fieldValue.pending
               }}
             bsStyle="success"
             onClick={()=>props.dispatch(actions.submit('form.environment'))}
    >
      { props.update ? 'Update Environment' : 'Create Environment' }
    </Control>
  );
})

function keyInputMask(model, value: string): Thunk {
    return (dispatch) => {
        let key = convertStringToKey(value); // value.trim().toLowerCase().replace(/[^A-Z0-9]+/g, '');
        dispatch(actions.change(model, key));
        if (key.length === 0){
            // Let the name dictate again!
            dispatch(actions.setPristine('form.environment.key'))
        }
    }
}

export default withFeatureflow()(connect(mapStateToProps)(EnvironmentForm));
