// @flow
import React from 'react';

import {FaRefresh} from 'react-icons/lib/fa'

import {isAdmin} from '../../../utils/authentication';
import BillingInfo from "./BillingInfo";
import CurrentPlan from "./CurrentPlan";
import BillingHistory from "./BillingHistory";
import {useFeatureflow} from 'react-featureflow-client'
import AvailableProducts from "./AvailableProducts";
import AvailablePrices from "./AvailablePrices";
import type {
    CardInfoType,
    InvoiceLineItem,
    Price,
    Subscription,
    BillingUsage, SubscribeToPrice, CancelSubscription
} from "../../../api/billing/types";
import LoadingOverlay from "../../../components/LoadingOverlay";
import {Col, Row} from "react-bootstrap";


type BillingViewProps = {
    info?: CardInfoType,
    prices?: Price[],
    usage?: BillingUsage,
    subscription: Subscription,
    invoices?: InvoiceLineItem[],
    updateCardDetails: () => any,
    subscribeToPrice: SubscribeToPrice,
    cancelSubscription: CancelSubscription
};

const BillingView = (props: BillingViewProps): React.Element<any> => {
    const {
        subscription,
        usage,
        invoices,
        info,
        prices,
        subscribeToPrice,
        cancelSubscription,
        updateCardDetails,
        updating
    } = props;
    const featureflow = useFeatureflow();

    if (!isAdmin()) {
        return (
            <div>
                <h2>Billing</h2>
                <div style={{textAlign: 'center', marginTop: 100, marginBottom: 100}}>
                    <p>Only organisation admins may manage billing. Please contact your administrator to update billing
                        details. </p>
                    <p>For details on pricing plans, please see <a href={"https://www.featureflow.io/pricing/"}
                                                                   target={"_blank"}>here</a></p>
                </div>
            </div>
        )
    }
    if (info && prices && usage && invoices && !updating) {
        return (
            <>
            <Row>
                <Col xs={12}>
                    <h2>Billing</h2>
                    <BillingInfo info={info} updateCardDetails={updateCardDetails}/>
                </Col>
            </Row>
            {featureflow.evaluate("pricing-plans").is("2") &&
                <AvailableProducts
                    currentPriceId={subscription.price.id}
                    currentProductId={subscription.price.id}
                    subscribeToPrice={subscribeToPrice}
                    cancelSubscription={cancelSubscription}
                    paidSeats={subscription.quantity}
                    usedSeats={ usage.seats }
                />
            }
            {!featureflow.evaluate("pricing-plans").is("2") &&
                <AvailablePrices
                    prices={prices}
                    currentPriceId={subscription.price.id}
                    subscribeToPrice={subscribeToPrice}
                    cancelSubscription={cancelSubscription}
                />
            }
            <Row>
                <CurrentPlan subscription={subscription} usage={usage}/>
            </Row>
            <Row>
                <BillingHistory invoices={invoices}/>
            </Row>
    </>
    )
    } else {
        return (
            <div>
                <h2>Billing</h2>
                <div style={{textAlign: 'center', marginTop: 100, marginBottom: 100}}>
                    <p>{updating ? 'Updating billing information...' : 'Retrieving billing information...'}</p>
                </div>
            </div>
        )
    }
}

export default BillingView;
