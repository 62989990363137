import * as React from 'react';
import type {Environment} from "../../api/environment";
import {Button} from "react-bootstrap";
import {bootstrapUtils} from "react-bootstrap/lib/utils";

type Props = {
    environment: Environment
}
function EnvironmentLink(props: Props) {
    bootstrapUtils.addStyle(Button, 'custom');
    const { environment } = props;
    const customButtonStyle = (
        <div>
            <style type="text/css">
                {`
                .btn-custom {
                    background-color: ${environment.color};
                    color: white;
                }
                `}
            </style>
            <Button bsStyle="link">{environment.name}</Button>
        </div>
        );
    return customButtonStyle;
}

export default EnvironmentLink;