import React from 'react';
import { LocalForm, Control } from 'react-redux-form';
import { FormControl, Button } from 'react-bootstrap';
import { convertStringToKey } from '../../utils/formParsers';

const CreateFeature = ({ featureKey, feature, featureFormValues, submitting, setFormValues, onCreateFeature }) => {
    const name = featureFormValues.name || '';

    return (
        <div>
            {!featureKey || !feature ? (
                <LocalForm
                    onChange={setFormValues}
                    onSubmit={onCreateFeature}
                >
                    <p>Create a feature name</p>
                    <Control
                        component={FormControl}
                        model=".name"
                        bsSize="sm"
                        placeholder="Name your feature..."
                        style={{ marginBottom: 10 }}
                    />

                    {name.length > 0 && (
                        <p>
                            Will create a featureKey{' '}
                            <span style={{ background: '#eee', padding: '0 5px' }}>
                {convertStringToKey(name)}
              </span>
                        </p>
                    )}

                    <Control
                        component={Button}
                        model="."
                        type="submit"
                        bsSize="sm"
                        disabled={name.length <= 0 || submitting}
                    >
                        {submitting ? 'Creating Feature' : 'Create Feature'}
                    </Control>
                </LocalForm>
            ) : (
                <p>
                    Feature created {feature.name} with key{' '}
                    <span style={{ background: '#eee', padding: '0 5px' }}>
            {feature.key}
          </span>
                </p>
            )}
        </div>
    );
};

export default CreateFeature;