// @flow
import React from 'react';
import access from 'safe-access';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit'

import { operatorValueToLabel } from '../../utils/rules';

import ControlWrapper from '../controls/ControlWrapper';

import Select from 'react-select';

import { actions } from 'react-redux-form';
import type {Target} from "../../api/project";

const selectProject = (state, props) => state.projects[props.params.projectKey];

const getTargets = createSelector(
  selectProject,
  project => project.targets
);

const getSelectedTarget = createSelector(
  (state, props) => props.baseModel,
  (state) => state.form.rules,
  (baseModel, rulesForm) => {
    return access(rulesForm, baseModel +'.target')
  }
);

const getTarget = createSelector(
  getTargets,
  getSelectedTarget,
  (targets, key) => targets.filter(target => target.key === key)[0]
)

function mapStateToProps(state, props){
  return {
    target: getTarget(state, props)
  }
}

type OperatorSelect = {
  params: any,
  baseModel: string,
  target: Target
}


export default withRouter(connect(mapStateToProps)(function(props: OperatorSelect): React.Element<any>{

  let { target } = props;
  let options = [];
  if (target){
    switch(target.type){
      case('segment'):
        options = options.concat([
          "in",
          "notIn"]);
        break;
      case('string'):
        options = options.concat([
          "contains",
          "equals",
          "startsWith",
          "endsWith",
          "matches",
          "in",
          "notIn"
        ]);
        break;
      case('date'):
        options = options.concat([
          "before",
          "after",
        ]);
        break;
      case('number'):
        options = options.concat([
          "equals",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual",
        ]);
        break;
      default:
    }
  }

  return <div style={{flex: 1, marginRight: 8}}>
            <ControlWrapper model=".operator"
                        placeholder="Matches"
                        component={Select}
                        validateOn={['blur', 'change']}
                        ignore={['blur']}
                        disabled={!target}
                        errorMessages={{
                          isRequired: 'Required'
                        }}
                        clearable={false}
                        changeAction={(model = '', value = {}) => {
                          return dispatch => {
                            const conditionModel = model.substr(0, model.lastIndexOf('.'))
                            dispatch(actions.merge(conditionModel, {
                              operator: value.value
                            }));
                          }
                        }}
                        options={options.map(opt => {
                          return {
                            label: operatorValueToLabel(opt),
                            value: opt
                          }
                        })}
            />
      </div>;
}))