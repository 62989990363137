// @flow
import type { Subscription } from '../api/subscription';

const PREFIX: string = "FF/SubscriptionOutline/";
const RESET: string = PREFIX + "RESET";
const SET_SUBSCRIPTION: string = PREFIX + "SET_SUBSCRIPTION";

const initialState: ?Subscription = null;

export default function (state: ? Subscription  = initialState, action: Action): ?Subscription {
  switch(action.type){
    case(SET_SUBSCRIPTION):
      return action.payload.subscription || null;
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setSubscription(subscription: Subscription): Action{
  return {
    type: SET_SUBSCRIPTION,
    payload: {
      subscription
    }
  }
}