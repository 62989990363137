// @flow
import React from 'react';
import ToggleButton from 'react-toggle-button';

export default function(props: any): React.Element<any>{
    return (
      <ToggleButton
        {...props}
        onToggle={(value)=>{
          props.onChange(!value);
        }}
        value={!!props.checked}
      />
    )
}