// @flow
import React from 'react';
import './Activate.scss';

import { actions, Form, Control } from 'react-redux-form';
import { connect } from 'react-redux';

import * as JWTService from '../utils/JWTService';
import { getSubdomain } from '../utils/api';

import { setToken } from '../redux/authentication';

import * as loginApi from '../api/login';
import * as userInvitesApi from '../api/userInvites';

import { FaRefresh } from 'react-icons/lib/fa';

import { goToRoute } from '../utils/routing';

import ControlWrapper from '../forms/controls/ControlWrapper';

import { Button } from 'react-bootstrap';

import ActionButton from '../forms/controls/ActionButton';

import PasswordStrength from '../components/PasswordStrength';

function verifyPasswordIsTheSame(props, otherPassword, model, value){
  props.dispatch(actions.change(model, value));
  if (value !== otherPassword && props.form.confirmPassword.touched){
    props.dispatch(actions.setErrors('form.invitedSignup.confirmPassword', {
      nomatch:'The two passwords do not match.'
    }));
  }
  else{
    props.dispatch(actions.setValidity('form.invitedSignup.confirmPassword', true));
  }
}

class InvitedSignup extends React.Component{
  state = {
    invitee: undefined,
    loading: true
  }
  constructor(props){
    super(props);
    props.dispatch(actions.reset('form.invitedSignup'));
    userInvitesApi.getInvitedUser(props.params.activationKey)
      .then(invitee=>{
        this.setState({
          invitee,
          loading: false
        });
        if (invitee){
          this.props.dispatch(actions.change('form.invitedSignup', {
            ...props.invitedSignup,
            firstName: invitee.firstName || '',
            lastName: invitee.lastName || ''
          }))
        }
      }).catch(err=>{
      this.setState({
        loading: false
      })
    })
  }

  submitForm(values){
    this.props.dispatch(actions.setPending('form.invitedSignup'));
    userInvitesApi.signupInvitedUser({
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      activationKey: this.props.params.activationKey
    }).then(response=>{
      if (this.state.invitee){
        return loginApi.authenticate({
          username: this.state.invitee.email,
          password: values.password,
          domain: getSubdomain(),
          rememberMe: true
        })
      }
    }).then(response=>{
      if (response){
        JWTService.setAuthToken(response.id_token);
        this.props.dispatch(setToken(response.id_token));
        goToRoute('/', true);
      }
    }).catch(err=>{
      this.props.dispatch(actions.setErrors('form.invitedSignup', {errors: err}))
    });
  }

  render(){
    if (this.state.loading){
      return (
        <div id="activate-container">
          <div id="activate">
            <div id="activate-box"  style={{textAlign: 'center'}}>
              <FaRefresh className="fa-spin" style={{fontSize: 100, margin: 50}}/>
              <h1>Retrieving your details</h1>
            </div>
          </div>
        </div>
      )
    }
    else{
      if (this.state.invitee){
        if (this.state.invitee.alreadyActivated){
          return this.renderAlreadyActivated();
        }
        else{
          return this.renderForm(this.state.invitee);
        }
      }
      else{
        return this.renderNotFound();
      }
    }
  }
  renderNotFound(){
    return (
      <div id="activate-container">
        <div id="activate">
          <div id="activate-box" style={{textAlign: 'center'}}>
            <h1>We cannot find you!</h1>
          </div>
        </div>
      </div>
    )
  }
  renderAlreadyActivated(){
    return (
      <div id="activate-container">
        <div id="activate">
          <div id="activate-box"  style={{textAlign: 'center'}}>
            <h1>It looks like you have already activated your account!</h1>
            <Button onClick={()=>goToRoute('/login')}>Log in Here</Button>
          </div>
        </div>
      </div>
    )
  }
  renderForm(invitee){
    let props = this.props;
    return (
      <div id="activate-container">
        <div id="activate">
          <div id="activate-box"  style={{textAlign: 'center'}}>
            <Form model="form.invitedSignup"
                  style={{textAlign: 'center'}}
                  onSubmit={this.submitForm.bind(this)}>
              <h2>Join the organisation for {invitee.organisationName}</h2>
              <ControlWrapper model="form.invitedSignup.firstName"
                             label="First Name"
                             errorMessages={{
                               required: 'Your first name is required'
                             }}
              />
              <ControlWrapper model="form.invitedSignup.lastName"
                             label="Last Name"
                             errorMessages={{
                               required: 'Your last name is required'
                             }}
              />
              <ControlWrapper model="form.invitedSignup.password"
                             type="password"
                             label="Create a Password"
                             errorMessages={{
                               passwordMinLength: 'Your password should be greater than 4 characters long'
                             }}
                             changeAction={ verifyPasswordIsTheSame.bind(this, props, props.invitedSignup.confirmPassword)}
              />
              <PasswordStrength password={props.invitedSignup.password} style={{marginTop: -16, marginBottom: 8}}/>
              <ControlWrapper model="form.invitedSignup.confirmPassword"
                             label="Confirm Password"
                             validateOn={['change']}
                             type="password"
                             changeAction={ verifyPasswordIsTheSame.bind(this, props, props.invitedSignup.password) }
              />
              <Control component={ActionButton}
                       model="form.invitedSignup"
                       type="submit"
                       bsStyle="primary"
                       mapProps={{
                         spinner: props=>props.fieldValue.pending,
                         disabled: props=>props.modelValue.password !== props.modelValue.confirmPassword || !props.fieldValue.valid || props.fieldValue.pending
                       }}
              >
                Signup
              </Control>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    form: state.form.forms.invitedSignup,
    invitedSignup: state.form.invitedSignup
  }
}

export default connect(mapStateToProps)(InvitedSignup);