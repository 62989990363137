// @flow
import React from 'react';
import './Login.scss';

import Logo from '../assets/images/logos/featureflow-logo-text-150x155.png';

import LoginForm from '../forms/LoginForm';
import SignupForm from '../forms/SignupForm';
import constants from '../config/constants';
import {getHostname, getSubdomain} from "../utils/api";

export default function Login(): React.Element<any>{
  return (
    <div id="login-container">
      <div id="login">
        <img src={Logo} alt="featureflow"/>
        <div>
          <h1>
            Release confidently.
          </h1>
          <h4>
            Build and release better software faster
          </h4>

        </div>
          <span className={'version'}>{constants.VERSION}</span>
      </div>
      {
        displaySidebarContent()
      }
    </div>
  )
}

function displaySidebarContent(): React.Element<any>{
  if (getSubdomain().toLowerCase() === 'app'){
    //this is the signup page
    return (
      <div id="sidebar">
        <div className="login-heading">
          <h4>
            It's simple to get started...
          </h4>
        </div>
        <SignupForm/>
      </div>
    );
  }
  else{
    return (
      <div id="sidebar">
        <div className="login-heading">
          <h4>Log in to {getHostname()}</h4>
        </div>
        <LoginForm/>
          <div>Not a member? <a href="https://app.featureflow.io">Sign up for free</a></div>

      </div>
    );
  }
}