// @flow
import React from 'react';

import {LinkContainer} from "react-router-bootstrap";

import image404 from '../assets/images/backgrounds/image404.png';
import {Button} from "react-bootstrap";

export type Props = {
    reason?: string
}
const PageNotFound = (props: Props) => {
    const { reason } = props;
    return (
        <div style={{textAlign: 'center', backgroundColor: '#FDFDFD',}}>
            <h1>Page not found.</h1>
            <h3>
                Sorry this might not be what you are looking for.
            </h3>
            <h4>
                {reason || ''}
                <LinkContainer to={{pathname: `/home`, search: "?query=bookmarked"}}>
                    <Button bsStyle="link">Head home</Button>
                </LinkContainer>
            </h4>


            <img src={image404} alt="Not what you were looking for" style={{maxWidth: '100%', maxHeight: '80vh'}}/>
        </div>
    )
}

export default PageNotFound;