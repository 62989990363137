// @flow
import constants from '../config/constants';
import parseDomain from 'parse-domain';
import access from 'safe-access';

const _hostname = access(window, 'location.hostname');
const _pathname = access(window, 'location.pathname');


export function getBaseUrl(){
  if (constants.BASE_URL){
    return constants.BASE_URL;
  }
  else{
    return '//' + access(window, 'location.hostname');
  }
}
export function getProtocol(): string {
  var arr = window.location.href.split("/");
  const protocol = arr ? arr[0] : "";
  return protocol;
}
export function getHostname(): string{
  return _hostname;
}

export function getSubdomain(): string{
  const { subdomain = '' } = parseDomain(access(window, 'location.hostname'), {customTlds:['localdev']}) || {};
  return subdomain;
}

export function getDomain(): string{
  const { domain, tld } = parseDomain(access(window, 'location.hostname'), {customTlds:['localdev']}) || {};
  return domain && tld ? (domain+'.'+tld) : '';
}

export const getOrganisationDomain = () => {
  return _pathname.split('/')[1] || "";
}

export function jsonToQueryString(json?: {[key: string]: any}) {
  if (!json){
    return '';
  }
  return '?' +
    Object.keys(json).map(function(key) {
      return encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key]);
    }).join('&');
}
