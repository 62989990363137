// @flow
import React from 'react';
import {Button, Col, Panel} from "react-bootstrap";
import CCIcon from "../../../components/CCIcon";

const BillingInfo = (props = {info: any, updateCardDetails: () => {}}) => {
    let { info, updateCardDetails } = props;
    return (
            <Panel>
                <Panel.Heading style={{fontSize: 18}}>Billing Information</Panel.Heading>
                <Panel.Body>
                    {info.lastFour
                        ?
                        <p>This account is billed to: <CCIcon brand={info.brand}/> <b>{info.brand} ending
                            in {info.lastFour}</b>
                        </p>
                        : <p>This account has no billing information</p>
                    }
                    <Button onClick={updateCardDetails.bind(this)} bsStyle="primary" bsSize="sm">
                        Update Credit Card
                    </Button>
                </Panel.Body>
            </Panel>
    )
}

export default BillingInfo;