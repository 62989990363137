// @flow
import api from './';

type AuthData = {
  username: string,
  password: string,
  token?: string,
  domain: string,
  rememberMe: boolean
}

export function authenticate(data: AuthData){
  return api('post', `/api/authenticate`, {
    data
  })
}