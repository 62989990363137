// @flow
import React from 'react';
import {Button, ButtonToolbar, Table} from "react-bootstrap";
import moment from "moment";
import {FaDownload} from "react-icons/lib/fa";

const BillingHistory = (props = { invoices }) => {
    const {invoices} = props;

    if (!invoices || !invoices.length) {
        return (
            <div>
                <h2>Billing History</h2>
                <p>No History</p>
            </div>
        );
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="page-header">
                        <h2 id="tables">Billing History</h2>
                    </div>

                    <div className="bs-component">
                        <Table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th style={{border: 0}}>Date</th>
                                <th style={{border: 0}}>Amount Paid</th>
                                <th style={{border: 0}} className="pull-right">PDF</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoices.map((invoice) => {
                                return (
                                    <tr key={invoice.id}>
                                        <td>{moment(invoice.date).format('MMMM Do YYYY')}</td>
                                        <td>{(invoice.total / 100).toFixed(2).toLocaleString(invoice.currency)}</td>
                                        <td>
                                            <ButtonToolbar className="pull-right">
                                                <Button bsStyle="primary" bsSize="xsmall" href={invoice.invoicePdf}>
                                                    Download <FaDownload/>
                                                </Button>
                                            </ButtonToolbar>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingHistory;
