// @flow
import React from 'react';

import { Link } from 'react-router';

export default function(props: any): React.Element<any>{
    return (
      <Link to="/admin/billing">
        {props.text || 'Upgrade your plan'}
      </Link>
    )
}