// @flow
import api from './';
import type { Feature } from './feature';

export type ExperimentPost = {
  id?: string,
  name: string,
  description: string,
  enabled: boolean,
  goalKey: string,
  featureId: string,
  featureKey: string,
  environmentId: string,
  control: string,
  variation: string
}

export type Experiment = ExperimentPost & {
  startDate: any,
  endDate: any,
  feature: Feature

}

export function get(){
  return api('get', `/api/v1/experiments`)
}

export function update(data: Experiment){
  return api(data.id ? 'put' : 'post', `/api/v1/experiments`, {
    data
  })
}

export function remove(experimentId: string){
  return api('delete', `/api/v1/experiments/${experimentId}`);
}


export function getStatistics(experimentId: string){
  return api('get', `/api/v1/experiment-statistics/${experimentId}`)
}

type TimeSeriesQuery = {
  from: Date,
  to: Date,
  metrics: string[]
}

export function getTimeseries(experimentId: string, query: TimeSeriesQuery){
  let { from, to, metrics } = query;
  return api('get', `/api/v1/experiment-timeseries/${experimentId}`
    + `?from=${from.toISOString()}`
    + `&to=${to.toISOString()}`
    + `&${metrics.map(metric=>`metric=${metric}`).join('&')}`);
}