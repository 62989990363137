import React from 'react';

function Maintainer(props) {
    const { maintainer } = props;
    if(!maintainer){
        return <span/>;
    }
    if (maintainer.email) {
        return (
            <h4 style={{marginTop: 4}}>Maintainer: <a href={'mailto:' + props.maintainer.email + '?subject=Regarding the ' + props.featureName + ' (' + props.featureKey + ') feature in featureflow'}>{props.maintainer.name}</a></h4>
        )
    }
    if (maintainer.name) {
        return (
            <h4>Maintainer: {props.maintainer.name}</h4>
        )
    }
    return (<span/>)

}

export default Maintainer;