import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'

import application from './application';
import authentication from './authentication';
import environments from './environments';
import features from './features';
import projects from './projects';
import integrations from './integrations';
import featureControls from './featureControls';
import experiments from './experiments';
import principalUserOutline from './principalUserOutline';
import organisation from './organisation';
import subscriptionOutline from './subscriptionOutline';
import token from './token';
import modalController from './modalController';
import form from './form/index';
import Alert from 'react-s-alert';
//apis
import {featureflowApi} from '../api/featureflowApi';

import {isFulfilled, isRejected, isRejectedWithValue, isAsyncThunkAction} from '@reduxjs/toolkit'

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!

    // if(isFulfilled(action) && `${action.type}`.includes('executeMutation')){
    //   const name = action.payload ? (action.payload.name ? action.payload.name : action.payload.key) : '';
    //   const method = action.meta.baseQueryMeta.request.method;
    //   const type = method === 'DELETE'
    //    ? 'deleted' : method === 'PUT' ? 'updated' : 'created';
    //   Alert.success(`${name} ${type}`, { timeout: 1000});
    // };


    if (action.error && action.error.message === 'Failed to fetch') {
        Alert.error('We could not contact the server, please try again.');
    }
    if (isRejectedWithValue(action)) {
        if(action.payload && action.payload.originalStatus === 504){
            Alert.error('We lost the connection, please try again.');
        } /*else{
            const error =
                action.error.data ?
                    action.error.data.message
                    :
                    action.error.message ?
                        action.error.message
                        :
                        "We encountered a problem"
            Alert.error(error);
        }*/
    }

    return next(action);
}

const store = configureStore({
    reducer: {
        //Note the state names here are implied by the imported reducer name by convention
        application,
        authentication,
        environments,
        features,
        featureControls,
        experiments,
        projects: projects,
        integrations,
        principalUserOutline,
        organisation,
        subscriptionOutline,
        token,
        modalController,
        form,
        [featureflowApi.reducerPath]: featureflowApi.reducer, //see https://redux-toolkit.js.org/rtk-query/overview
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(rtkQueryErrorLogger)
            .concat(featureflowApi.middleware),

})

setupListeners(store.dispatch)

export const dispatch = store.dispatch;

export default store;
