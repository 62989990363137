// @flow
import api from './';

export type Segment = {
  key: string,
  name: string,
  description: string,
  archived?: boolean,
  tags: string[],
  created: Date,
  updated: Date,
  rules: SegmentRule[]
}

export type SegmentRule = {
  id: string,
  conditions: Condition[]
}

export type Condition = {
  target: string,
  operator: string,
  values: mixed[]
}
export const getSegments = async (projectKey: string, environmentKey: string, target: string, query: string = ''): Promise<Segment[]> => {
  return api('get', `/api/v2/segments/${projectKey}/${environmentKey}?query=${query}`)
}
