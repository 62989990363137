// @flow
import * as reduxUtils from "../utils/redux";

import * as integrationsApi from '../api/integrations';
//Just fucking tons of boilerplate code for doing some bog standard crud operations. The future.
type IntegrationsState = {[key: string]: any};
const PREFIX: string = "FF/Integrations/";
const RESET: string = PREFIX + "RESET";
const SET_INTEGRATIONS: string = PREFIX + "SET_INTEGRATIONS";
const REMOVE_INTEGRATION: string = PREFIX + "REMOVE_INTEGRATION";

export const FETCH_INTEGRATIONS_BEGIN   = 'FETCH_INTEGRATIONS_BEGIN';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';
export const FETCH_INTEGRATIONS_FAILURE = 'FETCH_INTEGRATIONS_FAILURE';


const initialState: IntegrationsState = {};

//this means that the state is of type IntegrationsState
export default function (state: ?IntegrationsState=initialState, action: Action): ?IntegrationsState{
  switch(action.type){
    case(SET_INTEGRATIONS):
        return {
            ...state,
            ...reduxUtils.reduceArrayToMap(action.payload.integrations)
        };
      case (REMOVE_INTEGRATION):
          return reduxUtils.remove(action.payload.integrationId, state);
      case(RESET):
          return initialState;
      default:
          return state;
  }
}

export function setIntegrations(integrations: any[]): Action{
  return {
    type: SET_INTEGRATIONS,
    payload: {
      integrations
    }
  }
}

export function removeIntegration(integrationId: string): Action{
    return {
        type: REMOVE_INTEGRATION,
        payload:{
            integrationId
        }
    }
}

export function reset(): Action{
    return {
        type: RESET,
        payload: {}
    }
}

//Thunks
export function doSetIntegrationEnabled(integration: any, enabled: boolean): Thunk{
    return (dispatch)=>{
        return integrationsApi.updateIntegration({
            ...integration,
            enabled
        })
            .then(response=>{
                dispatch(setIntegrations([response]));
                return Promise.resolve(response);
            })
    }
}

export function doUpdateIntegration(integration: any): Thunk{
    return (dispatch)=>{
        return integrationsApi.updateIntegration(integration)
            .then(response=>{
                dispatch(setIntegrations([response]));
                return Promise.resolve(response);
            })
    }
}

export function doGetIntegrations(): Thunk{
    return (dispatch)=>{
        return integrationsApi.getIntegrations()
            .then(response=>{
                dispatch(setIntegrations(response));
                return Promise.resolve(response);
            }).catch(err=>{
                return Promise.reject(err);
            })
    }
}

export function doRemoveIntegration(integrationId: any): Thunk{
    return (dispatch)=>{
        return integrationsApi.removeIntegration(integrationId)
            .then(response=>{
                dispatch(removeIntegration(integrationId));
                return Promise.resolve(response);
            })
            .catch(err=>{
                return Promise.reject(err);
            })
    }
}