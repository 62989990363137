//@flow

import {goToRoute} from '../utils/routing';
import Alert from 'react-s-alert';

import access from 'safe-access';

import store from '../redux';


export function reauthenticate(){
    goToRoute('/logout');
}

function testRole(role: string, user: ?any){
    var roles = (access(store.getState(), 'principalUserOutline.authorities') || []);
    return roles.indexOf(role) >= 0;
}

export function isAdmin(){
  return testRole('ROLE_ORGANISATION_ADMIN');
}

export function isProdUser(){
  return testRole('ROLE_ORGANISATION_PROD_EDITOR') || testRole('ROLE_ORGANISATION_ADMIN');
}
export function isReadOnlyUser(){
    return testRole('ROLE_ORGANISATION_READONLY_USER');
}
export function restrictUser(action: string, resource: string, tags: string[], attributes: string[], callback: any){
    if(isAllowed(action, resource, tags, attributes)){
        callback();
    }else{
        Alert.error('Your user role restricts you from making changes.');
    }

}

/**
 * Cna the user perform the action.?
 * @param action 'create,read,update,delete' mostly
 * @param resource the resource identifier
 * @param tags - user assigned tags
 * @param attributes - internal attributes 'production'
 * @returns {boolean}
 */
export function isAllowed(action: string, resource: string, tags: string[], attributes: string[]){
    //var roles = (access(store.getState(), 'principalUserOutline.authorities') || []);

    //this is to cater for basic roles
    if (['update', 'create', 'delete'].indexOf(action) >= 0 && attributes.indexOf('production') > -1 && !isProdUser()) {          //just check for production anywhere in the resource for now
        return false;
    }
    else if(isReadOnlyUser()){
        return false;
    }
    else{
        return true;
    }
    /*  if (props.environment.production && !isProdUser()){
      Alert.error('Your user role restricts you from making changes in a production environment');
  }
  else if(isReadOnlyUser()) {
      Alert.error('Your read-only user role restricts you from making changes.');
  }
  else{
      callback();
  }*/
}