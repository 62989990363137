//@flow
import React from 'react';
import { modal } from './BasicModal';

export const info = <div>
  <p>
    Local environments can only be seen by you. You should use local environments to the targeting for features you are developing locally.
    The features you create in local environments are not shared, so it is safe for you to develop with them.
  </p>
</div>;

export function show(){
  modal('Things to know about local environments', info)
}
