// @flow

import * as userApi from '../api/user';
import type {PrincipalUserOutline, UserSettings} from "../api/user";


const PREFIX: string = "FF/PrincipalUserOutline/";
const RESET: string = PREFIX + "RESET";
const SET_PRINCIPAL_USER: string = PREFIX + "SET_PRINCIPAL_USER";
const SET_USER_SETTINGS: string = PREFIX + "SET_USER_SETTINGS";

const initialState: ?PrincipalUserOutline = null;

export default function (state: ?PrincipalUserOutline=initialState, action: Action): ?PrincipalUserOutline{
  switch(action.type){
    case(SET_PRINCIPAL_USER):
      return action.payload.principalUser || null;
    case(SET_USER_SETTINGS):
      if (state){
        return {
          ...state,
          settings: action.payload.settings
        }
      }
      else{
        return state;
      }
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setPrincipalUser(principalUser: PrincipalUserOutline): Action{
  return {
    type: SET_PRINCIPAL_USER,
    payload: {
      principalUser
    }
  }
}

export function setUserSettings(settings: UserSettings): Action{
  return {
    type: SET_USER_SETTINGS,
    payload: {
      settings
    }
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}

// Thunks

export function markIntroRead(id: string): Thunk{
  return function(dispatch){
    return userApi.markIntroAsRead(id).then(settings=>{
      dispatch(setUserSettings(settings));
    });
  }
}