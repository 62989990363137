// @flow
import React from 'react';

type Modal = {
  title: string | React.Element<any>,
  body: React.Element<any>,
  footer?: React.Element<any>,
  closeButton: boolean,
  static?: boolean
};

type ModalControllerState = {
  show: number,
  modals: Modal[]
};
const PREFIX: string = "FF/ModalController/";
const SHOW: string = PREFIX + "SHOW";
const CLOSE: string = PREFIX + "CLOSE";
const CLOSE_ALL: string = PREFIX + "CLOSE_ALL";
const RESET: string = PREFIX + "RESET";

const initialState: ModalControllerState = {
  show: 0,
  modals: []
};

export default function (state: ModalControllerState=initialState, action: Action): ModalControllerState{
  switch(action.type){
    case(SHOW):
      return {
        show: state.show + 1,
        modals: state.modals.slice(0, state.show).concat(action.payload.modal)
      };
    case(CLOSE):
      return {
        ...state,
        show: Math.max(state.show - 1, 0)
      }
    case(CLOSE_ALL):
      return {
        ...state,
        show: 0
      };
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function show(modal: Modal): Action{
  return {
    type: SHOW,
    payload: {
      modal: {
        static: false,
        key: Math.random().toString(36).substr(2, 5),
        ...modal
      }
    }
  }
}

export function close(): Action{
  return {
    type: CLOSE,
    payload: {}
  }
}

export function closeAll(): Action{
  return {
    type: CLOSE_ALL,
    payload: {}
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}