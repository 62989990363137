import * as React from 'react';
import type { Feature } from "../../api/feature";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import Alert from "react-s-alert";
import { LinkContainer } from "react-router-bootstrap";


type Props = {
    feature: Feature,
}

function FeatureSummaryRow(props: Props) {
    const { feature } = props;    
    FeatureSummaryRow.defaultProps = {
        feature: {},
    };
    let featureDetailsTooltip = <Tooltip id={feature.id + 'details'}>Show Details for {feature.key}</Tooltip>;
    const path = `/projects/${feature.projectKey}/features/${feature.key}`;
    return <tr>
        <td>
            <CopyToClipboard text={feature.key}
                             onCopy={() => Alert.success(`Copied <b>${feature.key}</b> to clipboard`, {html: true})}>
                        <span style={{cursor: 'pointer'}}>
                            {feature.key}
                        </span>
            </CopyToClipboard>
        </td>
        <td>
            <OverlayTrigger
                overlay={featureDetailsTooltip} placement="top"
                delayShow={300} delayHide={150}
            >
                <LinkContainer to={{pathname: `${path}`}}>
                    <Button href={path} bsStyle="link" style={{padding: '5px 0'}}>
                        {feature.name}
                    </Button>
                </LinkContainer>

            </OverlayTrigger>
        </td>
        <td>
          {feature.description || ''}
        </td>
        <td>
            {feature.project.name}
        </td>
        <td>
            {feature.maintainer ? feature.maintainer.name : ''}
        </td>
        <td className="text-right">
        </td>
    </tr>
}

export default FeatureSummaryRow;