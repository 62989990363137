// @flow
const initialState = {
  id: undefined,
  name: '',
  description: '',
  enabled: false,
  goalKey: '',
  featureId: '',
  featureKey: '',
  environmentId: '',
  control: '',
  variation: ''
};
export default initialState;