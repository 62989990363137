// @flow
import React from 'react';
import Logo from '../assets/images/logos/logo-white.png';

export default function(): React.Element<any>{
  return (
    <div className="loading-page">
      <img className="logo" alt="FeatureFlow" src={Logo}/>
      <div className="loading-text">
        Loading
      </div>
    </div>
  )
};