// @flow
import React from 'react';

import * as modalController from '../redux/modalController';

import FeatureForm, {FeatureFormButtons} from '../forms/FeatureForm';

import {actions} from 'react-redux-form';

import {dispatch} from '../redux';

import {getInitialVariants} from '../redux/form/feature';

import DismissableAlert from '../components/DismissableAlert';

import { useCreateFeatureMutation, useUpdateFeatureMutation, useDeleteFeatureMutation } from '../api/featureflowApi';


import Alert from 'react-s-alert';
import {confirmModal} from "./ConfirmModal";

const ModalBody = (props): React.Element<any> => {
    const { feature } = props;
    const [createFeature] = useCreateFeatureMutation();
    const [updateFeature] = useUpdateFeatureMutation();

    const doUpdateFeature = (values) => {
        const featurePayload = {
            ...feature,
            ...values,
            tags: (values.tags || []).map(tag => tag.label),
            jiraIssueKeys: (values.jiraIssueKeys || []).map(jiraIssueKey => jiraIssueKey.label),
            maintainer: (values.maintainer ? { id: values.maintainer.value, name: values.maintainer.label } : {})
        };
        if(feature.id){
            updateFeature(featurePayload);
        }else{
            createFeature(featurePayload);
        }
    }
    const doOnSubmit = (values) => {
        const update = values.id ? 'Update' : 'Create';
        confirmModal(
            <div>
                {update} feature
            </div>,

            <div>
                {values.id && <p>Feature settings apply across all environments.</p>}
                <p>Are you sure you wish to {update} '{values.name}'?</p>
            </div>,
            [
                {
                    text: 'Cancel',
                    value: false,
                    style: 'default'
                },
                {
                    text: `${update} feature`,
                    value: true,
                    style: 'primary'
                }
            ]
        ).then(response => {
            if (response) {
                doUpdateFeature(values);
                Alert.success(`${values.name} saved.`);
                dispatch(modalController.closeAll());
            }else{
                dispatch(actions.setSubmitFailed('form.feature'));
            }
        });
    }

    return (
        <>
            {!feature && <DismissableAlert>
                A feature is a manageable item in your application.<br/>
                The name is for display purposes only and the key is used by your developers to identify the feature
                in the application.<br/>
                To make a feature manageable you need to insert a small snippet of code into your application.<br/>
                Please check the <a href="http://docs.featureflow.io" target="_blank"
                                    rel="noopener noreferrer">docs</a> for how to do this.
            </DismissableAlert>}
            <FeatureForm update={feature && feature.id}
                onSubmit={doOnSubmit}
            />
        </>)
}

export function updateFeatureModal(props?: any, feature?: any): Promise<any> {
    const { doAfterDelete } = props;
    return new Promise((resolve, reject) => {
        //Must reset the form before we show it.
        dispatch(actions.reset('form.feature')); // no idea why I have to do both but I do....
        dispatch(actions.setInitial('form.feature'));
        dispatch(actions.reset('form.feature'));
        dispatch(actions.change('form.feature.variants', getInitialVariants()));

        if (feature && feature.id) { //if we are editing
            let {id, name, key, description, variants, permanent, tags, jiraIssues, projectKey, maintainer} = feature;
            permanent = permanent || false;
            tags = (tags || []).map((tag, index) => {
                return {
                    value: tag,
                    label: tag
                }
            });
            if(maintainer){
                maintainer = {
                    value: maintainer.id,
                    label: maintainer.name
                };
            }else{
                maintainer = {
                    value: '',
                    label: ''
                };
            }
            let jiraIssueKeys = (jiraIssues || []).map((jiraIssue, index) => {
                return {
                    value: jiraIssue.key,
                    label: jiraIssue.title
                }
            });
            dispatch(actions.load('form.feature', {
                id,
                name,
                key,
                description,
                variants,
                permanent,
                tags,
                jiraIssueKeys,
                projectKey,
                maintainer
            }))
        }

        dispatch(modalController.show({
            title: feature && feature.id ? 'Update Feature' : 'Create Feature',
            bsSize: 'large',
            body: <ModalBody feature={feature} />,
            footer: <FeatureFormButtons update={feature && feature.id ? true : false}
                        feature={feature}
                        disabled={false}
                        doAfterDelete={doAfterDelete}
                        onCancel={() => dispatch(modalController.close())}
                    />,
            closeButton: true,
            static: true,
        }));
    })

}