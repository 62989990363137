// @flow
import React from 'react';
import './Login.scss';
import Logo from '../assets/images/logos/featureflow-logo-text-150x155.png';
import SignupForm from '../forms/SignupForm';
import constants from '../config/constants';

export default function Login(): React.Element<any>{
  return (
    <div id="login-container">
      <div id="login">
        <img src={Logo} alt="featureflow"/>
        <div>
          <h1>
            Software delivery should be enjoyable {':)'}
          </h1>
          <h4>
            Featureflow keeps the business in control and makes champions of developers.
          </h4>

        </div>
          <span className={'version'}>{constants.VERSION}</span>
      </div>
      {
        displaySidebarContent()
      }
    </div>
  )
}

function displaySidebarContent(): React.Element<any>{
    return (
      <div id="sidebar">
        <div className="login-heading">
          <h4>
            It's simple to get started...
          </h4>
        </div>
        <SignupForm/>
      </div>
    );
}