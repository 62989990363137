// @flow
import React from 'react';

import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import * as formValidators from '../../utils/formValidators';

import { Control, Errors } from 'react-redux-form';

import ErrorContainer from './ErrorContainer';

function getValidatorTests(messages: {[key: string]: mixed} = {}){
  return Object.keys(messages).reduce((obj, next)=>{
    if (formValidators[next]){
      obj[next] = formValidators[next]
    }
    return obj;
  }, {});
}

function showError(formField){
  return formField && formField.touched && !formField.valid;
}

type ControlWrapperProps = {
  model: string,
  type?: string,
  placeholder?: string,
  errorMessages?: {[key:string]: mixed},
  changeAction: ()=>Thunk,
  parser?: (string)=>string,
  validateOn?: string[],
  updateOn?: string[],
  disabled?: boolean,
  componentClass?: 'textarea'|'select',
  label?: string,
  style?: any,
  children?: React.Element<any>[],
  component?: React.Component<*,*,*>,
  controlProps?: any,
  onChange?: ()=>any,
  changeAction?: ()=>any,
  mapProps?: {[key:string]: (props:any)=>any}
};

function InputControl(props: any){
  const {
    hasErrors,
    inputComponent,
    style,
    label,
    formControlStyle,
    errorsComponent,
    ...formControlProps
  } = props;
  return (
    <FormGroup validationState={hasErrors ? 'error' : null}
               style={style}
               bsSize="small">
      {label && <ControlLabel>{label}</ControlLabel>}
      {React.createElement(inputComponent || FormControl, {
        bsSize:'small',
        style: formControlStyle,
        ...formControlProps
      })}
      <ErrorContainer>
        {hasErrors && errorsComponent}
      </ErrorContainer>
    </FormGroup>
  )
}

const ControlWrapper = (props: ControlWrapperProps): React.Element<any> => {
  const {
    model,
    component,
    validateOn,
    updateOn,
    parser,
    controlProps,
    componentClass,
    errorMessages,
    label,
    disabled,
    mapProps,
    ...otherProps
  } = props;
  return (
    <Control  component={InputControl}
              inputComponent={component}
              model={model}
              validateOn={validateOn || ['blur', 'change']}
              updateOn={updateOn || ['blur', 'change']}
              validators={getValidatorTests(errorMessages)}
              mapProps={{
                hasErrors: props => showError(props.fieldValue),
                ...mapProps
              }}
              parser={parser}
              componentClass={componentClass}
              controlProps={controlProps}
              errorsComponent={
                <Errors model={model}
                        messages={errorMessages}
                        wrapper={props => <div>{props.children[0]}</div>}
                />
              }
              persist={false}
              disabled={!!disabled}
              label={label}
              {...otherProps}
    />
  )
};

export default ControlWrapper;