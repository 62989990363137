// @flow
import * as reduxUtils from '../utils/redux';
import * as tokenApi from '../api/token';

type TokensState = {[id: string]: any};
const PREFIX: string = "FF/Tokens/";
const RESET: string = PREFIX + "RESET";
const SET_TOKENS: string = PREFIX + "SET_TOKENS";
const REMOVE_TOKEN: string = PREFIX + "REMOVE_TOKEN";

const initialState: TokensState = {};

export default function (state: TokensState=initialState, action: Action): TokensState{
  switch(action.type){
    case(SET_TOKENS):
      return {
        ...state,
        ...reduxUtils.reduceArrayToMap(action.payload.tokens)
      };
    case(REMOVE_TOKEN):
      return {
        ...state,
        ...reduxUtils.archive(action.payload.tokenId, state)
      }
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setTokens(tokens: any[]): Action{
  return {
    type: SET_TOKENS,
    payload:{
      tokens
    }
  }
}

export function removeToken(tokenId: string): Action{
  return {
    type: REMOVE_TOKEN,
    payload:{
      tokenId
    }
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}


//Thunks
export function doCreateToken(projectKey: string, token: any): Thunk{
  return (dispatch)=>{
    return tokenApi.create(projectKey, {
      ...token
    }).then(response=>{
      dispatch(setTokens([response]));
      return Promise.resolve(response);
    }).catch(err=>{
      return Promise.reject(err);
    })
  }
}

export function doRemoveToken(tokenId: string): Thunk{
  return (dispatch)=>{
    return tokenApi.remove(tokenId)
        .then(response=>{
          dispatch(removeToken(tokenId));
          return Promise.resolve(response);
        })
        .catch(err=>{
          return Promise.reject(err);
        })
  }
}