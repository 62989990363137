// @flow
import React from 'react';

import * as modalController from '../redux/modalController';
import RequestResetPasswordForm, { RequestResetPasswordFormButtons } from '../forms/RequestResetPasswordForm';

import { actions } from 'react-redux-form';

import * as accountApi from '../api/account';


import { dispatch } from '../redux';

export function requestResetPasswordModal(): Promise<any>{
  return new Promise((resolve, reject)=>{
    //Must reset the form before we show it.
    dispatch(actions.reset('form.requestResetPassword'));
    dispatch(actions.setInitial('form.requestResetPassword'));

    dispatch(modalController.show({
      title: 'Reset password!',
      body: <RequestResetPasswordForm onSubmit={(values)=>{
                                    return accountApi.requestResetPassword(values.email)
                                      .then(success=>{
                                        dispatch(actions.setSubmitted('form.requestResetPassword'));
                                      })
                                      .catch(err=>{
                                        let errorMessage = "If your email exists for this organisation a password reset email will be sent. If you do not receive an email please contact your administrator";
                                        dispatch(actions.setSubmitFailed('form.requestResetPassword'));
                                        dispatch(actions.setErrors('form.requestResetPassword', {serverError: errorMessage}));
                                        return reject(err);
                                      });
                                    }}/>,
      footer: <RequestResetPasswordFormButtons onClose={()=>{dispatch(modalController.close())}}/>,
      closeButton: true,
      static: true,
    }));
  })

}