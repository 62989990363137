// @flow
import api from './';


/*
type RoleType = {
  id: string,
  name: string
}
*/

export function get(){
  return api('get', `/api/v1/roles`)
}

export function update(data: UserType){
  return api('put', `/api/v1/roles`, {
    data
  })
}

export function deleteRole(id: string){
  return api('delete', `/api/v1/roles/${id}`)
}