// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { createSelector } from '@reduxjs/toolkit'


import ControlWrapper from './controls/ControlWrapper';
import { Button, Alert } from 'react-bootstrap';
import ActionButton from './controls/ActionButton';
import { Control, actions, Errors } from 'react-redux-form';

import ErrorContainer from './controls/ErrorContainer';

const selectRequestResetPasswordStatusForm = (state) => state.form.forms.requestResetPassword.$form;

const getFormPending = createSelector(
  selectRequestResetPasswordStatusForm,
  form=>form.pending
);

const getFormSubmitted = createSelector(
  selectRequestResetPasswordStatusForm,
  form=>form.submitted
)

function mapStateToProps(state){
  return {
    pending: getFormPending(state),
    submitted: getFormSubmitted(state)
  }
}
export default connect(mapStateToProps)(function(props: any):React.Element<any>{
  return (
    <Form model="form.requestResetPassword" onSubmit={props.onSubmit}>
      <p>
        Enter your email below and we'll send you an email with details on how to reset your password.
      </p>
      <ControlWrapper model=".email"
                     type="text"
                     label="Email"
                     disabled={props.submitted}
                     placeholder="Email"
                     errorMessages={{
                       isEmail: 'Must be a valid email address'
                     }}
      />
      {
        props.submitted
          ? <Alert bsStyle="success">An email has been sent with details to reset your password.</Alert>
          : <ErrorContainer>
              <Errors model="."/>
            </ErrorContainer>
      }
    </Form>
  )
})

export const RequestResetPasswordFormButtons = connect(mapStateToProps)((props: any): React.Element<any> => {
  if (props.submitted){
    return (
      <Button onClick={props.onClose.bind(this)}>
        Close
      </Button>
    );
  }
  else{
    return (
      <Control component={ActionButton}
               model="form.requestResetPassword"
               type="submit"
               mapProps={{
                 disabled: props=>props.fieldValue.pending,
                 spinner: props=>props.fieldValue.pending
               }}
               bsStyle="success"
               onClick={()=>props.dispatch(actions.submit('form.requestResetPassword'))}
      >
        Send
      </Control>
    );
  }
});