import React, { useEffect } from "react";

import {Col, Row} from "react-bootstrap";
import {dispatch} from "../../redux";
import {actions} from "react-redux-form";
import RulesForm, {mapApiStructureToForm, mapFormToApiStructure, RulesFormButtons} from "../../forms/RulesForm";
import type {Environment} from "../../api/environment";
import type {Feature, FeatureControl} from "../../api/feature";
import { useUpdateFeatureControlMutation } from '../../api/featureflowApi';
import Alert from "react-s-alert";
import {confirmModal} from "../../modals/ConfirmModal";

type Props = {
    environment: Environment,
    feature: Feature,
    featureControl: FeatureControl,
    variants: any
}
export const FeatureTargetingTab = (props: Props) => {
    const { environment, feature, featureControl, variants } = props;
    const [updateFeatureControl, { isSuccess: updateFeatureIsSuccess, isError: updateFeatureIsError, error: updateFeatureError }] = useUpdateFeatureControlMutation();

    useEffect(() => {
        if(updateFeatureIsError){
            Alert.warning(updateFeatureError?.data?.title || '');
            dispatch(actions.setSubmitFailed('form.rules'));
        }
    }, [updateFeatureIsError]);

    useEffect(() => {
        if(updateFeatureIsSuccess){
            Alert.success(`${feature.name} targeting for ${environment.name} saved.`);
        }
    }, [updateFeatureIsSuccess]);

    useEffect(() => {
        if(featureControl){
            loadRulesForm();
        }
    }, [feature, environment]);

    const loadRulesForm = () => {
        dispatch(actions.reset('form.rules'));
        dispatch(actions.load('form.rules', mapApiStructureToForm(featureControl.enabled, featureControl.rules, featureControl.offVariantKey)));
    }

    const doUpdateFeatureTargeting = (values) => {
        updateFeatureControl({...featureControl, ...mapFormToApiStructure(values, feature.variants)});
    }
    const doOnSubmit = (values) => {
        //get diffs here
        if (!environment.production) {
            doUpdateFeatureTargeting(values);
        } else {
            confirmModal(
                <div>
                    Updating feature in a production environment
                </div>,
                <p>
                    Warning, this is a production environment.<br/><br/>
                    Are you sure you wish to update '{feature.name}' in {environment.name}?
                </p>
            ).then(response => {
                if (response) {
                    doUpdateFeatureTargeting(values);
                }else{
                    dispatch(actions.setSubmitFailed('form.rules'));
                }
            });
        }
    }
    return <>
        <Row>
            <Col sm={12}>
                <h3>Targeting for {environment.name || ''}</h3>
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={8}>
                <RulesForm variants={variants} onSubmit={doOnSubmit} />
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={8}>
                <RulesFormButtons onCancel={() => loadRulesForm()} />
            </Col>
        </Row>

    </>
}