// @flow
import { combineForms } from 'react-redux-form';
import login from './login';
import signup from './signup';
import invitedSignup from './invitedSignup';
import environment from './environment';
import feature from './feature';
import project from './project';
import experiment from './experiment';
import variant from './variant';
import rules from './rules';
import findOrg from './findOrg';
import requestResetPassword from './requestResetPassword';
import resetPassword from './resetPassword';
import filters from './filters';
import userInvites from './userInvites';
import user from './user';
import target from './target';


export default combineForms({
  login,
  signup,
  invitedSignup,
  environment,
  feature,
  project,
  experiment,
  variant,
  rules,
  findOrg,
  requestResetPassword,
  resetPassword,
  userInvites,
  user,
  target,
  filters
}, 'form');