// @flow
import React, { useState, useEffect } from 'react';

import console from '../../utils/debug';
import {numberLessThanLimit} from '../../utils/limits';

import {FaEdit, FaTrash, FaKey, FaPlusCircle, FaLaptop} from 'react-icons/lib/fa';
import Col from 'react-bootstrap/lib/Col';

import {
    ListGroup,
    ListGroupItem,
    ButtonToolbar,
    Button,
    Tooltip,
    OverlayTrigger,
    DropdownButton,
    MenuItem,
    Row, Grid, Alert
} from 'react-bootstrap';
import {default as AlertToast} from "react-s-alert";

import {modal} from '../../modals/BasicModal';

import {confirmModal} from '../../modals/ConfirmModal';
import {updateProjectModal} from '../../modals/ProjectFormModal';
import {updateEnvironmentModal} from '../../modals/EnvironmentFormModal';
import * as modalController from '../../redux/modalController';

import ApiKeyCopyField from '../../components/ApiKeyCopyField';
import UpgradeLink from '../../components/UpgradeLink';

import {dispatch} from '../../redux';

import {useFeatureflow} from 'react-featureflow-client';
import {isAdmin} from "../../utils/authentication";

import { useDeleteEnvironmentMutation, useDeleteProjectMutation } from '../../api/featureflowApi';
import type { Project } from '../../api/project';
import {goToRoute} from "../../utils/routing";

export type Props = {
    projects: Project[],
    limits: any,
    environmentsCount: number
}

const Projects = (props: Props) => {
    const featureflow = useFeatureflow();
    let {projects, limits, environmentsCount, selectedProject} = props;

    const initial = selectedProject ? selectedProject : props.projects.length > 0 ? props.projects[0].key : undefined;
    const [currentProject, setCurrentProject] = useState<string>(initial);
    
    useEffect(() => {
        setCurrentProject(selectedProject)
    }, [selectedProject]);
    
    const project: Project = 
        projects.length > 0 &&  projects.filter(project => project.key === currentProject)[0] 
        ?
        projects.filter(project => project.key === currentProject)[0] 
        : 
        projects[0];
        
    const [deleteEnvironment, { isLoading: deleteEnvironmentIsLoading, isSuccess: deleteEnvironmentIsSuccess, isError: deleteEnvironmentIsError, error: deleteEnvironmentError }] = useDeleteEnvironmentMutation();
    const [deleteProject, { isLoading: deleteProjectIsLoading, isSuccess: deleteProjectIsSuccess, isError: deleteProjectIsError, error: deleteProjectError }] = useDeleteProjectMutation();
    
    useEffect(() => {
        if(deleteEnvironmentIsSuccess){
            dispatch(modalController.closeAll());
        }        
    }, [deleteEnvironmentIsSuccess]);

    useEffect(() => {
        if(deleteProjectIsSuccess){
            dispatch(modalController.closeAll());
        }        
    }, [deleteProjectIsSuccess]);

    

    if ( project === null ) {
        return (
            <Grid>
                <Row>
                    <Col xs={12} style={{display: 'flex', paddingTop: 20, paddingBottom: 10}}>
                        <h2 style={{flex: 1, margin: 0}}>
                            Projects
                            &nbsp;
                            &nbsp;
                        </h2>
                    </Col>
                    <Col xs={12} >
                        <Alert bsStyle="info">You have no projects. Let's set one up now.</Alert>
                    </Col>
                    <Col xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
                        <div>
                            <Button bsStyle="primary"
                                    onClick={() => {
                                        updateProjectModal()
                                            .then(project => {
                                                setTimeout(() => setState({...state, selectedProject: project.key}), 50);
                                            })
                                            .catch(console.warn);
                                    }}>
                                New Project <FaPlusCircle style={{verticalAlign: 'text-bottom'}}/>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Grid>

        )
    }

    if (!isAdmin()) {
        return (
            <div>
                <Col xs={12} style={{display: 'flex', paddingTop: 20, paddingBottom: 10}}>
                    <h2 style={{flex: 1, margin: 0}}>
                        Projects
                        &nbsp;
                        &nbsp;
                    </h2>
                </Col>
                <Col xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
                    <div style={{textAlign: 'center', marginTop: 100, marginBottom: 100}}>
                        <p>Only organisation admins may manage projects. </p>
                    </div>
                </Col>
            </div>
        )
    }


    return (
        <div>
            <div style={{display: 'flex', paddingTop: 20, paddingBottom: 10}}>
                <h2 style={{flex: 1, margin: 0}}>
                    {project.name}
                    <small>({project.key})</small>
                    &nbsp;
                    &nbsp;
                    <DropdownButton bsStyle='primary'
                                    bsSize="small"
                                    title='Change' id={`dropdown-project`}
                                    onSelect={(key) => {
                                        goToRoute(`/admin/projects/${key}`);
                                    }}
                    >
                        {projects.map(project => {
                            return <MenuItem eventKey={project.key} key={project.key} active={project.key === currentProject}>{project.name}
                                <small>({project.key})</small>
                            </MenuItem>
                        })}
                    </DropdownButton>
                </h2>
                {
                    !numberLessThanLimit(projects.length, limits.maxProjects) && featureflow.evaluate('billing').isOn() &&
                    <p style={{marginTop: 10, textAlign: 'right', marginRight: 10}}>
                        Only {limits.maxProjects} project{limits.maxProjects !== 1 && 's'} allowed. <UpgradeLink/>
                    </p>
                }
                <div>
                    <Button bsStyle="primary"
                            disabled={!numberLessThanLimit(projects.length, limits.maxProjects) && featureflow.evaluate('billing').isOn()}
                            onClick={() => {
                                updateProjectModal()
                                    .then(project => {
                                        setTimeout(() => setState({...state, selectedProject: project.key}), 50);
                                    })
                                    .catch(console.warn);
                            }}>
                        New Project <FaPlusCircle style={{verticalAlign: 'text-bottom'}}/>
                    </Button>
                </div>
            </div>
            <div style={{paddingTop: 10, paddingBottom: 10}}>
                <ListGroup>
                    <ListGroupItem style={{display: 'flex', height: 50, backgroundColor: '#f3f3f3'}}>
                        <div style={{flex: 1, fontSize: 16, alignSelf: 'center'}}>Project Information</div>
                    </ListGroupItem>
                    {/* <ListGroupItem>
                        <ApiKeyCopyField title="Project JavaScript Api Key" apiKey={project.projectJsApiKey}/>
                    </ListGroupItem>*/}
                    <ListGroupItem style={{display: 'flex'}}>
                        <span style={{flex: 1}}>Update Project</span>
                        <Button bsStyle="primary"
                                bsSize="xsmall"
                                onClick={() => {
                                    updateProjectModal(project)
                                        .catch(console.warn);
                                }}>
                            Update Project <FaEdit/>
                        </Button>
                    </ListGroupItem>
                    <ListGroupItem style={{display: 'flex'}}>
            <span style={{flex: 1}}>
            Delete Project
            </span>
                        {projects.length <= 1 && <small style={{marginLeft: 10, marginRight: 10, color: '#999'}}>You must have at least one project.</small>}
                        <Button bsStyle="danger"
                                bsSize="xsmall"
                                disabled={projects.length <= 1}
                                onClick={() => {
                                    confirmModal('Confirm Delete', `This is an irreversible action. Are you sure you want to delete "${project.name} (${project.key})"?`)
                                        .then(value => {
                                            if (value) {
                                                deleteProject(project.key);
                                                // dispatch(doRemoveProject(project.key)).then(response => {
                                                //     setTimeout(() => setState({...state, selectedProject: projects[0].key}), 50);
                                                // });
                                            }
                                        });
                                }}>
                            Delete Project <FaTrash/>
                        </Button>
                    </ListGroupItem>
                </ListGroup>
            </div>

            <ListGroup style={{marginBottom: 0}}>
                <ListGroupItem style={{display: 'flex', height: 50, backgroundColor: '#f3f3f3'}}>
                    <div style={{flex: 1, fontSize: 16, alignSelf: 'center'}}>Environments</div>
                </ListGroupItem>
                {project.environments.map(environment => {
                    let editTooltip = <Tooltip id={environment.id + 'edit'}>Update {environment.name}</Tooltip>
                    let keysTooltip = <Tooltip id={environment.id + 'keys'}>Api Keys for {environment.name}</Tooltip>
                    let deleteTooltip = <Tooltip id={environment.id + 'delete'}>Delete {environment.name}</Tooltip>
                    return (
                        <ListGroupItem key={environment.id}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                                <div style={{
                                    background: environment.color || 'white',
                                    verticalAlign: 'middle',
                                    display: 'inline-block',
                                    width: 12,
                                    height: 12,
                                    marginRight: 4,
                                    borderRadius: 3,
                                    border: '1px solid #333'
                                }}></div>
                                {environment.local && <FaLaptop/>} {environment.name} ({environment.key})
                                <div style={{flex: 1, color: environment.url ? '#777' : '#f77', marginLeft: 16}}>
                                    {environment.url || '(no url set)'}
                                </div>
                                {project.environments.length === 1 && <small style={{marginLeft: 10, marginRight: 10, color: '#999'}}>You must have at least one environment.</small>}
                                <ButtonToolbar style={{display: 'inline-block'}}>
                                    <OverlayTrigger
                                        overlay={keysTooltip} placement="top"
                                        delayShow={300} delayHide={150}
                                    >
                                        <Button bsStyle="info"
                                                bsSize="xsmall"
                                                onClick={() => {

                                                    modal(`Api keys for ${environment.name}`,
                                                        <div>
                                                            <ApiKeyCopyField title="JS Client Environment Api Key" apiKey={environment.jsApiKey} tooltip="Use this SDK Key in your client-side javscript applications."/>
                                                            <ApiKeyCopyField title="Server Environment Api Key" apiKey={environment.apiKey} tooltip="Use this SDK Key in your server-side applications."/>
                                                        </div>
                                                    ).catch(console.warn)
                                                }}>
                                            Api Keys <FaKey/>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        overlay={editTooltip} placement="top"
                                        delayShow={300} delayHide={150}
                                    >
                                        <Button bsStyle="primary"
                                                bsSize="xsmall"
                                                onClick={() => {
                                                    updateEnvironmentModal(project, environment)
                                                        .catch(console.warn);
                                                }}>
                                            Update <FaEdit/>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        overlay={deleteTooltip} placement="top"
                                        delayShow={300} delayHide={150}
                                    >
                                        <Button bsStyle="danger"
                                                bsSize="xsmall"
                                                disabled={project.environments.length <= 1}
                                                onClick={() => {
                                                    confirmModal('Delete Environment', `Are you sure you want to delete '${environment.name}'?`).then(value => {
                                                        if (value) {
                                                            deleteEnvironment(environment.id);
                                                            AlertToast.success(`Environment deleted.`);
                                                        }
                                                    })
                                                }}>
                                            <FaTrash/>
                                        </Button>
                                    </OverlayTrigger>
                                </ButtonToolbar>
                            </div>
                        </ListGroupItem>
                    )
                })}
                <ListGroupItem style={{display: 'flex', justifyContent: 'flex-end', borderLeft: 'none', borderRight: 'none', borderBottom: 'none', padding: 0, paddingTop: 10}}>
                    <Button bsStyle="primary"
                            bsSize="small"
                            disabled={!numberLessThanLimit(environmentsCount, limits.maxEnvironments) && featureflow.evaluate('billing').isOn()}
                            onClick={() => {
                                updateEnvironmentModal(project).catch(console.warn);
                            }}>
                        New Environment
                    </Button>
                </ListGroupItem>
                {
                    !numberLessThanLimit(environmentsCount, limits.maxEnvironments) && featureflow.evaluate('billing').isOn() &&
                    <p style={{marginTop: 10, textAlign: 'right'}}>
                        Only {limits.maxEnvironments} environment{limits.maxEnvironments !== 1 && 's'} allowed. {environmentsCount} used in total. <UpgradeLink/>
                    </p>
                }
            </ListGroup>
        </div>
    )
}

export default Projects;