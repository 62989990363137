// @flow
import api from './';

export function registerEmail(email: string, beta: string){
  return api('post', `/api/v1/signup/registeremail`, {
    data:{
      email,
      beta
    }
  })
}


export function validatePin(email: string, once: string, pin: string){
  return api('post', `/api/v1/signup/validatepin`, {
    data:{
      email,
      once,
      pin
    }
  })
}


export function checkDomain(domain: string){
  return api('post', `/api/v1/signup/checkdomain`, {
    data:{
      domain
    }
  })
}

export function createAccount(data: any){
  return api('post', '/api/v1/signup/createaccount', {
    data
  })
}
