// @flow
import * as reduxUtils from '../utils/redux';

import * as featureControlApi from '../api/featureControl';

type FeatureControlsState = {[key: string]: any};
const PREFIX: string = "FF/FeatureControls/";
const RESET: string = PREFIX + "RESET";
const SET_FEATURE_CONTROLS: string = PREFIX + "SET_FEATURE_CONTROLS";
const REMOVE_FEATURE_CONTROLS: string = PREFIX + "REMOVE_FEATURE_CONTROLS";

const initialState: FeatureControlsState = {};

export default function (state: FeatureControlsState=initialState, action: Action): FeatureControlsState{
  switch(action.type){
    case(SET_FEATURE_CONTROLS):
      return {
        ...state,
        ...reduxUtils.reduceArrayToMap(action.payload.featureControls)
      };
    case (REMOVE_FEATURE_CONTROLS):
      return {
        ...state,
        ...action.payload.featureControlIds
            .reduce((obj, next)=>{
              return {
                ...obj,
                ...reduxUtils.archive(next.id, next)
              };
            }, {})
      }
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setFeatureControls(featureControls: any[]): Action{
  return {
    type: SET_FEATURE_CONTROLS,
    payload:{
      featureControls
    }
  }
}

export function removeFeatureControls(featureControlIds: string[]): Action{
  return {
    type: REMOVE_FEATURE_CONTROLS,
    payload:{
      featureControlIds
    }
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}

//Thunks
//Deprecated
export function doUpdateFeatureControl(featureControl: any, values: any): Thunk{
  return (dispatch)=>{
    return featureControlApi.update({
      ...featureControl,
      ...values
    })
      .then(response=>{
        dispatch(setFeatureControls([response]));
        return Promise.resolve(response);
      })
  }
}