// @flow
import api from '../';
import type {Coupon, Price} from "./types";
import BillingInfo from "../../routes/Admin/Billing/BillingInfo";
import {BillingUsage} from "./types";

export function getPrices(): Price[]{
  return api('get', `/api/v1/prices`)
}


export function getBillingInfo(): BillingInfo{
  return api('get', `/api/v1/billing/info`)
}


export function getBillingUsage(): BillingUsage{
  return api('get', `/api/v1/billing/usage`)
}

export function getBillingInvoices(){
  return api('get', `/api/v1/billing/invoices`)
}


export function addCardToken(token: string){
  return api('put', `/api/v1/billing/source`, {
    data: {
      token
    }
  })
}

export function subscribe(priceId: string, seats?: number){
  return api('put', '/api/v1/billing/subscription', {
    data:{
      priceId: priceId,
      quantity: seats
    }
  })
}

export function cancelSubscription(){
  return api('delete', `/api/v1/billing/subscription`);

}


export function getCoupon(couponCode: string): Coupon{
    return api('get', `/api/v1/billing/coupon/` + couponCode);
}

export function applyCouponCode(couponCode: string){
    return api('put', `/api/v1/billing/coupon`, {
      data: {
        couponCode
      }
    });
}
