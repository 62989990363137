// @flow
import React from 'react';
import { Provider } from 'react-redux';

import FeatureflowRouter from './FeatureflowRouter';

import socket from './socket';
import * as auth from './redux/authentication';

import store from './redux';

import featureflow from './config/featureflow';

import { FeatureflowProvider } from 'react-featureflow-client';
import * as JWTService from './utils/JWTService';
/*import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';*/
//socket();

store.dispatch(auth.setToken(JWTService.getAuthToken()));

//const stripePromise = loadStripe('pk_test_5y6Dwzg75qC9kFasDTaxCxIM');

export default class App extends React.Component{
  componentDidMount(){
    const ele = document.getElementById('initial-app-loading');
    if(ele){
      ele.classList.add('available');
      setTimeout(() => {
        ele.outerHTML = '';
      }, 1000);
    }
  }
  render(){
/*    const options = {
      // passing the client secret obtained from the server
      clientSecret: '{{CLIENT_SECRET}}',
    };*/
    return (
      /*<Elements stripe={stripePromise} options={options}>*/
        <FeatureflowProvider client={featureflow}>
          <Provider store={store}>
            <FeatureflowRouter/>
          </Provider>
        </FeatureflowProvider>
      /*</Elements>*/
    );
  }

}
