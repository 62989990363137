// @flow

export function reduceArrayToMap(list: [], key: string = 'id'){
  return list.reduce((obj, next)=>{
    obj[next[key]] = next;
    return obj;
  }, {});
}

//use this with spread
export function archive(key: string, state: any){
  return {
    [key]: {
      ...state[key],
      archived: true
    }
  }
}
//remove an item from the state by its key value
export function remove(key: string, state: any){
    const { [key]: value, ...newState } = state;
    return newState;
}