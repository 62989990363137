import moment from 'moment';
const FF_CONTEXT = 'FF:';
let timestamp = function(){};
timestamp.toString = function(){
  return moment().format(`D/M/YY h:mm:ssa [${FF_CONTEXT} ]`);
};

function wrap(fn){
  if (!fn){
    return fn;
  }
  if (process.env.NODE_ENV === 'production'){
    return ()=>{} //noop
  }
  else{
    if (fn.bind === 'undefined') { // IE < 10
      return Function.prototype.bind.call(fn, console, timestamp);
    }
    else {
      return fn.bind(console, timestamp);
    }
  }
}

const proxyMethods = [
  'assert',
  'clear',
  'count',
  'debug',
  'dir',
  'dirxml',
  'error',
  'exception',
  'group',
  'groupCollapsed',
  'groupEnd',
  'info',
  'log',
  'profile',
  'profileEnd',
  'table',
  'time',
  'timeEnd',
  'timeStamp',
  'trace',
  'warn'
];

export default proxyMethods.reduce((object, method)=>{
  object[method] = wrap(console[method]);
  return object;
},{})