import * as React from 'react';
import {Button, Col, Label, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import access from "safe-access";
import moment from "moment";
import {browserHistory} from "react-router";
import FeatureStatusIndicator from "../../components/FeatureStatusIndicator";
import CopyToClipboard from "react-copy-to-clipboard";
import Alert from "react-s-alert";
import {FaCopy, FaEdit} from "react-icons/lib/fa";
import BookmarkFeature from "../../components/BookmarkFeature";
import FeatureControlDetails from "./FeatureControlDetails";
import {isAllowed} from "../../utils/authentication";
import {updateFeatureModal} from "../../modals/FeatureFormModal";
import console from "../../utils/debug";
import Maintainer from "./Maintainer";
import { useFeatureflow } from 'react-featureflow-client'
import { updateBookmarkMutation } from '../../api/featureflowApi';

function featureControlDetailsModal(props) {
    const featureflow = useFeatureflow();
    const [updateBookmark, { isLoading: updateBookmarkIsLoading, isSuccess: updateBookmarkIsSuccess, isError: updateBookmarkIsError, error: updateBookmarkError }] = useUpdateBookmarkMutation();    
    const {feature = {}, featureControl = {}, project = {}, environment} = props;
    let copyFeatureKeyTooltip = <Tooltip id={feature.id+'copy'}>Copy '{feature.key}' to clipboard</Tooltip>;
    let editFeatureTooltip = <Tooltip id={feature.id+'edit'}>Edit {feature.key}</Tooltip>;

    let lastImpressionDate = 'None';

    if(access(featureControl, '.statistics.lastImpressionDate') !== null) {
        lastImpressionDate = moment(access(featureControl, '.statistics.lastImpressionDate')).format('Do MMM YYYY, h:mma');
    }
    let doToggleBookmark = (featureId: string, bookmarked: boolean): void => {
        updateBookmark({featureId, bookmarked});
    };


    // useEffect(() => {
    //     if(deleteProjectIsSuccess){
    //         dispatch(modalController.closeAll());
    //     }        
    // }, [deleteProjectIsSuccess]);



    if(!feature || !featureControl){
        return <></>
    }
    return (
        <Modal show={!!props.location.query.key}
               bsSize="large"
               backdrop="static"
               animation={!!props.location.query.key}
               onHide={() => {
                   browserHistory.push({
                       ...browserHistory.getCurrentLocation(),
                       query: {}
                   })
               }}>
            <Modal.Header closeButton={true} style={{paddingBottom: 0}}>
                <Row>
                    <Col xs={8}>
                        <FeatureStatusIndicator featureKey={feature.key}
                                                available={featureControl.available}
                                                trafficStatus={featureControl.trafficStatus}
                                                targetingStatus={featureControl.targetingStatus}
                                                staleDays={featureControl.staleDays}
                                                environment={environment.name}
                                                style={{marginRight: 10, paddingTop: 4, float: 'left'}}
                        />
                        <h3 style={{marginTop: 0}}>
                            {feature.name} &nbsp;
                        </h3>

                        <p>
                            <OverlayTrigger
                                overlay={copyFeatureKeyTooltip} placement="bottom"
                                delayShow={300} delayHide={150}>
                                <CopyToClipboard text={feature.key}
                                                 onCopy={() => Alert.success(`Copied <b>${feature.key}</b> to clipboard`, {html: true})}>
                                    <span style={{cursor: 'pointer'}}>
                                        <Label bsStyle="default">Key: {feature.key} &nbsp;<FaCopy/></Label>
                                    </span>
                                </CopyToClipboard>
                            </OverlayTrigger>
                        </p>
                        {feature.description &&
                        <p style={{marginTop: 0}}>
                            {feature.description} &nbsp;
                        </p>}
                        {feature.jiraIssues &&
                        <p style={{marginTop: 0}}>
                            {feature.jiraIssues.map(issue => {
                                return <a href={issue.url} target="_blank" rel="noopener noreferrer" style={{marginRight: 5}} className="label label-primary">{issue.key}</a>
                            })}
                        </p>}
                    </Col>

                    <Col xs={4} className="text-right" style={{marginTop: 0}}>
                        <Maintainer maintainer={feature.maintainer} featureKey={feature.key} featureName={feature.name} />
                        <p><span className="text-muted">Created: </span><span>{moment(access(featureControl, '.createdDate')).format('Do MMM YYYY, h:mma')}</span></p>
                        <p><span className="text-muted">Last updated: </span><span>{moment(access(featureControl, '.lastModifiedDate')).format('Do MMM YYYY, h:mma')}</span></p>
                        <p><span className="text-muted">Last impression: </span><span>{lastImpressionDate}</span></p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right" lg={12}>
                        {featureflow.evaluate('bookmarked').isOn() &&
                        <BookmarkFeature
                            featureId={feature.id}
                            bookmarked={feature.bookmarked}
                            onClick={doToggleBookmark}
                        />}
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: '#f4f4f4'}}>
                <FeatureControlDetails feature={feature} featureControl={featureControl}/>
            </Modal.Body>
            <Modal.Footer>
                {isAllowed('update', `projects/${project.key}/environments/${environment.id}/controls/${featureControl.key}`, feature.tags, environment.production?['production']:[]) &&
                <OverlayTrigger
                    overlay={editFeatureTooltip} placement="top"
                    delayShow={300} delayHide={150}
                    onClick={() => {
                        props.location.query = {};
                        updateFeatureModal(props, feature).catch(console.warn)
                    }}
                >
                    <Button bsStyle="primary">
                        <FaEdit size="1.2em"/> Edit
                    </Button>
                </OverlayTrigger>
                }
                <OverlayTrigger
                    overlay={<Tooltip id={feature.id+'close'}>Close</Tooltip>} placement="top"
                    delayShow={300} delayHide={150}
                    onClick={() => {
                        browserHistory.push({
                            ...browserHistory.getCurrentLocation(),
                            query: {}
                        })
                    }}
                >
                    <Button bsSize="success">
                        Close
                    </Button>
                </OverlayTrigger>
            </Modal.Footer>
        </Modal>
    )
}

export default featureControlDetailsModal;