// @flow
import React, {useEffect, useState} from 'react';
import access from 'safe-access';
import {Col, Panel, Row} from 'react-bootstrap';
import * as helpers from '../../utils/helpers';
import moment from 'moment';
import * as statisticsApi from "../../api/statistics";

import {Line} from 'react-chartjs-2';
import type {Feature, FeatureControl} from "../../api/feature";
import type {Environment} from "../../api/environment";

type Props = {
    environment: Environment,
    featureControl: FeatureControl,
    feature: Feature
}
const FeatureControlDetails = (props: Props): React.Element<any> => {
    const timeFormat = 'YYYY-MM-DD';
    const {environment, featureControl, feature} = props;
    const [state, setState] = useState({
        lastImpressionDate: 'Never',
        impressionChartData: {
            labels: [],
            datasets: []
        },
        impressionChartOptions: {
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    fontColor: 'rgb(109, 192, 168)'
                }
            },
        }
    });

    const colours = [
        'rgba(230, 176, 170)',
        'rgba(215, 189, 226)',
        'rgba(169, 204, 227)',
        'rgba(169, 204, 227)',
        'rgba(169, 223, 191)',
        'rgb(249, 231, 159)',
        'rgb(245, 203, 167)',
        'rgb(229, 231, 233)',
        'rgb(174, 182, 191)',
        'rgb(171, 178, 185)'
    ];

    const defaults = {
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10
    };

    useEffect(() => {
        statisticsApi.getFeatureControlImpressions(featureControl.id)
            .then(lineChartData => {
                for (let j = 0; j < lineChartData.labels.length; j++) {
                    lineChartData.labels[j] = moment(lineChartData.labels[j]).format(timeFormat);
                }

                for (let i = 0; i < lineChartData.datasets.length; i++) {
                    lineChartData.datasets[i] = {...lineChartData.datasets[i], ...defaults};
                    lineChartData.datasets[i].borderColor = colours[i % colours.length];
                    lineChartData.datasets[i].backgroundColor = lineChartData.datasets[i].borderColor;
                }
                setState({...state, impressionChartData: lineChartData});
            }).catch(console.warn);
    }, [featureControl])

    return <>
        {feature && environment && featureControl &&
        <div>
            <Row>
                <Col sm={12}>
                    <h3>Impressions in {environment.name || ''}</h3>
                    <Panel title="impressions">
                        <Line data={state.impressionChartData}
                              options={state.impressionChartOptions} height={250}/>
                    </Panel>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <ul className="list-group">
                        {feature.variants && feature.variants.map(variant => {
                            const fcVariant = featureControl?.statistics?.variants[variant.key];
                            return <li className="list-group-item" key={variant.key}>
                                        <span
                                            className="badge">{helpers.numberFormatted(access(fcVariant, '.impressions.today'))}</span>
                                {`Variant '${variant.name}' Impressions Today`}
                            </li>
                        })}
                        <li className="list-group-item">
                                    <span
                                        className="badge">{helpers.numberFormatted(access(featureControl, '.statistics.impressions.today'))}</span>
                            {"Total Impressions Today"}
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
        }
    </>
}

export default FeatureControlDetails;