// @flow
import {browserHistory} from 'react-router';
import {getDomain} from "./api";
import access from 'safe-access';

export function goToFeatureRoute(projectKey: string, featureKey: string): void {
    //go to an environment view for a feature currently
    //this gets redirected via routes/index.js and redirectToFeature
    goToRoute("/projects/" + projectKey + "/features/" + featureKey);
}

export function goToFeaturesRoute(projectKey: string, environmentKey: string): void {
    goToRoute("/projects/" + projectKey + "/environments/" + environmentKey);
}

export function goToRoute(path: string, replace: boolean = false): void {
    if (replace) {
        browserHistory.replace(getRoute(path));
    } else {
        browserHistory.push(getRoute(path));
    }
}

export function goToSubdomain(domain: string): void {
    const protocol = access(window, 'location.protocol');
    const path = protocol + "//" + domain + "." + getDomain() + "/login"; // protocol + "//" + parts.join(".") + "/" + subdomain;
    window.location = path;
}

export function getRoute(path: string) {
    if (path.charAt(0) !== '/') {
        const base = browserHistory.getCurrentLocation().pathname;
        let stack = base.split("/"),
            parts = path.split("/");
        stack.pop(); // remove current file name (or empty string)
        // (omit if "base" is the current folder without trailing slash)
        for (let i = 0; i < parts.length; i++) {
            if (parts[i] === ".")
                continue;
            if (parts[i] === "..")
                stack.pop();
            else
                stack.push(parts[i]);
        }
        return stack.join("/");
    }
    return path;
}