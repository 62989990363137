// @flow
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import * as reduxUtils from '../utils/redux';
import * as featureApi from '../api/feature';

type FeaturesState = {[key: string]: any};
const PREFIX: string = "FF/Features/";
const RESET: string = PREFIX + "RESET";
const SET_FEATURES: string = PREFIX + "SET_FEATURES";
const REMOVE_FEATURE: string = PREFIX + "REMOVE_FEATURE";

const initialState: FeaturesState = {};

export default function (state: FeaturesState=initialState, action: Action): FeaturesState{
  switch(action.type){
    case(SET_FEATURES):
      return {
        ...state,
        ...reduxUtils.reduceArrayToMap(action.payload.features)
      };
    case (REMOVE_FEATURE):
      return {
        ...state,
        ...reduxUtils.archive(action.payload.featureId, state)
      }
    case(RESET):
      return initialState;
    default:
      return state;
  }
}

export function setFeatures(features: any[]): Action{
  return {
    type: SET_FEATURES,
    payload:{
      features
    }
  }
}

export function removeFeature(featureId: string): Action{
  return {
    type: REMOVE_FEATURE,
    payload: {
      featureId
    }
  }
}

export function reset(): Action{
  return {
    type: RESET,
    payload: {}
  }
}

//Thunks
export function doUpdateFeature(feature: any): Thunk{
  return (dispatch)=>{
    return featureApi.update(feature).then(response=>{
      dispatch(setFeatures([response]));
      return Promise.resolve(response);
    }).catch(err=>{
      return Promise.reject(err);
    })
  }
}
export function doLinkFeature(feature: any): Thunk{
  return (dispatch)=>{
    return featureApi.link(feature).then(response=>{
      dispatch(setFeatures([response]));
      return Promise.resolve(response);
    }).catch(err=>{
      return Promise.reject(err);
    })
  }
}

export const updateBookmark = (featureId: string, bookmarked: boolean) => async dispatch => {
  const result = await dispatch(setFeatures([await featureApi.bookmark(featureId, bookmarked)]));
  return result;
}

/*
export const bookmarkFeature = ({featureId: string, bookmarked: boolean}) => async dispatch => {
    return featureApi.bookmark(featureId).then(response=>{
      dispatch(setFeatures([response]));
      return Promise.resolve(response);
    }).catch(err=>{
      return Promise.reject(err);
    })
}
*/

/*export function doBookmarkFeature(featureId: string): Thunk{
  return (dispatch) => {
    return featureApi.bookmark(featureId).then(response=>{
      dispatch(setFeatures([response]));
      return Promise.resolve(response);
    }).catch(err=>{
      return Promise.reject(err);
    })
  }
}*/
export function doRemoveFeature(projectKey: string, featureId:string): Thunk{
  return (dispatch)=>{
    return featureApi.remove(projectKey, featureId)
      .then(response=>{
        dispatch(removeFeature(featureId));
        return Promise.resolve(response);
      })
      .catch(err=>{
        return Promise.reject(err);
      })
  }
}

export function doUpdateVariant(feature: any, variant: any): Thunk{
  let { key, name, description } = variant;
  let variants = feature.variants.filter(v=>v.key !== key);
  variants.push({ key, name, description });
  return (dispatch)=>{
    return featureApi.update({
      ...feature,
      variants
    })
      .then(response=>{
        dispatch(setFeatures([response]));
        return Promise.resolve(response);
      })
  }
}

export function doRemoveVariant(feature: any, variantKey: any): Thunk{
  let variants = feature.variants.filter(v=>v.key !== variantKey);
  return (dispatch)=>{
    return featureApi.update({
      ...feature,
      variants
    })
      .then(response=>{
        dispatch(setFeatures([response]));
        return Promise.resolve(response);
      })
  }
}