// @flow
import React from 'react';

import './SyntaxHighlighterWithCopy.scss';

import SyntaxHighlighter, { registerLanguage } from 'react-syntax-highlighter/dist/light';
import {bash, javascript, java, go, ruby, xml} from 'react-syntax-highlighter/dist/languages/index';
import { docco } from 'react-syntax-highlighter/dist/styles';

import CopyButton from './CopyButton';

registerLanguage('javascript', javascript);
registerLanguage('java', java);
registerLanguage('go', go);
registerLanguage('ruby', ruby);
registerLanguage('bash', bash);
registerLanguage('xml', xml);


export default function(props: any): React.Element<any>{
  const {
    code,
    style,
    ...otherProps
  } = props;
  return (
    <div className="syntax-highlighter-with-copy" style={style}>
      <SyntaxHighlighter language={props.language} style={{...docco, lineHeight: 1}} {...otherProps} children={code}/>
      <CopyButton bsStyle="primary" copyText={code} style={{position: 'absolute', top: 2, right: 2}}/>
    </div>
  )
}