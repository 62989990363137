// @flow
import React, {useState} from 'react';
import {Button, Col, FormControl, FormGroup, Panel, Row} from "react-bootstrap";
import {CancelSubscription, Price, SubscribeToPrice} from "../../../api/billing/types";

export type Props = {
    currentPlan: boolean,
    price: Price,
    subscribeToPrice: SubscribeToPrice,
    cancelSubscription: CancelSubscription
}

function FixedPricingPlan({currentPlan, price, cancelSubscription, subscribeToPrice}: Props) {

    const doSubscribeToPrice = (price) => () => {
        subscribeToPrice(price);
    }

    const doCancelSubscription = (price) => () => {
        cancelSubscription(price);
    }

    function plural(number, word) {
        return `${numberWithCommas(number)} ${number === 1 ? word.substr(0, word.length - 1) : word}`
    }

    function numberWithCommas(x) {
        if (!x && x !== 0) {
            return "Unlimited";
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <Col lg={3} md={3} sm={3} xs={12} key={price.id}>
            <Panel>
                <Panel.Heading
                    style={{textAlign: 'center', fontSize: 18}}>{price.productName}</Panel.Heading>
                <Panel.Body style={{textAlign: 'center'}}>
                    <h2 style={{marginTop: 0}}>${(price.amount / 100).toFixed(2)}/{price.interval}
                        <small> ({price.currency.toUpperCase()})</small>
                    </h2>
                    <p style={{fontSize: 18}}>
                        {price.description}
                    </p>
                    <b>Features</b>
                    <ul style={{textAlign: 'left'}}>
                        <li>{plural(price.limits.maxOrganisationUsers, 'seats')}</li>
                        <li>{plural(price.limits.maxApplicationUniqueUsers, 'frontend MAU')} </li>
                        <li>{plural(price.limits.maxProjects, 'projects')} </li>
                        <li>{plural(price.limits.maxEnvironments, 'environments')}</li>
                        <li>{plural(price.limits.maxFeatures, 'features')}</li>
                        {price.limits.auditLog && <li>Audit logs</li>}
                        {price.limits.variantTesting && <li>A/B Testing</li>}
                    </ul>

                    <Button onClick={doSubscribeToPrice(price)}
                            disabled={currentPlan}
                            bsStyle="primary" bsSize="sm">
                        {currentPlan ? 'Current Plan' : `Subscribe to ${price.productName}`}
                    </Button>
                    {currentPlan && price.amount > 0 && <div style={{marginTop: 10}}>
                        <Button onClick={doCancelSubscription(price)}
                                bsStyle="default" bsSize="xs">
                            Cancel Subscription
                        </Button>
                    </div>}
                </Panel.Body>
            </Panel>
        </Col>
    )
}

export default FixedPricingPlan;
