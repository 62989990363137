// @flow
import React from 'react';
import { Button } from 'react-bootstrap';
import ActionButton from './controls/ActionButton';
import { connect } from 'react-redux';
import { actions, Form, Control, Fieldset } from 'react-redux-form';

import FaPlus from 'react-icons/lib/fa/plus';
import FaMinus from 'react-icons/lib/fa/minus';

import { defaultUserInvite } from '../redux/form/userInvites';

import ControlWrapper from '../forms/controls/ControlWrapper';



function mapStateToProps(state){
  return {
    userInvites: state.form.userInvites.invites
  }
}

export default connect(mapStateToProps)(function(props: any): React.Element<any>{
    return (
      <Form model="form.userInvites" onSubmit={props.onSubmit}>
        {props.userInvites.map((userInvite, index)=>{
          return (
            <Fieldset key={index} model={`.invites[${index}]`} style={{display: 'flex', marginBottom: -16}}>
              <ControlWrapper model=".email"
                             style={{marginRight: 8}}
                             label={index === 0 ? 'Email': ''}
                             placeholder="Email Address"
                             validateOn={['change']}
                             errorMessages={{
                               isRequired: 'Required',
                               isEmail: 'Email must be valid'
                             }}
              />
              <ControlWrapper model=".firstName"
                             style={{marginRight: 8}}
                             label={index === 0 ? 'First Name': ''}
                             placeholder="First Name (optional)"
                             validateOn={['change']}
              />
              <ControlWrapper model=".lastName"
                             style={{marginRight: 8}}
                             label={index === 0 ? 'Last Name': ''}
                             placeholder="Last Name (optional)"
                             validateOn={['change']}
              />
              <div>
              <Button
                bsStyle="danger"
                bsSize="small"
                disabled={props.userInvites.length <= 1}
                style={{marginRight: 8, marginTop: (index === 0 ? 26: 0), fontSize: 12}}
                onClick={()=>{
                          props.dispatch(
                            actions.remove(
                              `form.userInvites.invites`,
                              index
                            )
                          )
                        }}> <FaMinus/> </Button>

              {(index >= props.userInvites.length - 1) &&
              <Button
                bsStyle="success"
                bsSize="small"
                style={{marginRight: 8, marginTop: (index === 0 ? 26: 0), fontSize: 12}}
                onClick={()=>{
                          props.dispatch(
                            actions.push(
                              `form.userInvites.invites`,
                              {...defaultUserInvite}
                            )
                          )
                        }}> <FaPlus/> </Button>}
              </div>
            </Fieldset>
          )
        })}
      </Form>
    )
});

export const UserInvitesFormButtons = connect()((props: any): React.Element<any> => {
  return (
    <Control component={ActionButton}
             model="form.userInvites"
             type="submit"
             disabled={fieldValue=>fieldValue.pending}
             mapProps={{
                 disabled: props=>props.fieldValue.pending,
                 spinner: props=>props.fieldValue.pending
               }}
             bsStyle="success"
             onClick={()=>props.dispatch(actions.submit('form.userInvites'))}
    >
      Send User Invites
    </Control>
  );
});