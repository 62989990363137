import React from 'react';
import MyStepper from './MyStepper';

import HomeMenu from '../assets/images/onboarding/home-menu.png';
import FeatureRow from '../assets/images/onboarding/feature-row.png';
import FeatureDetailsPage from '../assets/images/onboarding/feature-details-page.png'

export default function UpdatesCarousel(props){
  const { onClose } = props;
  return(
    <MyStepper onClose={onClose}>
      <div style={{textAlign:'center'}}>
          <h1><strong>We've made a few changes</strong></h1>
            <br/>
            <p>The Home page gives you a list of recent features and any you have bookmarked or are maintaining.</p>
            <img src={HomeMenu} alt="Home Menu" style={{maxWidth: '90%', maxHeight: 200, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>A Simplified Features List</strong></h1>
        <br/>
        <p>Clicking the 'On/Off' toggle will enable or disable the feature targeting rules in the current environment.</p>
        <p>Clicking the feature name shows a consolidated feature overview including settings, rules and an audit of changes.</p>
        <img src={FeatureRow} alt="Update Rules" style={{maxWidth: '90%', maxHeight: 450, border: '1px solid #ddd'}}/>
      </div>
      <div style={{textAlign:'center'}}>
        <h1><strong>A Consolidated feature view</strong></h1>
        <br/>
        <p>See your feature overview, targeting rules, settings and audit log in one place.</p>
        <p>View the feature in other environments using the breadcrumb links.</p>
        <p>We welcome your feedback through Intercom :)</p>
        <img src={FeatureDetailsPage} alt="Rules Form" style={{maxWidth:  '90%', maxHeight: 450, border: '1px solid #ddd'}}/>
      </div>
    </MyStepper>
  );
}