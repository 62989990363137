// @flow
import * as React from 'react';
import { useState } from 'react';
import { useFeatureflow } from 'react-featureflow-client'
import {Button, Col} from 'react-bootstrap';
import Search from '../components/Search';

import {updateFeatureModal} from '../modals/FeatureFormModal';
import {largeModal} from '../modals/BasicModal';

import FeatureList from './FeatureControls/FeatureList';
import {numberLessThanLimit} from '../utils/limits';
import ApiKeyCopyField from '../components/ApiKeyCopyField';
import {useSelector} from 'react-redux';
import {convertStringToKey} from '../utils/formParsers';
import {actions} from 'react-redux-form';

import {withRouter} from 'react-router';
import {isAllowed} from '../utils/authentication';
import UpgradeLink from '../components/UpgradeLink';
import console from "../utils/debug";
import featureControlDetailsModal from "./FeatureControls/FeatureControlDetailsModal";
import { LoadingOverlay } from '../components/LoadingOverlay';
import {
    useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery,
    useGetFeaturesByProjectKeyQuery,
    useGetProjectByKeyQuery
} from "../api/featureflowApi";
import PageNotFound from "./PageNotFound";
import Tour from 'reactour'

/*
type PaginationState = {
    itemsPerPage: number;
    sort: string;
    order: string;
    activePage: number;
}*/


const steps = [
    {
        selector: '.tourStep1',
        content: `We've have moved feature details, settings, targeting and audit all under one roof.   `,
    },
    // ...
];

const FeatureControlView = (function (props: any): React.Element<any> {
    const featureflow = useFeatureflow();
    const [isTourOpen, setIsTourOpen] = useState(true);
    const { projectKey, environmentKey, key } = props.params;
    const { data: features, error: featuresError, isFetching, isLoading } = useGetFeaturesByProjectKeyQuery({projectKey});
    const { data: environment, error: environmentError, isLoading: environmentIsLoading }
        = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery({projectKey, environmentKey});
    const { data: project, error: projectError, isLoading: projectIsLoading }
        = useGetProjectByKeyQuery(projectKey);
    const limits = useSelector((state => state.subscriptionOutline.limits));
    const filterText = useSelector((state => state.form.filters.features));

//    const featuresUrl = `/projects/${projectKey}/environments/${environmentKey}/features`


    if(featuresError || environmentError || projectError){
      return <PageNotFound />;
    }
    return (
        <>
            <LoadingOverlay visible={isLoading || environmentIsLoading || projectIsLoading} />
            {environment && project && features && <div>
                <Col xs={12} md={8} style={{paddingBottom: 10}}>
                    <h2 style={{paddingBottom: 0}}>
                        Features
                    </h2>
                    <small>
                        for {environment.name}
                        {environment.url &&
                        <span><small> ({environment.url})</small></span>
                        }
                    </small>
                </Col>
                <Col xs={12} md={4} style={{marginTop: 21}}>

                    <div style={{float: 'right'}}>
                        <Search model="form.filters.features" placeholder="Search Features"/>


                        <button className="link-button"
                                style={{
                                    fontSize: 16,
                                    fontWeight: 'normal',
                                    verticalAlign: 'bottom',
                                    float: 'right',
                                    marginTop: 10
                                }}
                                onClick={() =>
                                    largeModal(`Api keys for ${environment.name}`,
                                        <div>
                                            <ApiKeyCopyField title="Server Environment SDK Key" apiKey={environment.apiKey}
                                                             tooltip={'Use this key for all server-side SDK integrations'}/>
                                            <ApiKeyCopyField title="JS Client Environment SDK Key"
                                                             apiKey={environment.jsApiKey}
                                                             tooltip={'Use this key for all client-browser integrations using the javascript SDK'}/>
                                        </div>
                                    ).catch(console.warn)
                                }>
                            Api Keys
                        </button>
                    </div>

                </Col>
                <Col xs={12} style={{paddingBottom: 20}}>
                    {isAllowed('create', `feature`, environment.tags || [] , environment.production ? ['production'] : []) &&
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} className="step-new-feature-button">
                            <Button bsStyle="primary"
                                    disabled={!numberLessThanLimit(features.length, limits.maxFeatures) && featureflow.evaluate('billing').isOn()}
                                    onClick={() => {
                                        updateFeatureModal(props, {projectKey: project.key}).catch(console.warn);
                                    }}
                            >
                                New Feature
                            </Button>
                        </div>
                    }
                </Col>
                {
                    !numberLessThanLimit(features.length, limits.maxFeatures) &&
                    <Col xs={12} style={{paddingBottom: 20}}>
                        <p style={{marginTop: 10, textAlign: 'right'}}>
                            Only {limits.maxFeatures} feature{limits.maxFeatures !== 1 && 's'} allowed. <UpgradeLink/>
                        </p>
                    </Col>
                }
                <Col xs={12}>
                    <FeatureList
                        featureList={features}
                        environment={environment}
                        project={project}
                        limits={limits}
                        filterText={filterText}
                        match={props.match} //props.match is injected from react router and is the matched url we are on eg projects/FASTT/environments/598947c33cb2f2000ac1a636/features
                        createFeature={(text) => {
                            updateFeatureModal(props, {
                                name: text,
                                key: convertStringToKey(text),
                                projectKey: project.key
                            })
                                .then(() => props.dispatch(actions.reset('form.filters.features')))
                                .catch(console.warn);
                        }}/>
                </Col>
                <Col xs={12} style={{paddingBottom: 80}}></Col>
                {typeof feature !== 'undefined' && featureControlDetailsModal({...props, environment, feature, project, featureControl: feature.controls[environmentKey]})}
            </div>
            }
           {/* <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
            />*/}
        </>

    )
});




export default withRouter(FeatureControlView);