// @flow

export function getInitialVariants(){
  return [
    {
      key: 'on',
      description: 'On',
      name: 'On'
    },
    {
      key: 'off',
      description: 'Off',
      name: 'Off'
    }
  ];
}

const initialState = {
  id: undefined,
  name: '',
  key: '',
  description: '',
  permanent: false,
  variants: [],
  tags: [],
  goals: [],
  maintainer: {},
};

export default initialState;

export const defaultVariant = {
  key: '',
  name: '',
  description: ''
};