  // @flow
import React, { useEffect, useState } from 'react';

import Timeseries from '../charts/Timeseries';

import * as experimentApi from '../api/experiment';

import moment from 'moment';

import { goToRoute } from '../utils/routing';

import {FaRefresh, FaChevronLeft, FaCircle} from 'react-icons/lib/fa'

import {Table, Row, Col, Panel, Button, Grid, PageHeader, Tooltip, OverlayTrigger} from 'react-bootstrap';

import { connect } from 'react-redux';

import { createSelector } from '@reduxjs/toolkit'

import { useFeatureflow } from 'react-featureflow-client';

import type { Project } from '../api/project';

import type { Environment } from '../api/environment';

import type { Experiment } from '../api/experiment';

import { 
  useGetExperimentQuery, 
  useGetProjectByKeyQuery,
  useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery ,
  //useGetFeatureByProjectKeyAndFeatureKeyQuery,
} from '../api/featureflowApi';


type ExperimentResultsType = {
  confidence: number,
  pvalue: number,
  validResult: boolean,
  variantResults: { [string]: any } ,
  winningVariation: string,
  zscore: number
}

type ExperimentParams = {
  params: {
    experimentId: string
  },
  experiment: {
    name: string,
    feature: any,
    startDate: ?string,
    endDate: ?string,
    results: ExperimentResultsType,
    control: string,
    variation: string
  }
}

type State = {
  timeseries: any,
  statistics: any
}

type Props = {
  // projectKey: string,
  // environmentKey: string,
  // experimentId: string,
  params: any
}

const ExperimentStats = (props: Props): React.ElementType => {
  const { projectKey, environmentKey, experimentId} = props.params;
  const { data: experiment, error, isLoading: experimentIsLoading, isSuccess: experimentIsSuccess } = useGetExperimentQuery(experimentId);
  const { data: environment, error: environmentError, isLoading: environmentIsLoading } = useGetEnvironmentByProjectKeyAndEnvironmentKeyQuery({projectKey, environmentKey});
  const { data: project, error: projectError, isLoading: projectIsLoading } = useGetProjectByKeyQuery(projectKey);
  //const { data: feature, error: featureError, isLoading: featureIsLoading } = useGetFeatureByProjectKeyAndFeatureKeyQuery({projectKey, featureKey});    
  //const experiment = {};
  

  const featureflow = useFeatureflow();

  const [state, setState] = useState<State>({
    timeseries: {},
    statistics: {
      confidence: 0,
      pvalue: 0,
      validResult: false,
      variantResults: {},
      winningVariation: '',
      zscore: 0

    }
  });

  useEffect(() => {
    Promise.all([
      experimentApi.getStatistics(experimentId)
      // ,experimentApi.getTimeseries(props.params.experimentId, {
      //   from:  moment(props.experiment.startDate || 0).toDate(),
      //   to:   moment(props.experiment.endDate || new Date()).toDate(),
      //   metrics: ['impressions','goals','conversions']
      // })
    ]).then(([statistics])=>{
      setState({...state, statistics })
    })
    .catch(console.warn);
  }, [])
  
  const renderTimeseriesChart = () => {
    if (!state.timeseries){
      return (
        <div style={{fontSize: 100, color: '#555', lineHeight: '200px', textAlign: 'center'}}>
          <FaRefresh className="fa-spin"/>
        </div>
      )
    }
    else{
      return <Timeseries {...state.timeseries} yFormat={(d)=>d} metrics={['impressions']}/>
    }
  }

  
  let winnerTooltip = <Tooltip id="winnerTooltip">We declare a winner when the experiment reaches 95% confidence.</Tooltip>

  if(!experiment || !project || !environment){
    return <div></div>;
  }
  const { name, goalKey, startDate, endDate, feature, enabled} = experiment;

  return <Grid>
          <Row>
            <Col>
              <Button bsStyle="link"
                      style={{padding: 0}}
                      onClick={()=>goToRoute(`/projects/${props.params.projectKey}/environments/${props.params.environmentKey}/experiments`)}>
                <FaChevronLeft/> Back to Experiments
              </Button>

            </Col>
          </Row>
          <Row>
            <Col>
              <PageHeader>
                <span style={{fontSize: 24, height: '24px', color: enabled?'#6ac1a8':'#ccccc'}}>
                  <FaCircle style={{verticalAlign: 'text-top', lineHeight: '24px'}}/>
                </span>
                {name} <small>{enabled ? 'Currently Active' : 'Finished '+moment().to(moment(endDate))}</small>
              </PageHeader>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h4><b>{feature.name}</b> converting to <b> {goalKey} </b> in <b>{project.name} {environment.name}</b></h4>
            </Col>
          </Row>
          <Row>
            <Col sm={4} xs={12}>
              <OverlayTrigger
                  overlay={winnerTooltip} placement="bottom"
                  delayShow={300} delayHide={150}
              >
              <Panel>
                <Panel.Heading bsStyle="info" style={{textAlign: 'center', fontSize: 18}}>Winner</Panel.Heading>
                <Panel.Body>
                  <h1 className="text-center">{state.statistics.winningVariation || 'None'}</h1>
                </Panel.Body>
              </Panel>
              </OverlayTrigger>
            </Col>
            <Col sm={4} xs={12}>
              <Panel>
                <Panel.Heading bsStyle="info" style={{textAlign: 'center', fontSize: 18}}>{ endDate ? 'Ran For' : 'Running For'}</Panel.Heading>
                <Panel.Body>
                  <h1 className="text-center">{moment(startDate).to(endDate ? moment(endDate) : moment(), true)}</h1>
                </Panel.Body>
              </Panel>
            </Col>
            <Col sm={4} xs={12}>
              <Panel>
                <Panel.Heading bsStyle="info" style={{textAlign: 'center', fontSize: 18}}>Confidence</Panel.Heading>
                <Panel.Body>
                  <h1 className="text-center">{format2dp(state.statistics.confidence)}%</h1>
                </Panel.Body>
              </Panel>
            </Col>
            <Col xs={12}>

              <Table className="table table-striped table-hover">
                <thead>
                <tr>
                  <th>Variant</th>
                  <th>Conversions / Impressions</th>
                  {/*<th>Goals</th>*/}
                  <th>Conversion Ratio</th>
                </tr>
                </thead>
                <tbody>
                {
                  Object.entries(state.statistics.variantResults).map(
                      ([key, variant]) => {
                        //state.statistics.variantResults.map(variant=>{
                        return <tr key={key}>
                          <td>{key}</td>
                          <td>{variant.goals} / {variant.impressions}</td>
                          <td>{format2dp(variant.conversionRate*100)}%</td>
                        </tr>
                      })}
                </tbody>
              </Table>

            </Col>
          </Row>
          {false === true && featureflow.evaluate('experiment-timeseries').isOn() &&
          <Row>
            <Col xs={12}>
            <span>
              <h3>Impressions</h3>
              {renderTimeseriesChart()}
            </span>
            </Col>
            <br/>
          </Row>
          }
        </Grid>
  }

//const selectExperiment = (state, props) => state.experiments[props.params.experimentId];
//const selectFeatures = (state, props) => state.features;
// const getExperiment = createSelector(
//   selectExperiment,
//   selectFeatures,
//   (experiment, features)=>{
//     experiment.feature = features[experiment.featureId];
//     return experiment;
//   }
// );
// const getProject = (state, props) => state.projects[props.params.projectKey];
// const getEnvironment = (state, props) => state.environments[props.params.environmentId];

// function mapStateToProps(state, props){
//   return {
//     experiment: getExperiment(state, props),
//     project: getProject(state, props),
//     environment: getEnvironment(state, props)
//   }
// }
function format2dp(number){
  return parseFloat(Math.round(number * 100) / 100).toFixed(2);
}

export default ExperimentStats;