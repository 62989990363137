// @flow
import React from 'react';

import SyntaxHighlighterWithCopy from '../SyntaxHighlighterWithCopy';

export function instructions(apiKey: string, featureKey: string){
const newProject = `mkdir featureflow-node-starter
cd featureflow-node-starter
npm init`;

const npmInstall = `npm install --save featureflow-node-sdk`;

const requireFeatureflow = `var Featureflow = require("featureflow-node-sdk")`;

const instantiateClient = `const API_KEY = "${apiKey}";
    
var user = new Featureflow.UserBuilder("jimmy@example.com")
                             .withAttribute("firstName", "Jimmy")
                             .withAttribute("lastName", "Hendrix")
                             .build();
                                    
var featureflow = new Featureflow.Client({apiKey: API_KEY});

featureflow.ready(function(error){
  if (error){
    return error;
  }
  if (featureflow.evaluate("${featureKey || ''}", user).isOn()){
    console.log('Showing feature to user');
  }
  else{
    console.log('Not showing feature to user');
  }
  featureflow.close();
});`;

    return (
      <ol>
        <li>
          Create a new NodeJS project
          <SyntaxHighlighterWithCopy language="bash" code={newProject}/>
        </li>
        <li>
          Install the Featureflow SDK from NPM
          <SyntaxHighlighterWithCopy language="bash" code={npmInstall}/>
        </li>
        <li>
          Create a file called "index.js" in the root of your project import the featureflow sdk
          <SyntaxHighlighterWithCopy language="javascript" code={requireFeatureflow}/>
        </li>
        <li>
          Instantiate the featureflow client and evaluate a feature
          <SyntaxHighlighterWithCopy language="javascript" code={instantiateClient}/>
        </li>
      </ol>
    )
}


export function runCommand(){
  return <SyntaxHighlighterWithCopy language="bash" code="node index.js"/>
}