// @flow
import React from 'react';
const images = {
  americanexpress: require('../assets/images/logos/cc/americanexpress.png').default,
  dinersclub: require('../assets/images/logos/cc/dinersclub.png').default,
  discover: require('../assets/images/logos/cc/discover.png').default,
  jcb: require('../assets/images/logos/cc/jcb.png').default,
  mastercard: require('../assets/images/logos/cc/mastercard.png').default,
  visa: require('../assets/images/logos/cc/visa.png').default,
};

export default function(props: any): React.Element<any>{
  const {
    brand,
    ...otherProps
  } = props;

  const style = {
    height: 22,
    width: 34,
    borderRadius: 3,
    border: '1px solid #ccc'
  };

  return (
    images[brand.toLowerCase()] ? <img alt={brand} style={style} {...otherProps} src={images[brand.toLowerCase()]}/> : null
  )
}