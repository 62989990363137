// @flow
import React from 'react';
import { Errors } from 'react-redux-form';

import ErrorContainer from './ErrorContainer';

type FormErrorsProps = {
  form: string
};
const FormErrors = (props: FormErrorsProps): React.Element<any> => {
  return (
    <ErrorContainer>
      <Errors model={'form.'+props.form}/>
    </ErrorContainer>
  )
};

export default FormErrors;