// @flow
import React from 'react';
import {connect} from 'react-redux';

import {dispatch} from '../redux';

import {Form, Control, actions, Errors} from 'react-redux-form';
import { FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import ActionButton from './controls/ActionButton';

import {setToken} from '../redux/authentication';

import {getDomain, getSubdomain} from '../utils/api';

import * as loginApi from '../api/login';

import ErrorContainer from './controls/ErrorContainer';

import ControlWrapper from './controls/ControlWrapper';


import ToggleButton from 'react-toggle-button';

import {requestResetPasswordModal} from '../modals/RequestResetPasswordModal';
import FaQuestionCircle from "react-icons/lib/fa/question-circle";
import Cookies from "js-cookie";

function mapStateToProps(state) {
    return {
        login: state.form.login
    }
}
const tooltip = (
    <Tooltip id="tooltip">
        Stay signed in for 30 days. Do not enable on a shared or insecure device.
    </Tooltip>);

export default connect(mapStateToProps)(function (props: any): React.Element<any> {
    const { organisationDomain } = props;
    const orgDomain = organisationDomain || getSubdomain();
    const handleSubmit = (values) => {
        dispatch(actions.setPending('form.login'));
        loginApi.authenticate({
            username: values.email,
            password: values.password,
            domain: orgDomain,
            rememberMe: values.rememberMe
        }).then(response => {
            if (orgDomain != null && orgDomain !== 'app') {
                Cookies.set('ffsubdomains', orgDomain, {domain: `.${getDomain()}`, expires: 365})
            }
            dispatch(actions.reset('form.login'));
            dispatch(setToken(response.id_token));

        }).catch((error) => {
            dispatch(actions.setPending('form.login', false));
            dispatch(actions.setErrors('form.login', {
                submit: error || "The email and password combination you have provided is invalid."
            }));
        });
    }

    return (
        <div>
            <Form
                model="form.login"
                onSubmit={handleSubmit}
            >
                <ControlWrapper model=".email"
                                type="email"
                                label="Email"
                                placeholder="Email"
                                autoComplete="email"
                                errorMessages={{
                                    isRequired: 'Please provide an email address.',
                                    isEmail: (val) => `${val} is not a valid email.`,
                                }}/>
                <ControlWrapper model=".password"
                                type="password"
                                label="Password"
                                placeholder="Password"
                                autoComplete="current-password"
                                errorMessages={{
                                    isRequired: 'Please provide a password.',
                                }}/>
                <FormGroup style={{display: 'flex'}}>
                    <label style={{flex: 1}}>Keep me signed in
                        <OverlayTrigger id="rememberMe-overlay" placement="top" overlay={tooltip}>
                            <FaQuestionCircle style={{marginLeft: '3px'}} />
                        </OverlayTrigger>
                    </label>

                    <ToggleButton value={props.login.rememberMe || false}
                                  inactiveLabel="No"
                                  activeLabel="Yes"
                                  onToggle={() => {
                                      dispatch(actions.toggle('form.login.rememberMe'))
                                  }
                                  }
                    />
                </FormGroup>
                <FormGroup>
                    <Control component={ActionButton}
                             model="form.login"
                             type="submit"
                             block
                             mapProps={{
                                 disabled: props => props.fieldValue.pending,
                                 spinner: props => props.fieldValue.pending
                             }}
                             bsStyle="success"
                    >
                        Log In
                    </Control>
                </FormGroup>
                <ErrorContainer>
                    <Errors model="form.login"/>
                </ErrorContainer>
            </Form>
            <button className="link-button" onClick={() => requestResetPasswordModal()}>Forgot password</button>
        </div>

    )
});