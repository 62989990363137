import React from 'react'
import { GithubPicker } from 'react-color'

class ButtonColorPicker extends React.Component {
  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ displayColorPicker: false });
    this.props.onChange(color.hex);
  };

  render() {

    const styles = {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: this.props.value,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    return (
      <div style={{position: 'relative', ...this.props.style}}>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ this.handleClose }/>
            <GithubPicker color={ this.props.value || '#fff' } width={212} triangle={this.props.triangle} onChange={ this.handleChange } />
          </div> : null }

      </div>
    )
  }
}

export default ButtonColorPicker;