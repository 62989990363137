import * as React from 'react';
import { ListGroup } from "react-bootstrap";
import type {Project} from "../../api/project";
import EnvironmentLink from "../environment/EnvironmentLink";

type Props = {
    project: Project
}
function ProjectPanel(props: Props) {
    const { project } = props;
    return <ListGroup>
        {project.environments.slice(0, 4).map(environment => {
            return <EnvironmentLink environment={environment} />
            /*return (<ListGroupItem href="#Development">{environment.name}</ListGroupItem>);*/
        })}
    </ListGroup>
}

export default ProjectPanel;