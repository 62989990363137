export function operatorValueToLabel(operator: stirng) : string{
  switch(operator){
    case 'equals':
      return 'is equal to';
    case 'greaterThan':
      return 'is greater than';
    case 'greaterThanOrEqual':
      return 'is greater than or equal to';
    case 'lessThan':
      return 'is less than';
    case 'lessThanOrEqual':
      return 'is less than or equal to';
    case 'contains':
      return 'contains the value';
    case 'matches':
      return 'matches the regex';
    case 'before':
      return 'is before';
    case 'after':
      return 'is after';
    case 'startsWith':
      return 'starts with';
    case 'endsWith':
      return 'ends with';
    case 'in':
      return 'is in';
    case 'notIn':
      return 'is not in';
    default:
      return operator;
  }
}

export function rulesSort(a: any,b: any): number{
  if (a.defaultRule) return 1;
  if (b.defaultRule) return -1;
  return 0;
}