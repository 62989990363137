// @flow
import React from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';

import Alert from 'react-s-alert';

import {FaCopy} from 'react-icons/lib/fa';
import { Button } from 'react-bootstrap';

export default function(props: any): React.Element<any>{
  const {
    copyText,
    style,
    ...otherProps
  } = props;
  return (
    <CopyToClipboard {...otherProps} style={style} text={copyText} onCopy={()=>Alert.success('Copied to clipboard')}>
      <Button bsSize="xs">Copy <FaCopy/></Button>
    </CopyToClipboard>
  )
}