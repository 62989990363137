// @flow
import axios from 'axios';
import constants from '../config/constants';
import * as JWTService from '../utils/JWTService';
import * as authenticationService from '../utils/authentication';
import Alert from "react-s-alert";


axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = JWTService.getAuthToken();
  if (token){
    config.headers.Authorization = 'Bearer '+token;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
/*axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  Alert.error(error);
  return Promise.reject(error);
});*/

type HTTPMethod =
  | "get"
  | "post"
  | "put"
  | "patch"
  | "delete";

type ExtraData = {
  params?: {[key:string]: any},
  data?: {[key:string]: any} | any[]
}

//track unauthorized tokens to not display the reauthenticate method over and over again.
let lastUnauthorizedToken = null;

export default function (method: HTTPMethod = 'get', path: string, extra: ExtraData = {}): Promise<*>{
  return axios.request({
    method: method,
    baseURL: constants.BASE_URL,
    url: path,
    ...extra
  }).then(response => {
    return Promise.resolve(response.data);
  })
  .catch(err => {
    // If we are using a token, and we are unauthenticated, then we should prompt the user to reauthenticate
    if (err.response.status === 401 && err.config.headers.Authorization && lastUnauthorizedToken !== err.config.headers.Authorization){
      lastUnauthorizedToken = err.config.headers.Authorization;
      authenticationService.reauthenticate();
    }
    if([403, 500].indexOf(err.response.status) > -1){
      if(err.response.data.title && err.response.data.title.length > 0){
        Alert.error(err.response.data.title);
      }
    }else if([400].indexOf(err.response.status) > -1){
      if(err.response.data.title && err.response.data.title.length > 0){
        Alert.warning(err.response.data.title,
            {
              timeout: 'none'
            });
      }
    }

    return Promise.reject(err.response.headers['x-featureflow-error-message'] || err.response.headers['x-featureflow-alert']);
  });
};

