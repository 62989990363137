import {Button, ControlLabel, FormControl, FormGroup, HelpBlock, Modal} from "react-bootstrap";
import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import ErrorContainer from "../../forms/controls/ErrorContainer";
import ActionButton from "../../forms/controls/ActionButton";
import {useUpdateSegmentMutation} from "../../api/featureflowApi";
import Alert from "react-s-alert";
import {convertStringToKey} from "../../utils/formParsers";

export type Props = {
    onClose: () => void,
    visible: boolean,
    environmentKey: string,
    projectKey: string
}

export const CreateSegmentModal = (props: Props) => {
    const {handleSubmit, register, setError, formState: { errors }} = useForm();
    const [visible, setVisible] = useState(false);
    const [updateSegment, { isLoading, isSuccess, isError, error}] = useUpdateSegmentMutation();

    const { onClose, visible: isVisible, environmentKey, projectKey} = props;

    useEffect(() => {
        setVisible(isVisible);
    }, [isVisible])

    useEffect(() => {
        if(isSuccess){
            Alert.success(`Segment saved.`);
        }
        setVisible(false);
    }, [isSuccess])

    useEffect(() => {
        if(isError){
            setError("general", "error", error.data.error);
        }
    }, [isError])

    const doOnSubmit = data => {
        console.log(data);
        updateSegment({
            environmentKey,
            projectKey,
            ...data
        });
    }
    const doOnCancel = data => {
        console.log(data);
        setVisible(false);
        onClose();
    }

    function FieldGroup({ id, label, help, ref, options, ...props }) {
        return (
            <FormGroup controlId={id} validationState={errors[id] ? 'error' : null}>
                <label htmlFor={id} className="col-lg-2 control-label">{label}</label>
                <input name={id} type="text" className="form-control" id={id}
                       autoComplete="off"
                       {...register(id, options)}
                       {...props}
                       {...props}

                />
                <ErrorContainer>
                    {errors[id] && <p>{errors[id].message}</p>}
                </ErrorContainer>
                {/*<ControlLabel>{label}</ControlLabel>
                <input name={id} className="form-control" id={id}
                       ref={register({required: true})}
                       {...props}
                />
                <ErrorContainer>
                </ErrorContainer>*/}
                {/*<FormControl
                    ref={ref}
                    {...props}
                    name={id}
                />
                {help && <HelpBlock>{help}</HelpBlock>}
                <ErrorContainer>
                    {errors[id]}
                    {id}
                    {errors[id] && <p>{errors[id]}</p>}
                </ErrorContainer>*/}
            </FormGroup>
        );
    }

    return  <Modal show={visible} onHide={onClose}>
            <form onSubmit={handleSubmit(doOnSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Segment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<FormGroup controlId={id} validationState={errors[id] ? 'error' : null}>*/}
                   {/* <Input
                        id="segmentKey"
                        type="text"
                        label="Segment key"
                        placeholder=""
                        ref={register({required: true})}
                        onChange={e => {
                            e.target.value = convertStringToKey(e.target.value);
                        }}
                    />*/}
                    <input name="bob" type="text" className="form-control" id="bob"
                           autoComplete="off"
                           {...register("bob",{required: true, setValueAs: value => convertStringToKey(value)})}
                           {...props}
                           {...props}

                    />
                        <FieldGroup
                            id="segmentKey"
                            type="text"
                            label="Key"
                            placeholder=""
                            options={{required: true, setValueAs: value => convertStringToKey(value)}}
                        />
                        <FieldGroup
                            id="segmentName"
                            type="text"
                            label="Name"
                            placeholder=""
                            options={{required: true}}
                        />
                        <FieldGroup
                            id="segmentDescription"
                            type="text"
                            label="Description"
                            placeholder=""
                        />
                </Modal.Body>
                <Modal.Footer>
                    <ErrorContainer>
                        {errors.general && errors.general.message}
                    </ErrorContainer>
                    <FormGroup>
                        <>
                            <Button type="reset" bsStyle="link" onClick={doOnCancel}>Cancel</Button>
                            <ActionButton
                                spinner={isLoading}
                                type="submit" bsStyle="success"> Save segment</ActionButton>
                        </>
                    </FormGroup>
                </Modal.Footer>
            </form>
        </Modal>
}



