// @flow
import React from 'react';
import { connect } from 'react-redux';
import {ListGroupItem, ButtonToolbar, Button, Label, ListGroup} from 'react-bootstrap';
import {withFeatureflow} from 'react-featureflow-client';
import Col from 'react-bootstrap/lib/Col';
import {confirmModal} from '../../modals/ConfirmModal';
import {dispatch} from '../../redux';
import {doGetIntegrations, doRemoveIntegration} from '../../redux/integrations';
import {FaCheckCircle, FaPlusCircle, FaTrashO} from 'react-icons/lib/fa';
import SlackLogo from '../../assets/images/logos/slack.svg';
import JiraLogo from '../../assets/images/logos/Jira-blue.svg';
import {getDomain, jsonToQueryString} from '../../utils/api';
import {isAdmin} from "../../utils/authentication";
import {createSelector} from "reselect";

var SLACK_CLIENT_ID = '17316152690.202407491441'; //Prod
if (process.env.NODE_ENV === 'development'){
    SLACK_CLIENT_ID = '17316152690.234217743122';
}

const SLACK_REDIRECT_URI = `${window.location.protocol}//app.${getDomain()}/api/v1/oauth/slack`;


//just tons of react/redux wiring code
/*
const integrationsSelector= state => state.integrations;

function mapStateToProps(state, props){
    return {
        integrations: getIntegrations(state, props)
    }
}
*/
//select the integrations object from redux
const integrationsSelector = state => state.integrations;
//convert the keyed object into an array
const getIntegrationsArray = createSelector(
    integrationsSelector,
    (integrations) => Object.keys(integrations)
        .map(i=>integrations[i])
);
function mapStateToProps(state, props){
    return {
        integrations: getIntegrationsArray(state, props)
    }
}

class Integrations extends React.Component {
    /**
     * Notes on the shit that is react redux
     * dispatch calls redux to invoke the method integrations.js/doGetIntegrations
     * this will call integrationsApi.getIntegrations GET /api/v1/integrations
     * then dispatch a message SET_INTEGRATIONS with the integrations payload
     * the result is spread into the state in ' case(SET_INTEGRATIONS):'
     *
     *
     * */

    componentDidMount() {
        //load the integrations using a shed load of wiring code.
        dispatch(doGetIntegrations());

        /*integrationsApi.getIntegrations()
            .then(integrations => {
                setTimeout(() => this.setState({integrations: integrations}))
            }).catch(console.warn);*/
    }

    removeIntegration(integration) {
        confirmModal(
            'Remove Integration',
            'Are you sure you want to remove the ' + integration.name + ' integration?',
            [
                {
                    text: 'Cancel',
                    value: false,
                    style: 'default'
                },
                {
                    text: 'Remove',
                    value: true,
                    style: 'primary'
                }
            ]
        ).then(shouldRemove => {
            if (shouldRemove) {
                return dispatch(doRemoveIntegration(integration.id))
            }
        })
    }

    integrationExists(type) {
        const b = this.props.integrations.find((item) => item.type === type);
        return typeof b !== 'undefined';
    }

    render(): React.Element<any> {

        let props = this.props;
        let {principalUser, featureflow} = props;
        const SLACK_STATE = `${principalUser.id}:${principalUser.hash}`;

        let slackParams = {
            client_id: SLACK_CLIENT_ID,
            scope: 'incoming-webhook',
            redirect_uri: SLACK_REDIRECT_URI,
            state: SLACK_STATE
        };
        if (!isAdmin()) {
            return (
                <div>
                    <Col xs={12} style={{display: 'flex', paddingTop: 20, paddingBottom: 10}}>
                        <h2 style={{flex: 1, margin: 0}}>
                            Integrations
                            &nbsp;
                            &nbsp;
                        </h2>
                    </Col>
                    <Col xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
                        <div style={{textAlign: 'center', marginTop: 100, marginBottom: 100}}>
                            <p>Only organisation admins may manage integrations. </p>
                        </div>
                    </Col>
                </div>
            )
        }

        return (

            <div>
                <Col xs={12} md={8}  style={{paddingTop: 20, paddingBottom: 10}}>
                    <h2 style={{flex: 1, margin: 0}}>
                        Integrations
                        &nbsp;
                        &nbsp;
                    </h2>
                </Col>
                {/*<Col xs={12} md={4} style={{paddingTop: 10, paddingBottom: 10, textAlign: 'right'}}>
                    <DropdownButton
                        bsStyle={'primary'}
                        title={'Add an integration'}
                        key={'add-integration'}
                        id={`add-integration`}
                    >
                        <MenuItem eventKey="1">Slack</MenuItem>
                        <MenuItem eventKey="2">Jira</MenuItem>
                    </DropdownButton>
                </Col>*/}
                <Col xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
                    <ListGroupItem style={{display: 'flex', height: 50, backgroundColor: '#f3f3f3'}}>
                        <div style={{flex: 1, fontSize: 16, alignSelf: 'center'}}>Available integrations</div>
                    </ListGroupItem>
                    <ListGroupItem style={{display: 'flex'}}>
                      <span style={{flex: 1}}>
                          <img src={SlackLogo} alt="slack" style={{height: 64, margin: -10}}/>
                      </span> {/*Not sure about using flex when we should be using bootstrap - it isn't a grid layout here mate... but we should probably standardise this better*/}
                        <ButtonToolbar style={{display: 'inline-block'}}>
                            {this.integrationExists('slack') ?
                                <Button bsStyle="success disabled">
                                    Installed <FaCheckCircle style={{verticalAlign: 'text-bottom'}}/>
                                </Button>
                                :
                                <a style={{alignSelf: 'center'}}
                                   href={`https://slack.com/oauth/authorize${jsonToQueryString(slackParams)} `}>
                                    <img alt="Add to Slack" height="40" width="139"
                                         src="https://platform.slack-edge.com/img/add_to_slack.png"
                                         srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"/>
                                </a>
                            }

                        </ButtonToolbar>
                    </ListGroupItem>
                    {featureflow.evaluate('jira-integration').isOn() &&
                        <ListGroupItem style={{display: 'flex'}}>
                              <span style={{flex: 1}}>
                                  <img src={JiraLogo} alt="jira" style={{height: 30, margin: 5}}/>
                              </span>

                            <ButtonToolbar style={{display: 'inline-block'}}>
                                    <a href={'https://marketplace.atlassian.com/vendors/1215795/featureflow'}>
                                        <Button bsStyle="primary">
                                            Add <FaPlusCircle style={{verticalAlign: 'text-bottom'}}/>
                                        </Button>
                                    </a>
                            </ButtonToolbar>
                        </ListGroupItem>
                    }
                </Col>
                <Col xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
                    <ListGroupItem style={{display: 'flex', height: 50, backgroundColor: '#f3f3f3'}}>
                        <div style={{flex: 1, fontSize: 16, alignSelf: 'center'}}>Installed integrations</div>
                    </ListGroupItem>
                    <ListGroup>
                        {props.integrations.map(integration=>{
                            return (
                                <span key={integration.id}>
                                {integration.type === 'slack' &&
                                    <ListGroupItem key={integration.id} style={{display: 'flex'}}>
                                        <span style={{flex: 1}}>
                                            <p><img src={SlackLogo} alt="slack" style={{height: 64, margin: -10}}/></p>
                                            <small className="text-muted">Installed by {integration.member.name}</small>
                                        </span>


                                        <ButtonToolbar style={{display: 'inline-block'}}>

                                                <span style={{alignSelf: 'center'}}>posting to <Label bsStyle="primary">{integration.configuration.slack.incomingWebhook.channel}</Label>
                                                    <FaCheckCircle style={{fontSize: 32, marginLeft: 10, color: '#60c3a7'}}/>
                                                    <span className="pointer" onClick={() => {this.removeIntegration(integration)}}>
                                                      <FaTrashO style={{fontSize: 24, marginLeft: 10, color: '#c33c33'}}/>
                                                    </span>
                                                </span>
                                        </ButtonToolbar>
                                    </ListGroupItem>
                                }
                                {integration.type === 'jira' &&
                                    <ListGroupItem style={{display: 'flex'}}>
                                  <span style={{flex: 1}}>
                                      <img src={JiraLogo} alt="jira" style={{height: 30, margin: 5}}/>
                                  </span>

                                    <ButtonToolbar style={{display: 'inline-block'}}>
                                            <span style={{alignSelf: 'center'}}>
                                                <span style={{alignSelf: 'center'}}>connected to <Label bsStyle="primary"><a target="_blank"  rel="noopener noreferrer" href={integration.configuration.jira.baseUrl}>{integration.configuration.jira.baseUrl}</a></Label>
                                                    <FaCheckCircle style={{fontSize: 32, marginLeft: 10, color: '#60c3a7'}}/>
                                                    <span className="pointer" onClick={() => {this.removeIntegration(integration)}}>
                                                      <FaTrashO style={{fontSize: 24, marginLeft: 10, color: '#c33c33'}}/>
                                                    </span>
                                                </span>
                                    </span>
                                    </ButtonToolbar>
                                </ListGroupItem>
                                }
                                </span>

                            )
                        })}
                    </ListGroup>
                </Col>

            </div>
        )
    }
}
export default withFeatureflow({update: true})(connect(mapStateToProps)(Integrations));