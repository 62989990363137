// @flow
import * as React from 'react';
import {Button, ButtonToolbar, Table} from "react-bootstrap";
import {FaTrash, FaEdit} from "react-icons/lib/fa";
import { useDispatch, useSelector } from "react-redux";

//previously known as a target

type Attributes = {
    [key: string]: string | number
};
type User = {
    id: string,
    attributes?: Attributes
}
type Users = User[];
type Props = {
    params: {
        projectKey: string,
        environmentId: String,
    }
}

const UsersList = (props: Props): React.Node => {
    const dispatch = useDispatch();

    const projectKey = props.params.projectKey;
    const environmentKey = props.params.environmentId;
    const currentProject = useSelector(state => state.projects[projectKey]);
    const currentEnvironment = currentProject.environments[environmentKey];
    const users: Users = useSelector(state => state.users) || [];

    //dispatch(GetAllUsers())

    return (
            <>
                <h1>Target Users</h1>
                <p>
                    Users for {currentProject && currentProject.name} in {currentEnvironment && currentEnvironment.name}
                </p>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-header">
                            <h2 id="user-table">Users</h2>
                        </div>

                        <div className="bs-component">
                            <Table className="table table-striped table-hover">
                                <thead>
                                <tr>
                                    <th style={{border: 0}}>Id</th>
                                    <th style={{border: 0}}>Attributes</th>
                                    <th style={{border: 0}}></th>
                                </tr>
                                </thead>
                                <tbody>
                                { users.map(user => <tr>
                                        <td>{user.id}</td>
                                        <td><AttributesInlineDisplay attributes={user.attributes || {}}></AttributesInlineDisplay></td>
                                        <td className="text-right">
                                            <ButtonToolbar className="pull-right">
                                                <Button bsStyle="danger"
                                                        bsSize="xsmall">
                                                    Delete <FaTrash/>
                                                </Button>
                                                <Button bsStyle="danger"
                                                        bsSize="xsmall">
                                                    Edit <FaEdit/>
                                                </Button>
                                            </ButtonToolbar>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </>
        );
}

const AttributesInlineDisplay = (props: { attributes: Attributes}) => {
    const { attributes } = props.attributes;
    const keys = Object.keys(props.attributes);
    return (
        keys.map(key => <><span>{key}</span><span>{attributes[key]}</span></>)
    )
} 

export default UsersList