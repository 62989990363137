// @flow
import React from 'react';
import './Login.scss';

import Logo from '../assets/images/logos/featureflow-logo-text-150x155.png';

import LoginForm from '../forms/LoginForm';
import constants from '../config/constants';
import {getOrganisationDomain} from "../utils/api";

 const OrganisationLogin = (): React.Element<any> => {
    const organisationDomain = getOrganisationDomain();
    return (
        <div id="login-container">
            <div id="login">
                <img src={Logo} alt="featureflow"/>
                <div>
                    <h1>
                        Release confidently.
                    </h1>
                    <h4>
                        Featureflow keeps the business in control and makes champions of developers
                    </h4>

                </div>
                <span className={'version'}>{constants.VERSION}</span>
            </div>
            <div id="sidebar">
                <div className="login-heading">
                    <h4>Log in to {organisationDomain}</h4>
                </div>
                <LoginForm organisationDomain={organisationDomain}/>
                <div>Not a member? <a href="https://app.featureflow.io">Sign up for free</a></div>
            </div>
        </div>
    )
}

export default OrganisationLogin;
