// @flow
import React from 'react';
import { withRouter } from 'react-router';
import { actions } from 'react-redux-form';
import Select from 'react-select';

import {useDispatch, useSelector} from 'react-redux';
import { createTargetModal } from '../../modals/CreateTargetFormModal';
import ControlWrapper from '../controls/ControlWrapper';

import type {Project, Target} from "../../api/project";

type Props = {
    project: Project,
    params: any,
    baseModel: string,
    targets: Target[],
    dispatch: () => Action
}

export default withRouter(function(props: Props): React.Element<any>{
    const dispatch = useDispatch();
    const project = useSelector((state => state.projects[props.params.projectKey]));
    const targets = project.targets;

    return (
      <ControlWrapper model=".target"
                      placeholder="Target"
                      component={Select.Creatable}
                      validateOn={['blur','change']}
                      ignore={['blur']}
                      errorMessages={{
                        isRequired: 'Required'
                      }}
                      clearable={false}
                      changeAction={(model = '', value = {})=>{
                        if(value === null) {
                            return;
                        }
                        return dispatch =>{
                          const conditionModel = model.substr(0, model.lastIndexOf('.'))
                          dispatch(actions.change(conditionModel, {
                            operator: '',
                            values: [],
                            target: value.key
                          }));
                        }
                      }}
                      options={targets}
                      valueKey="key"
                      labelKey="name"

                      mapProps={{
                        onNewOptionClick: formProps => (option)=>{
                           createTargetModal(option.key, project)
                             .then(value=>{
                               if (value){
                                  const conditionModel = formProps.model.substr(0, formProps.model.lastIndexOf('.'))
                                 dispatch(actions.change(conditionModel, {
                                  operator: '',
                                  values: [],
                                  target: value.key
                                }));
                               }
                             })
                        }
                      }}
      />
    )
})