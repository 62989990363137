// @flow
import React from 'react';

import * as modalController from '../redux/modalController';
import {confirmModal} from './ConfirmModal';
import * as userApi from '../api/user';
import UserForm, {UserFormButtons} from '../forms/UserForm';

import {actions} from 'react-redux-form';


import {dispatch} from '../redux';

type
User = {
    id: string,
    fullName: string,
    firstName: string,
    lastName: string,
    email: string,
    role: string
}

export function updateUserModal(user: User, deleteUser: (User)=>any): Promise<any> {
    return new Promise((resolve, reject) => {
        const {id, fullName, firstName, lastName, email} = user;
        let role;
        //Must reset the form before we show it.

        role = user.authority;

        dispatch(actions.load('form.user', {id, fullName, firstName, lastName, email, role}))

        dispatch(modalController.show({
            title: `Update User`,
            body: <div>
                <UserForm onSubmit={(_values) => {
                    let values = {..._values};

                    values.authorities = [values.role];
                    values.authority = values.role;

                    delete values.role;

                    userApi.update(values)
                        .then(response => {
                            dispatch(modalController.close());
                            dispatch(actions.setPending('form.user', false));
                            return response;
                        }).then(resolve)
                        .catch(err => {
                            dispatch(actions.setErrors('form.user', {err}));
                            dispatch(actions.setPending('form.user', false));
                            return reject(err);
                        });
                }}/>
            </div>,
            footer: <UserFormButtons onDelete={() => {
                if (user && user.id) {
                    confirmModal('Delete User', `Are you sure you want to delete '${user.email}'?`).then(value => {
                        if (value && user) {
                            deleteUser(user);
                            dispatch(modalController.close());
                        }
                    })
                }
            }}/>,
            closeButton: true,
            static: true,
        }));
    })

}